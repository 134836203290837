import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import withStyles from "@material-ui/core/styles/withStyles";
import {mainTheme} from "../../themes/Themes";

const style = theme => ({});

class Preloader2 extends Component {
    render() {
        return (
            <MuiThemeProvider theme={mainTheme}>
                    <div className="pre-loader-2">
                        <CircularProgress color='primary' size={50} thickness={4}/>
                    </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style, {withTheme: true})(Preloader2);