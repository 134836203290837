import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide/Slide";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
// ...or load this specific CSS file using a <link> tag in your document
// import 'react-fine-uploader/gallery/gallery.css';
import Button from "@material-ui/core/Button";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';
import CropIcon from '@material-ui/icons/Crop';
import {showError} from "../../actions/helperActions";
import {WhiteCircularProgress} from "../progress_bars/ProgressBars";
import {addAssignment, addHomework} from "../../actions/homeworkActions";
import {getClassLevels} from "../../actions/classLevelActions";
import {getTerms} from "../../actions/termsActions";
import {getTeachingSubjects} from "../../actions/subjectActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {getLocalizedMessage, titleCase, toastError} from "../../helpers";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getStreams} from "../../actions/streamActions";
import {DatePicker} from "material-ui-pickers";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {format} from "date-fns"
import DeleteIcon from "@material-ui/icons/Delete";
import {schoolClassLevelName} from "../../helpers/StorageHelper";

const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddAssignmentDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            date: new Date(),
            classLevel: '',
            stream: '',
            instructions: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    clearFields = () => {
        this.setState({
            subject: '',
            date: new Date(),
            classLevel: '',
            stream: '',
            instructions: ''
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const {classLevel, date, stream, subject, instructions} = this.state;

        if (classLevel !== '' && stream !== '' && subject !== '' && instructions !== '') {
            const serverDate = format(date, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            const data = JSON.stringify({instructions: instructions});
            this.props.addAssignment(classLevel, stream, subject, serverDate, data);
        } else {
            toastError(getLocalizedMessage('fillFields'));
        }
    };

    handleDateChange = (date) => {
        this.setState({date})
    };

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    render() {
        const {classes, loading} = this.props;
        const {classLevel, date, stream, subject, instructions} = this.state;
        const {levels} = this.props.levelsData;
        const {streams} = this.props.streamsData;
        const {subjects} = this.props.subjectsData;

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <div className="container mb-3">
                        <div className="row clearfix">
                            <div className="col-md-12 column">
                                <form onSubmit={this.handleSubmit}>
                                    <AppBar className={classes.appBar}>
                                        <Toolbar>
                                            <IconButton color="inherit" onClick={this.props.handleClose}
                                                        aria-label={getLocalizedMessage('close')}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                                {getLocalizedMessage('addHomework')}
                                            </Typography>
                                            <Button color="inherit" type="submit">
                                                {
                                                    !loading &&
                                                    <span>{getLocalizedMessage('submit')}</span>
                                                }
                                                {
                                                    loading &&
                                                    <WhiteCircularProgress size={30} thickness={5}/>
                                                }
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                    <div className="container"
                                         style={{marginTop: 90}}>

                                        <div className='d-flex w-100 justify-content-center'>
                                            <div className="notice-item">
                                                {
                                                    !levels &&
                                                    <FormControl className='mt-0' fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(schoolClassLevelName())}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    levels &&
                                                    <FormControl className='mt-0' fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(schoolClassLevelName())}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem
                                                                value=''>{titleCase(schoolClassLevelName())}...</MenuItem>
                                                            {
                                                                levels.map((item, index) => (
                                                                    <MenuItem value={item.id} key={index}>
                                                                        {titleCase(item.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }

                                                {
                                                    !streams &&
                                                    <FormControl className='mt-4' fullWidth>
                                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                                            {getLocalizedMessage('stream')}
                                                        </InputLabel>
                                                        <Select
                                                            value={stream}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="stream-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    streams &&
                                                    <FormControl className='mt-4' fullWidth>
                                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                                            {getLocalizedMessage('stream')}
                                                        </InputLabel>
                                                        <Select
                                                            value={stream}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="stream-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>{getLocalizedMessage('stream')}...</MenuItem>
                                                            {
                                                                streams.map((stream, index) => (
                                                                    <MenuItem value={stream.value} key={index}>
                                                                        {titleCase(stream.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }

                                                {
                                                    !subjects &&
                                                    <FormControl className='mt-4' fullWidth>
                                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                                            {getLocalizedMessage('subject')}
                                                        </InputLabel>
                                                        <Select
                                                            value={subject}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="subject"
                                                                          id="subject-label-placeholder"/>}
                                                            displayEmpty
                                                            name="subject"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    subjects &&
                                                    <FormControl className='mt-4' fullWidth>
                                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                                            {getLocalizedMessage('subject')}
                                                        </InputLabel>
                                                        <Select
                                                            value={subject}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="subject"
                                                                          id="subject-label-placeholder"/>}
                                                            displayEmpty
                                                            name="subject"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>{getLocalizedMessage('subject')}...</MenuItem>
                                                            {
                                                                subjects
                                                                    .map((subject, index) => (
                                                                        <MenuItem value={subject.id} key={index}>
                                                                            {titleCase(subject.name)}
                                                                        </MenuItem>
                                                                    ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                }

                                                <DatePicker
                                                    value={date}
                                                    onChange={this.handleDateChange}
                                                    leftArrowIcon={<ChevronLeftIcon/>}
                                                    rightArrowIcon={<ChevronRightIcon/>}
                                                    label={getLocalizedMessage('submissionDate')}
                                                    className="mt-4"
                                                    format="dd MMM yyyy"
                                                    disablePast
                                                    openToYearSelection={false}
                                                    fullWidth
                                                />

                                                <div className="form-group mt-3">
                                                    <label htmlFor='instructions text'>
                                                        {getLocalizedMessage('instructions')}
                                                    </label>
                                                    <textarea className="form-control"
                                                              id='instructions'
                                                              rows="4"
                                                              name='instructions'
                                                              value={instructions}
                                                              onChange={this.handleSelectChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        )
            ;
    }
}

AddAssignmentDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    addAssignment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.addHomeworkData.data,
    status: state.addHomeworkData.status,
    loading: state.addHomeworkData.loading,
    levelsData: state.levelsData.data,
    subjectsData: state.getTeachingSubjectsData.data,
    streamsData: state.getStreamsData.data,
});

export default connect(mapStateToProps, {
    addAssignment
})(withStyles(styles)(AddAssignmentDialog));