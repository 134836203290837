import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import "react-table/react-table.css";
import {editResults, exportResults, getResults} from "../../actions/resultActions";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/es/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/es/Button/Button";
import {getLocalizedMessage, titleCase, toastError} from "../../helpers";
import {showError} from "../../actions/helperActions";
import {getStreams} from "../../actions/streamActions";
import {getTeachingSubjects} from "../../actions/subjectActions";
import {maxYear, minYear} from "../../constants/constants";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createGenerateId, JssProvider} from 'react-jss'
import {getClassLevels} from "../../actions/classLevelActions";
import {getExams, getUnAveragedExams} from "../../actions/examActions";
import {getTerms} from "../../actions/termsActions";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";
import Loader from "../Loader";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ImportResultsDialog from "../dialogs/ImportResultsDialog";
import {downloadMarkSheet} from "../../actions/studentActions";
import {schoolClassLevelName, schoolTermName} from "../../helpers/StorageHelper";
import {toggleDialog} from "../../actions/commonActions";
import * as dialogActions from "../../constants/DialogActions";
import MarkAttendanceDialog from "../dialogs/MarkAttendanceDialog";
import AddResultDialog from "../dialogs/AddResultDialog";
import Preloader2 from "../progress_bars/Preloader2";

const generateId = createGenerateId();

const styles = theme => ({});

const getColumnWidth = (rows, accessor, headerText) => {
    if (rows) {
        const maxWidth = 400;
        const magicSpacing = 10;
        const cellLength = Math.max(
            ...rows.map(row => (`${row[accessor]}` || '').length),
            headerText.length,
        );
        return Math.min(maxWidth, cellLength * magicSpacing)
    }
};

class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classLevel: '',
            year: new Date().getFullYear(),
            term: '',
            exam: '',
            currentRow: null,
            openDialog: false,
            data: [],
            contentsChanged: false,
            editable: false,
            stream: '',
            subject: '',
            moreActionsAnchorEl: null,
            importResultsDialogOpen: false,
            exams: []
        };

        this.addDialogRef = React.createRef();
        this.importDialogRef = React.createRef();

        this.renderCell = this.renderCell.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
        this.showMoreActionMenu = this.showMoreActionMenu.bind(this);
        this.hideMoreActionMenu = this.hideMoreActionMenu.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.downloadMarkSheet = this.downloadMarkSheet.bind(this);
        this.exportResults = this.exportResults.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('subjectResults');
        this.props.setTitle(getLocalizedMessage('subjectResults'));
        this.props.getTeachingSubjects();
        this.props.getStreams();
        this.props.getClassLevels();
        this.props.getTerms();
        this.props.getUnAveragedExams();
    }

    handleChange = event => {

    };

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                const {classLevel, year, stream, exam, term, subject} = this.state;
                if (classLevel !== '' && year !== '' && stream !== '' && exam !== '' && term !== '' && subject !== '') {
                    this.props.getResults(classLevel, stream, year, term, exam, subject);
                }

                if (event.target.name === 'classLevel' || event.target.name === 'year'
                    || event.target.name === 'term') {
                    const {classLevel, year, term} = this.state;
                    if (classLevel !== '' && year !== '' && term !== '') {
                        const {exams} = this.props.examsData;
                        let myExams = [];

                        if (exams) {
                            myExams = exams.filter(item => item.classLevelId == classLevel && item.termId == term && item.year == year);
                        }
                        this.setState({exam: '', exams: myExams});
                    }
                }
            }
        );

    };

    componentWillUpdate(nextProps, nextState, nextContext) {
        // this.props.getStudents();
    }

    renderCell = cellInfo => {
        const {results} = this.props.data;

        return (
            <div
                style={{backgroundColor: "#fafafa"}}
                className="d-flex justify-content-center"
            >
                <div>{results[cellInfo.index][cellInfo.column.id]}</div>
            </div>
        );
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {examsData} = this.props;
        const {classLevel, year, stream, exam, term, subject} = this.state;
        if (classLevel !== '' && year !== '' && stream !== '' && exam !== '' && term !== '' && subject !== '') {
            let empty = false;
            const data = [];
            const {exams} = examsData;
            let maximum = null;
            let scoreExceeds = false;

            exams.forEach(item => {
                if (item.id === exam) {
                    maximum = item.maximum;
                }
            });

            if (this.state.data.length < 1) {
                empty = true;
            }

            this.state.data.forEach((function (item) {
                if (item.score === '') {
                    empty = true;
                } else if (item.score < 0 || item.score > maximum) {
                    scoreExceeds = true;
                } else if (item.edited === true) {
                    data.push({
                        admNo: item.admNo,
                        score: item.score
                    })
                }
            }));

            if (empty === true) {
                toastError(getLocalizedMessage('fillFields'));

            } else if (scoreExceeds) {
                toastError(`${getLocalizedMessage('scoreMustBeBetween')} ${maximum}`);
            } else {
                const submitData = JSON.stringify({
                    data: data
                });
                this.props.editResults(submitData, classLevel, year, term, exam, subject);
            }
        } else {
            toastError(getLocalizedMessage('fillFields'));
        }
    };

    renderEditable = cellInfo => {
        return (
            <Input value={this.state.data[cellInfo.index][cellInfo.column.id]}
                   onChange={event => {
                       let data = [...this.state.data];
                       data[cellInfo.index][cellInfo.column.id] = event.target.value;
                       data[cellInfo.index]["edited"] = true;

                       this.setState({
                           data,
                           contentsChanged: true
                       })
                   }}/>
        )
    };

    handleModeChange = event => {
        const {results} = this.props.data;
        if (results.length > 0) {
            this.setState({
                    editable: event.target.value
                },
                () => {
                    if (this.state.editable) {
                        this.setState({
                            data: [...results],
                            contentsChanged: false
                        })
                    } else {
                        const {classLevel, year, stream, exam, term, subject} = this.state;
                        if (classLevel !== '' && year !== '' && stream !== '' && exam !== '' &&
                            term !== '' && subject !== '') {
                            this.props.getResults(classLevel, stream, year, term, exam, subject);
                        }
                    }
                })
        }


    };

    hideMoreActionMenu = e => {
        this.setState({
            moreActionsAnchorEl: null
        })
    };

    showMoreActionMenu = event => {
        this.setState({
            moreActionsAnchorEl: event.currentTarget
        })
    };

    openDialog = name => e => {
        this.setState({
            moreActionsAnchorEl: null
        });

        if (name === dialogActions.ADD_RESULTS) {
            this.addDialogRef.clearFields();
        } else if (name === dialogActions.IMPORT_RESULTS) {
            this.importDialogRef.clearFields();
        }
        this.props.toggleDialog(name, true);
    };

    closeDialog = name => e => {
        this.props.toggleDialog(name, false);
    };

    downloadMarkSheet = event => {
        this.setState({moreActionsAnchorEl: null});
        const {classLevel, year, stream} = this.state;
        this.props.downloadMarkSheet(classLevel, year, stream);
    };

    exportResults = event => {
        this.setState({moreActionsAnchorEl: null});
        const {classLevel, year, stream, exam, term, subject} = this.state;
        this.props.exportResults(classLevel, stream, year, term, exam, subject);
    };

    render() {
        const {classLevel, year, term, subject, exam, editable, importResultsDialogOpen, stream, exams} = this.state;
        const {
            data, status, subjectsData, streamsData, classes, editData, loading,
            streamsLoadingExamsData, examsData, levelsData, termsData, exportingResults, downloadingMarkSheet,
            dialogData, editLoading
        } = this.props;
        const {results, hasGrades} = data;
        const years = [];

        for (let i = 2000; i < 2100; i++) {
            years.push(i)
        }

        return (
            <MuiThemeProvider theme={mainTheme}>
                <JssProvider generateId={generateId} classNamePrefix="Results-">
                    <div>
                        <div className="mb-3 d-flex justify-content-between flex-column flex-lg-row">
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="mode">
                                    {getLocalizedMessage('mode')}
                                </InputLabel>
                                <Select
                                    value={this.state.editable}
                                    input={<Input name="mode"/>}
                                    displayEmpty
                                    name="editable"
                                    className={classes.selectEmpty}
                                    onChange={this.handleModeChange}
                                >
                                    <MenuItem value={false}>{getLocalizedMessage('view')}</MenuItem>
                                    <MenuItem value={true}>{getLocalizedMessage('editable')}</MenuItem>
                                </Select>
                            </FormControl>

                            <div className='d-flex flex-column flex-lg-row'>
                                {
                                    classLevel && stream && year &&
                                    <Button onClick={this.showMoreActionMenu} variant='outlined' color='secondary'
                                            aria-owns={this.state.moreActionsAnchorEl ? 'moreActionMenu' : undefined}
                                            className='mt-3 mt-lg-0'>
                                        {getLocalizedMessage('moreActions')} <MoreVertIcon/>
                                    </Button>
                                }
                                <Button onClick={this.openDialog(dialogActions.ADD_RESULTS)} variant='contained'
                                        color='primary'
                                        className='ml-0 ml-lg-3 mt-3 mt-lg-0'>
                                    {getLocalizedMessage('addResults')}
                                </Button>
                                {
                                    this.state.contentsChanged && editable &&
                                    <Button variant="outlined"
                                            color="primary"
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            className='ml-0 ml-lg-3 mt-3 mt-lg-0'>
                                        {getLocalizedMessage('submitChanges')}
                                    </Button>
                                }
                            </div>

                        </div>
                        <div className="row m-0">
                            <div className="col-md-3 pl-0">
                                {
                                    !levelsData.levels &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                            {titleCase(schoolClassLevelName())}
                                        </InputLabel>
                                        <Select
                                            value={classLevel}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="classLevel"
                                                          id="level-label-placeholder"/>}
                                            displayEmpty
                                            name="classLevel"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>
                                                <CircularProgress color='primary' size={30}
                                                                  thickness={5}/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    levelsData.levels &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                            {titleCase(schoolClassLevelName())}
                                        </InputLabel>
                                        <Select
                                            value={classLevel}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="classLevel"
                                                          id="level-label-placeholder"/>}
                                            displayEmpty
                                            name="classLevel"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{titleCase(schoolClassLevelName())}...</MenuItem>
                                            {
                                                levelsData.levels.map((item, index) => (
                                                    <MenuItem value={item.id} key={index}>
                                                        {titleCase(item.name)}
                                                    </MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                }
                            </div>
                            <div className="col-md-3 pl-0 mt-3 mt-lg-0">
                                <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                    <InputLabel shrink htmlFor="year-label-placeholder">
                                        {getLocalizedMessage('year')}
                                    </InputLabel>
                                    <Select
                                        value={year}
                                        onChange={this.handleSelectChange}
                                        input={<Input name="year" id="year-label-placeholder"/>}
                                        displayEmpty
                                        name="year"
                                        className={classes.selectEmpty}
                                    >
                                        {
                                            years.map((item, index) => (
                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="col-md-3 pl-0 mt-3 mt-lg-0">
                                {
                                    !streamsData.streams &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                            {getLocalizedMessage('stream')}
                                        </InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="stream"
                                                          id="stream-label-placeholder"/>}
                                            displayEmpty
                                            name="stream"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>
                                                <CircularProgress color='primary' size={30}
                                                                  thickness={5}/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    streamsData.streams &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="stream-label-placeholder">
                                            {getLocalizedMessage('stream')}
                                        </InputLabel>
                                        <Select
                                            value={this.state.stream}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="stream"
                                                          id="stream-label-placeholder"/>}
                                            displayEmpty
                                            name="stream"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{getLocalizedMessage('stream')}...</MenuItem>
                                            {
                                                streamsData.streams.map((stream, index) => (
                                                    <MenuItem value={stream.value} key={index}>
                                                        {titleCase(stream.name)}
                                                    </MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                }
                            </div>
                            <div className="col-md-3 pl-0 mt-3 mt-lg-0">
                                {
                                    !termsData.terms &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                            {titleCase(schoolTermName())}
                                        </InputLabel>
                                        <Select
                                            value={term}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="term"
                                                          id="term-label-placeholder"/>}
                                            displayEmpty
                                            name="term"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>
                                                <CircularProgress color='primary' size={30}
                                                                  thickness={5}/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    termsData.terms &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                            {titleCase(schoolTermName())}
                                        </InputLabel>
                                        <Select
                                            value={this.state.term}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="term"
                                                          id="term-label-placeholder"/>}
                                            displayEmpty
                                            name="term"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{titleCase(schoolTermName())}...</MenuItem>
                                            {
                                                termsData.terms.map((item, index) => (
                                                    <MenuItem value={item.id} key={index}>
                                                        {titleCase(item.name)}
                                                    </MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                }
                            </div>
                            <div className="col-md-3 pl-0 mt-3">
                                {
                                    !examsData.exams &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="exam-label-placeholder">
                                            {getLocalizedMessage('exam')}
                                        </InputLabel>
                                        <Select
                                            value={exam}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="exam"
                                                          id="exam-label-placeholder"/>}
                                            displayEmpty
                                            name="exam"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{getLocalizedMessage('exam')}...</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    examsData.exams &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="exam-label-placeholder">
                                            {getLocalizedMessage('exam')}
                                        </InputLabel>
                                        <Select
                                            value={exam}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="exam"
                                                          id="exam-label-placeholder"/>}
                                            displayEmpty
                                            name="exam"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{getLocalizedMessage('exam')}...</MenuItem>
                                            {
                                                exams.map((item, index) => (
                                                    <MenuItem value={item.id} key={index}>
                                                        {titleCase(item.name)}
                                                    </MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                }
                            </div>
                            <div className="col-md-3 pl-0 mt-3">
                                {
                                    !subjectsData.subjects &&
                                    <FormControl className={classes.formControl} disabled={editable} fullWidth>
                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                            {getLocalizedMessage('subject')}
                                        </InputLabel>
                                        <Select
                                            value={subject}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="subject"
                                                          id="subject-label-placeholder"/>}
                                            displayEmpty
                                            name="subject"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>
                                                <CircularProgress color='primary' size={30}
                                                                  thickness={5}/>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                                {
                                    subjectsData.subjects &&
                                    <FormControl className={classes.formControl} fullWidth disabled={editable}>
                                        <InputLabel shrink htmlFor="subject-label-placeholder">
                                            {getLocalizedMessage('subject')}
                                        </InputLabel>
                                        <Select
                                            value={subject}
                                            onChange={this.handleSelectChange}
                                            input={<Input name="subject"
                                                          id="subject-label-placeholder"/>}
                                            displayEmpty
                                            name="subject"
                                            className={classes.selectEmpty}
                                        >
                                            <MenuItem value=''>{getLocalizedMessage('subject')}...</MenuItem>
                                            {
                                                subjectsData.subjects
                                                    .map((subject, index) => (
                                                        <MenuItem value={subject.id} key={index}>
                                                            {titleCase(subject.name)}
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                }
                            </div>
                        </div>

                        {
                            loading &&
                            <div className='w-100 d-flex justify-content-center pt-5'>
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            </div>
                        }

                        {
                            this.state.data && editable &&
                            <table className="table table-bordered table-hover table-responsive-md mt-3">
                                <thead>
                                <tr>
                                    <th/>
                                    <th>{getLocalizedMessage('admNo')}</th>
                                    <th>{getLocalizedMessage('name')}</th>
                                    <th>{getLocalizedMessage('score')}</th>

                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.data.map((result, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{result.admNo}</td>
                                            <td>{titleCase(result.fullName)}</td>
                                            <td>
                                                <Input
                                                    fullWidth
                                                    type='number'
                                                    value={this.state.data[index].score}
                                                    onChange={event => {
                                                        const data = [...this.state.data];
                                                        data[index].score = parseInt(event.target.value, 10);
                                                        data[index].edited = true;
                                                        this.setState({
                                                            data: data,
                                                            contentsChanged: true
                                                        });
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        }

                        {
                            results && !editable && results.length > 0 && !loading &&
                            <table className="table table-bordered table-hover table-responsive-md mt-3">
                                <thead>
                                <tr>
                                    <th/>
                                    <th>{getLocalizedMessage('admNo')}</th>
                                    <th>{getLocalizedMessage('name')}</th>
                                    <th>{getLocalizedMessage('score')}</th>
                                    {
                                        hasGrades &&
                                        <th>{getLocalizedMessage('grade')}</th>
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    results.map((result, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{result.admNo}</td>
                                            <td>{titleCase(result.fullName)}</td>
                                            <td>{result.score}</td>
                                            {
                                                hasGrades &&
                                                <th>{result.grade ? result.grade.toUpperCase() : ""}</th>
                                            }
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        }

                        {
                            results &&
                            <div className='mt-4'>
                                {
                                    results.length === 0 &&
                                    <Paper>
                                        <div className='p-3'>
                                            {getLocalizedMessage('resultsNotAdded')}
                                        </div>
                                    </Paper>
                                }
                            </div>
                        }

                        {
                            data.error &&
                            <Paper className='mt-4'>
                                <div className='p-3'>
                                    {data.message}
                                </div>
                            </Paper>
                        }

                    </div>
                </JssProvider>


                <Menu
                    id='moreActionMenu'
                    anchorEl={this.state.moreActionsAnchorEl}
                    open={Boolean(this.state.moreActionsAnchorEl)}
                    onClose={this.hideMoreActionMenu}
                >

                    <MenuItem onClick={this.downloadMarkSheet}>
                        <ListItemText classes={{primary: classes.primary}} primary="Download Marks Sheet"/>
                    </MenuItem>
                    {
                        classLevel && stream && year && term && exam && subject &&
                        <MenuItem onClick={this.openDialog(dialogActions.IMPORT_RESULTS)}>
                            <ListItemText classes={{primary: classes.primary}}
                                          primary={getLocalizedMessage('importResults')}/>
                        </MenuItem>
                    }
                    {
                        classLevel && stream && year && term && exam && subject && results && results.length > 0 &&
                        <MenuItem onClick={this.exportResults}>
                            <ListItemText classes={{primary: classes.primary}}
                                          primary={getLocalizedMessage('exportResults')}/>
                        </MenuItem>
                    }
                </Menu>

                {
                    (downloadingMarkSheet || exportingResults || editLoading) &&
                    <Preloader2/>
                }

                <ImportResultsDialog
                    open={dialogData[dialogActions.IMPORT_RESULTS] ? dialogData[dialogActions.IMPORT_RESULTS] : false}
                    handleClose={this.closeDialog(dialogActions.IMPORT_RESULTS)}
                    childRef={ref => this.importDialogRef = ref}
                    classLevel={classLevel} year={year} stream={stream} exam={exam} term={term} subject={subject}/>

                <AddResultDialog
                    open={dialogData[dialogActions.ADD_RESULTS] ? dialogData[dialogActions.ADD_RESULTS] : false}
                    handleClose={this.closeDialog(dialogActions.ADD_RESULTS)}
                    childRef={ref => this.addDialogRef = ref}/>
            </MuiThemeProvider>
        );
    }
}

Results.propTypes = {
    getResults: PropTypes.func.isRequired,
    editResults: PropTypes.func.isRequired,
    getTeachingSubjects: PropTypes.func.isRequired,
    getStreams: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    getClassLevels: PropTypes.func.isRequired,
    getUnAveragedExams: PropTypes.func.isRequired,
    getTerms: PropTypes.func.isRequired,
    downloadMarkSheet: PropTypes.func.isRequired,
    exportResults: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.getResultsData.data,
    editData: state.editResultsData.data,
    streamsData: state.getStreamsData.data,
    streamsLoading: state.getStreamsData.loading,
    subjectsData: state.getTeachingSubjectsData.data,
    levelsData: state.levelsData.data,
    termsData: state.termsData.data,
    examsData: state.examsData.data,
    status: state.getResultsData.status,
    loading: state.getResultsData.loading,
    exportingResults: state.exportResultsData.loading,
    downloadingMarkSheet: state.downloadMarkSheetData.loading,
    editLoading: state.editResultsData.loading,
    dialogData: state.dialogData.data,
});

export default connect(mapStateToProps, {
    getResults,
    getTeachingSubjects,
    getStreams,
    editResults,
    getClassLevels,
    getUnAveragedExams,
    getTerms,
    downloadMarkSheet,
    exportResults,
    toggleDialog
})(withStyles(styles, {withTheme: true})(Results));