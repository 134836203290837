import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import {EXAM_URL} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";

export const getExams = (levelId, termId, year) => dispatch => {
    dispatch(
        loading(actionTypes.GET_EXAMS, {}, 0, true)
    );
    axios.get(`${EXAM_URL}/${levelId}/${termId}/${year}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_EXAMS, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_EXAMS, error)
            )
        )
};

export const getUnAveragedExams = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_EXAMS, {}, 0, true)
    );
    axios.get(EXAM_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_EXAMS, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_EXAMS, error)
            )
        )
};

export const getAllExams = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_EXAMS, {}, 0, true)
    );
    axios.get(`${EXAM_URL}/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_EXAMS, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_EXAMS, error)
            )
        )
};
