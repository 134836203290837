import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import {showError} from "../../actions/helperActions";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/es/Button";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {createDate, getLocalizedMessage, titleCase} from "../../helpers";
import {format, isAfter, isBefore, parse, startOfWeek} from "date-fns";
import {DatePicker, DateTimePicker} from "material-ui-pickers";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {addAssignment, getAssignments, getHomework} from "../../actions/homeworkActions";
import {Link} from "react-router-dom";
import {schoolClassLevelName} from "../../helpers/StorageHelper";
import {getClassLevels} from "../../actions/classLevelActions";
import {getStreams} from "../../actions/streamActions";
import {getTeachingSubjects} from "../../actions/subjectActions";
import {toggleDialog} from "../../actions/commonActions";
import * as dialogActions from "../../constants/DialogActions";
import AddAssignmentDialog from "../dialogs/AddAssignmentDialog";
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({});

class Assignments extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const start = startOfWeek(today, {weekStartsOn: 1});
        this.state = {
            end: new Date(),
            start: start
        };

        this.addDialogRef = React.createRef();

        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.shouldDisableDateEnd = this.shouldDisableDateEnd.bind(this);
        this.shouldDisableDateStart = this.shouldDisableDateStart.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage("homework");
        this.props.setTitle(getLocalizedMessage("homework"));
        const {start, end} = this.state;
        if (start && end) {
            const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            this.props.getAssignments(from, to);
        }
        this.props.getClassLevels();
        this.props.getTeachingSubjects();
        this.props.getStreams();
    }

    handleStartDateChange = date => {
        this.setState({start: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getAssignments(from, to);
                }
            });
    };

    handleEndDateChange = date => {
        this.setState({end: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getAssignments(from, to);
                }
            });
    };

    shouldDisableDateStart = date => {
        const {end} = this.state;
        return isAfter(date, end);
    };

    shouldDisableDateEnd = date => {
        const {start} = this.state;
        return isBefore(date, start);
    };

    openDialog = name => e => {
        this.setState({
            anchorEl: null
        });

        if (name === dialogActions.ADD_HOMEWORK) {
            this.addDialogRef.clearFields();
        }
        this.props.toggleDialog(name, true);
    };

    closeDialog = name => e => {
        this.props.toggleDialog(name, false);
    };

    render() {
        const {data, dialogData, loading} = this.props;
        const {start, end} = this.state;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="p-3 d-flex justify-content-center">
                    <div className='notice-item-2'>

                        <Hidden mdUp implementation="css">
                            <div className='d-flex justify-content-end'>
                                <Button onClick={this.openDialog(dialogActions.ADD_HOMEWORK)} variant='contained'
                                        color='primary'>
                                    {getLocalizedMessage('addHomework')}
                                </Button>
                            </div>
                        </Hidden>
                        <div className='d-flex justify-content-start mt-3 mt-lg-0'>
                            <DatePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                leftArrowIcon={<ChevronLeftIcon/>}
                                rightArrowIcon={<ChevronRightIcon/>}
                                label={getLocalizedMessage('from')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                format="dd MMM yyyy"
                                disableFuture
                                openToYearSelection={false}
                                shouldDisableDate={this.shouldDisableDateStart}
                            />
                            <div className='ml-3'>
                                <DatePicker
                                    value={end}
                                    onChange={this.handleEndDateChange}
                                    leftArrowIcon={<ChevronLeftIcon/>}
                                    rightArrowIcon={<ChevronRightIcon/>}
                                    label={getLocalizedMessage('to')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    format="dd MMM yyyy"
                                    disableFuture
                                    openToYearSelection={false}
                                    shouldDisableDate={this.shouldDisableDateEnd}
                                />
                            </div>
                            <div className='justify-content-end ml-auto'>
                                <Hidden smDown implementation="css">
                                    <Button onClick={this.openDialog(dialogActions.ADD_HOMEWORK)} variant='contained'
                                            color='primary'>
                                        {getLocalizedMessage('addHomework')}
                                    </Button>
                                </Hidden>
                            </div>
                        </div>
                        <div className='mt-4'>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-1'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                data.data && data.data.length > 0 && !loading &&
                                <div>
                                    {
                                        data.data.map((item, index) => {
                                            const date = parse(item.date, "yyyy-MM-dd", new Date());
                                            const displayDate = format(date, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});
                                            const submissionDate = parse(item.submissionDate, "yyyy-MM-dd", new Date());
                                            const displaySubmissionDate = format(submissionDate, "EEE do MMM, yyyy", {awareOfUnicodeTokens: true});

                                            return (
                                                <Paper key={index} className='mb-3'>
                                                    <div className="p-3 text-dark">
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('subject')}</strong>: <span>{titleCase(item.subject)}</span>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>{titleCase(schoolClassLevelName())}</strong>: <span>{titleCase(item.level)}</span>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('stream')}</strong>: <span>{titleCase(item.stream)}</span>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('date')}</strong>: <span>{displayDate}</span>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('submissionDate')}: </strong>
                                                            <span>{displaySubmissionDate}</span>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div>
                                                                <strong>{getLocalizedMessage('instructions')}: </strong>
                                                            </div>
                                                            <pre>{item.instructions}</pre>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                data.data && data.data.length === 0 && !loading &&
                                <Paper>
                                    <div className='p-3'>
                                        {getLocalizedMessage('noHomework')}
                                    </div>
                                </Paper>
                            }
                        </div>
                    </div>
                </div>

                <AddAssignmentDialog
                    open={dialogData[dialogActions.ADD_HOMEWORK] ? dialogData[dialogActions.ADD_HOMEWORK] : false}
                    handleClose={this.closeDialog(dialogActions.ADD_HOMEWORK)}
                    childRef={ref => this.addDialogRef = ref}/>
            </MuiThemeProvider>
        );
    }
}

Assignments.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getAssignments: PropTypes.func.isRequired,
    getClassLevels: PropTypes.func.isRequired,
    getStreams: PropTypes.func.isRequired,
    getTeachingSubjects: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.homeworkData.data,
    status: state.homeworkData.status,
    loading: state.homeworkData.loading,
    dialogData: state.dialogData.data,
});

export default connect(mapStateToProps, {
    getAssignments,
    getClassLevels,
    getStreams,
    getTeachingSubjects,
    toggleDialog
})(withStyles(styles, {withTheme: true})(Assignments));