import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {DialogContent, DialogTitle} from "./Common";
import {connect} from "react-redux";
import {showError} from "../../actions/helperActions";
import {allowedExcelExtensions} from "../../constants/constants";
import {getLocalizedMessage, toastError, verifyExtension} from "../../helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from '@material-ui/icons/Close';
import {IconButton, withMobileDialog} from "@material-ui/core";
import {importResults} from "../../actions/resultActions";
import {GET_STREAM_STUDENTS} from "../../constants/actionTypes";

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PaperComponent(props) {
    return (
        <Draggable cancel='.input'>
            <Paper {...props} />
        </Draggable>
    );
}

class ImportResultsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
        };

        this.inputRef = React.createRef();

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.clearFile = this.clearFile.bind(this);
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    clearFields = () => {
        if (this.inputRef.current) {
            this.inputRef.current.value = null;
        }
        this.setState({file: null});
    };

    handleInputChange(e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        const fileName = file.name;
        if (verifyExtension(fileName, allowedExcelExtensions) === false) {
            toastError(getLocalizedMessage('fileNotExcel'));
            this.setState({
                file: null
            });
        } else {
            reader.onloadend = () => {
                this.setState({
                    file: file
                });
            };

            reader.readAsDataURL(file)
        }
    }

    handleSubmit = (e) => {
        const {file} = this.state;
        if (file) {
            const data = new FormData();
            data.append('file', file);
            const {classLevel, year, stream, exam, term, subject}  = this.props;
            this.props.importResults(classLevel, stream, year, term, exam, subject, data);
        } else {
            toastError(getLocalizedMessage('pleaseChooseFile'));
            this.setState({
                file: null
            })
        }
    };

    closeDialog = event => {
        this.setState({file: null});
        this.props.handleClose();
    };

    clearFile = event => {
        this.inputRef.current.value = null;
        this.setState({file: null});
    };

    render() {
        const {open, fullScreen, loading} = this.props;
        const {file} = this.state;

        return (
            <Dialog open={open}
                    TransitionComponent={Transition}
                    maxWidth="xs"
                    fullWidth
                    PaperComponent={PaperComponent}
                    onClose={this.closeDialog}
                    aria-labelledby="customized-dialog-title"
                    fullScreen={fullScreen}
            >
                <DialogTitle onClose={this.closeDialog}>
                    {getLocalizedMessage('importResultsExcelFile')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className=' mt-4 ml-3 mr-3'>
                            <input
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                style={{display: 'none'}}
                                id="raised-button-file"
                                type="file"
                                onChange={this.handleInputChange}
                                ref={this.inputRef}
                            />
                            <label htmlFor="raised-button-file">
                                <Button variant="contained" component="span">
                                    {getLocalizedMessage('chooseFile')}
                                </Button>
                            </label>

                            {
                                file &&
                                    <div className='mt-3'>
                                        <span className='file-name-container'>
                                            <span className='font-weight-bold'>{file.name}</span>
                                            <IconButton onClick={this.clearFile}>
                                                <CloseIcon/>
                                            </IconButton>
                                        </span>
                                    </div>
                            }

                        </div>
                        <Divider light={false} className='mt-3'/>
                        <div className='p-3 d-flex justify-content-end'>
                            <Button color="primary" type="submit" variant='contained'
                                    disabled={!file || loading} onClick={this.handleSubmit}>
                                {
                                        !loading &&
                                        <span>{getLocalizedMessage('submit')}</span>
                                    }
                                    {
                                        loading &&
                                        <span>
                                            <span className='mr-2'>{getLocalizedMessage('processing')}</span>
                                        <CircularProgress color='primary' size={30} thickness={5}/>
                                        </span>
                                    }
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

ImportResultsDialog.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    importResults: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.importResultsData.data,
    loading: state.importResultsData.loading,
});

export default connect(mapStateToProps, {
    importResults
})(withMobileDialog()(withStyles(styles, {withTheme: true})(ImportResultsDialog)));