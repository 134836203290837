import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";
import {DIALOG_DATA} from "../constants/actionTypes";

export const getSchools = countryId => dispatch => {
    dispatch(
        loading(actionTypes.GET_SCHOOLS, {}, 0, true)
    );
    axios.get(`${urls.SCHOOL_URL}/${countryId}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_SCHOOLS, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_SCHOOLS, error)
            )
        )
};

export const getCountries = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_COUNTRIES, {}, 0, true)
    );
    axios.get(urls.COUNTRY_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_COUNTRIES, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(actionTypes.GET_COUNTRIES, error)
            )
        )
};

export const toggleDialog = (name, open, data = {}) => dispatch => {
    const myData = {...data};
    myData[name] = open;
    dispatch(
        loading(DIALOG_DATA, myData, 0, true)
    );
};

export const clearStore = actionType => dispatch => {
    dispatch(loading(actionType, {}, 0, false));
};
