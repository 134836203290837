import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='mt-5'/>
        );
    }
}

Footer.propTypes = {};

export default Footer;