import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import 'cropperjs/dist/cropper.css';
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {reserveBook} from "../../actions/libraryActions";
import {getLocalizedMessage} from "../../helpers";
import {withMobileDialog} from "@material-ui/core";

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label={getLocalizedMessage('close')} className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class ReserveBookDialog extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleSubmit = e => {
        this.props.reserveBook(this.props.bookId)
    };

    render() {
        const {loading, open, bookTitle, fullScreen} = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.props.closeDialog}
                TransitionComponent={Transition}
                fullWidth
                maxWidth='xs'
                fullScreen={fullScreen}
            >
                <DialogTitle onClose={this.props.closeDialog}>
                    {getLocalizedMessage('reserveBook')}
                </DialogTitle>
                <DialogContent>
                    <div>
                        <strong>{getLocalizedMessage('bookTitle')}</strong>: <span>{bookTitle}</span>
                    </div>

                    <div className='mt-2'>{getLocalizedMessage('reserveBookMessage')}</div>
                </DialogContent>
                <DialogActions>
                    {
                        loading &&
                        <div>
                            <CircularProgress size={30} thickness={5} className='ml-3'/>
                        </div>
                    }
                    {
                        !loading &&
                        <Button onClick={this.props.handleClose} color="default">
                            {getLocalizedMessage('cancel')}
                        </Button>
                    }
                    {
                        !loading &&
                        <Button type='submit' color="primary" onClick={this.handleSubmit}>
                            {getLocalizedMessage('reserve')}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

ReserveBookDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    reserveBook: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    bookId: PropTypes.number.isRequired,
    bookTitle: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
    data: state.reserveBookData.data,
    loading: state.reserveBookData.loading,
});

export default connect(mapStateToProps, {
    reserveBook
})(withMobileDialog()(withStyles(styles, {withTheme: true})(ReserveBookDialog)));