import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getEvent, getEvents, searchEvents} from "../../actions/eventActions";
import connect from "react-redux/src/connect/connect";
import withStyles from "@material-ui/core/styles/withStyles";
import {mainTheme} from "../../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {format} from "date-fns";
import {createDate, getLocalizedMessage, trimString} from "../../helpers";
import SearchIcon from '@material-ui/icons/Search';
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import {EVENT_IMG_URL} from "../../constants/request_url";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {withRouter} from "react-router-dom";
import history from '../../history';
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Event extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('event');
        this.props.setTitle(getLocalizedMessage('event'));
        this.props.getEvent(parseInt(this.props.match.params.id, 10));
        this.props.getEvents(0, 5);

        window.onpopstate = () => {
            const {match} = this.props;

            if (this.props.history.action === 'POP' && this.props.history.location !== '/') {
                this.props.getEvent(parseInt(match.params.id));
            }
        }
    }

    updateEvent = eventId => e => {
        window.history.pushState({
            id: `event_${eventId}`
        }, 'Event', `/events/${eventId}`);

        /*if (history) {
            this.props.history.push(`/event/${eventId}`);
        }*/

        this.props.getEvent(eventId);
    };

    handleChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    handleSearch = event => {
        const {query} = this.state;
        if (query.trim().length > 2) {
            if (history) {
                this.props.history.push(`/events/search/${query}`);
            }
        }
    };

    onKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        const {message} = this.state;
        if (code === 13) {
            event.preventDefault();
            const {query} = this.state;
            if (query.trim().length > 2) {
                if (history) {
                    this.props.history.push(`/events/search/${query}`);
                }
            }
        }
    };

    render() {
        const {classes, data, eventsData, eventsLoading, loading, status} = this.props;
        const {query} = this.state;
        const {events} = eventsData;
        let displayDate = null;
        let displayTime = null;

        if (data) {
            if (data.date) {
                const date = createDate(data.date);
                displayDate = format(date, "EEE do MMM, yyyy",
                    {awareOfUnicodeTokens: true});
                displayTime = format(date, "h:mm bb",
                    {awareOfUnicodeTokens: true});
            }
        }
        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="">
                    <div className="row m-0">
                        <div className="col-12 order-0 order-md-1 col-md-4 mb-0 mb-lg-4 mt-3 mt-lg-0">
                            <Paper className={`${classes.root} mb-4`} elevation={1}>
                                <InputBase className={classes.input} placeholder={getLocalizedMessage('searchEvents')}
                                           value={query}
                                           name='query'
                                           onChange={this.handleChange}
                                           onKeyPress={this.onKeyPress}/>
                                <IconButton className={classes.iconButton} aria-label={getLocalizedMessage('search')}
                                            onClick={this.handleSearch}>
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                            <Hidden smDown implementation="css">
                                <div>
                                    <h5 className="text-primary">{getLocalizedMessage('latest')}</h5>
                                    {
                                        eventsLoading &&
                                        <CircularProgress color='primary' size={50} thickness={5}/>
                                    }
                                    <Paper className='w-100'>
                                        {
                                            events &&
                                            <div>
                                                {
                                                    events.map((event, index) => (
                                                        <div key={index} className='recent-event-container'
                                                             onClick={this.updateEvent(event.id)}>
                                                            <div className="p-3 d-flex">
                                                                <img src={`${EVENT_IMG_URL}/${event.imgUri}`}
                                                                     alt="events"
                                                                     className="recent-event-img"/>
                                                                <div className="ml-3">
                                                                    <div>
                                                                        <strong className='text-dark'>
                                                                            {trimString(event.title, 20)}
                                                                        </strong>
                                                                    </div>
                                                                    <div className='text-dark'>
                                                                        {trimString(event.description, 25)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Divider/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </Paper>
                                </div>
                            </Hidden>
                        </div>
                        <div className="col-12 order-1 order-md-0 col-md-8">
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-5'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                data && !loading &&
                                <Paper className={`${classes.paper} single-event-container`}>
                                    {
                                        data && !loading &&
                                        <Paper className={`${classes.paper} single-event-container`}>
                                            {
                                                status === 200 &&
                                                <div>
                                                    <img src={`${EVENT_IMG_URL}/${data.imgUri}`}
                                                         alt="" className="single-event-img"/>
                                                    {/*<div className="d-flex">
                                                        <h6 className="text-dark p-2">{data.title}</h6>
                                                        <div className="p-2 ml-auto event-date">
                                                            {displayDate} <span className="ml-3">{displayTime}</span>
                                                        </div>
                                                    </div>
                                                    <div className="p-2">{data.description}</div>*/}

                                                    <div className="d-flex">
                                                        <div className="p-2 event-date">
                                                            {displayDate}
                                                        </div>
                                                        <div className="p-2 event-date">
                                                            {displayTime}
                                                        </div>
                                                    </div>
                                                    <div className="text-dark pl-2">
                                                        <strong>{data.title}</strong>
                                                    </div>
                                                    <div className='text-dark pl-2 pb-2'>
                                                        {data.description}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                status === 404 &&
                                                <div className='p-3'>
                                                    <h5>{getLocalizedMessage('invalidEvent')}</h5>
                                                </div>
                                            }
                                        </Paper>
                                    }
                                </Paper>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Event.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getEvent: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
    searchEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.getSingleEventData.data,
    eventsData: state.getEventsData.data,
    eventsLoading: state.getEventsData.loading,
    status: state.getSingleEventData.status,
    loading: state.getSingleEventData.loading
});

export default connect(mapStateToProps, {
    getEvent,
    getEvents,
    searchEvents
})(withRouter(withStyles(styles, {withTheme: true})(Event)));