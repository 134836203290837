import {dialogData, loading, loadingMore} from "./helperActions";
import axios from "../axios/axios";
import {FORUM_URL} from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig} from "../constants/constants";
import {
    ADD_POST_DIALOG,
    GET_ALL_COMMENTS, GET_ALL_REPLIES,
    GET_COMMENTS,
    GET_POSTS,
    GET_REPLIES,
    SEND_COMMENTS,
    SEND_POSTS,
    SEND_REPLIES, UPLOAD_POST_PHOTO
} from "../constants/actionTypes";
import {userPublicId} from "../helpers/StorageHelper";

export const getPosts = (offset, postsData) => dispatch => {
    if (offset === 0) {
        dispatch(
            loading(GET_POSTS, postsData, 0, true)
        );
    } else {
        dispatch(
            loadingMore(GET_POSTS, postsData, 0, true)
        );
    }
    axios.get(`${FORUM_URL}/teacher/${offset}`)
        .then(response => {
            let myPosts = [...postsData.posts];
            myPosts = [...myPosts, ...response.data.posts];
            const data = {...response.data};
            data.posts = myPosts;
            dispatch(
                handleSuccessResponse(GET_POSTS, data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_POSTS, error, true, true, postsData)
                );
            }
        )
};

export const getComments = (postId, offset, commentsData) => dispatch => {
    if (offset === 0) {
        dispatch(
            loading(GET_COMMENTS, commentsData, 0, true)
        );
    } else {
        dispatch(
            loadingMore(GET_COMMENTS, commentsData, 0, true)
        );
    }
    axios.get(`${FORUM_URL}/teacher/comment/${postId}/${offset}`)
        .then(response => {
            let myComments = [];
            if (commentsData.comments) {
                myComments = [...commentsData.comments];
            }
            myComments = [...myComments, ...response.data.comments];
            let myReplies = [];
            if (commentsData.replies) {
                myReplies = [...commentsData.replies];
            }
            myReplies = [...myReplies, ...response.data.replies];
            const data = {...response.data};
            data.comments = myComments;
            data.replies = myReplies;
            dispatch(
                handleSuccessResponse(GET_COMMENTS, data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_COMMENTS, error, true, true, commentsData)
                );
            }
        )
};

export const getReplies = (postId, offset, replies, comments) => dispatch => {
    if (offset === 0) {
        dispatch(
            loading(GET_REPLIES, {comments: comments, replies: replies}, 0, true)
        );
    } else {
        dispatch(
            loadingMore(GET_REPLIES, {comments: comments, replies: replies}, 0, true)
        );
    }
    axios.get(`${FORUM_URL}/teacher/reply/all`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_REPLIES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(GET_REPLIES, error)
                );
            }
        )
};

export const newPost = (postsData, newItem) => dispatch => {
    let clonedPosts = [];
    if (postsData.posts) {
        clonedPosts = [...postsData.posts];
    }
    const filtered = clonedPosts.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && userPublicId() === newItem.fromIdNo && item.id === 0));
    if (filtered.length > 0) {
        clonedPosts.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                userPublicId() === newItem.fromIdNo && item.id === 0)) {
                clonedPosts.splice(index, 1, newItem);
            }
        });
    } else {
        clonedPosts.splice(0, 0, newItem);
    }
    const data = {...postsData};
    data.posts = clonedPosts;

    if (filtered.length === 0) {
        data.nextPage += 1;
        data.lastPage += 1;
    }
    dispatch(
        handleSuccessResponse(GET_POSTS, data, 200)
    );
};

export const newComment = (commentsData, newItem) => dispatch => {
    const clonedComments = [...commentsData.comments];
    const filtered = clonedComments.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && userPublicId() === newItem.fromIdNo));
    if (filtered.length > 0) {
        clonedComments.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                userPublicId() === newItem.fromIdNo && item.id === 0)) {
                clonedComments.splice(index, 1, newItem);
            }
        });
    } else {
        clonedComments.splice(0, 0, newItem);
    }
    const data = {...commentsData};
    data.comments = clonedComments;

    if (filtered.length === 0) {
        data.nextPage += 1;
        data.lastPage += 1;
    }

    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const newReply = (commentsData, newItem) => dispatch => {
    const clonedReplies = [...commentsData.replies];
    const filtered = clonedReplies.filter(item => item.id === newItem.id ||
        (item.pendingId === newItem.pendingId && userPublicId() === newItem.fromIdNo));
    if (filtered.length > 0) {
        clonedReplies.forEach((item, index) => {
            if (item.id === newItem.id || (item.pendingId === newItem.pendingId &&
                userPublicId() === newItem.fromIdNo && item.id === 0)) {
                clonedReplies.splice(index, 1, newItem);
            }
        });
    } else {
        clonedReplies.splice(0, 0, newItem);
    }
    const data = {...commentsData};
    data.replies = clonedReplies;
    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const addPost = (postsData, newPost) => dispatch => {
    const posts = [newPost, ...postsData.posts];
    const data = {...postsData};
    data.posts = posts;
    data.nextPage += 1;
    data.lastPage += 1;
    dispatch(
        handleSuccessResponse(GET_POSTS, data, 200)
    );
};

export const addComment = (allComments, commentsData, newComment) => dispatch => {
    const comments = [newComment, ...commentsData.comments];
    const myComments = [...allComments, newComment];
    const allData = {comments: myComments};
    dispatch(
        handleSuccessResponse(GET_ALL_COMMENTS, allData, 200)
    );
    const data = {...commentsData};
    data.comments = comments;
    data.nextPage += 1;
    data.lastPage += 1;
    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const addReply = (allReplies, commentsData, newReply) => dispatch => {
    const replies = [newReply, ...commentsData.replies];
    const myReplies = [...allReplies, newReply];
    const allData = {replies: myReplies};
    dispatch(
        handleSuccessResponse(GET_ALL_REPLIES, allData, 200)
    );
    const data = {...commentsData};
    data.replies = replies;
    dispatch(
        handleSuccessResponse(GET_COMMENTS, data, 200)
    );
};

export const sendPosts = (postsData, data) => dispatch => {
    dispatch(
        loading(SEND_POSTS, {}, 0, true)
    );
    axios.post(`${FORUM_URL}/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            const clonedPosts = [...postsData.posts];
            response.data.posts.forEach(newItem => {
                const filtered = clonedPosts.filter(item => item.pendingId === newItem.pendingId &&
                    userPublicId() === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    clonedPosts.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            userPublicId() === newItem.fromIdNo && item.id === 0) {
                            clonedPosts.splice(index, 1, newItem);
                        }
                    });
                }
            });
            const data = {...postsData};
            data.posts = clonedPosts;
            dispatch(
                handleSuccessResponse(GET_POSTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_POSTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_POSTS, error)
                );
            }
        )
};

export const sendComments = (allComments, commentsData, data) => dispatch => {
    dispatch(
        loading(SEND_COMMENTS, {}, 0, true)
    );
    const myComments = [...allComments];
    const comments = [...commentsData.comments];
    axios.post(`${FORUM_URL}/comment/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            response.data.comments.forEach(newItem => {
                const filtered = comments.filter(item => item.pendingId === newItem.pendingId &&
                    userPublicId() === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    comments.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            userPublicId() === newItem.fromIdNo && item.id === 0) {
                            comments.splice(index, 1, newItem);
                        }
                    });
                }

                const filtered2 = myComments.filter(item => item.pendingId === newItem.pendingId &&
                    userPublicId() === newItem.fromIdNo && item.id === 0);
                if (filtered2.length > 0) {
                    myComments.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            userPublicId() === newItem.fromIdNo && item.id === 0) {
                            myComments.splice(index, 1);
                        }
                    });
                }
            });
            const myData = {comments: myComments};
            dispatch(
                handleSuccessResponse(GET_ALL_COMMENTS, myData, 200)
            );
            const data = {...commentsData};
            data.comments = comments;
            dispatch(
                handleSuccessResponse(GET_COMMENTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_COMMENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_COMMENTS, error)
                );
            }
        )
};

export const sendReplies = (allReplies, commentsData, data) => dispatch => {
    dispatch(
        loading(SEND_REPLIES, {}, 0, true)
    );
    const myReplies = [...allReplies];
    const replies = [...commentsData.replies];
    axios.post(`${FORUM_URL}/reply/teacher/multi/2`, data, axiosConfig)
        .then(response => {
            response.data.replies.forEach(newItem => {
                const filtered = replies.filter(item => item.pendingId === newItem.pendingId &&
                    userPublicId() === newItem.fromIdNo && item.id === 0);
                if (filtered.length > 0) {
                    replies.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            userPublicId() === newItem.fromIdNo && item.id === 0) {
                            replies.splice(index, 1, newItem);
                        }
                    });
                }

                const filtered2 = myReplies.filter(item => item.pendingId === newItem.pendingId &&
                    userPublicId() === newItem.fromIdNo && item.id === 0);
                if (filtered2.length > 0) {
                    myReplies.forEach((item, index) => {
                        if (item.pendingId === newItem.pendingId &&
                            userPublicId() === newItem.fromIdNo && item.id === 0) {
                            myReplies.splice(index, 1);
                        }
                    });
                }
            });
            const myData = {replies: myReplies};
            dispatch(
                handleSuccessResponse(GET_ALL_REPLIES, myData, 200)
            );

            const data = {...commentsData};
            data.replies = replies;
            dispatch(
                handleSuccessResponse(GET_COMMENTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_REPLIES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_REPLIES, error)
                );
            }
        )
};

export const togglePostDialog = open => dispatch => {
    dispatch(dialogData(ADD_POST_DIALOG, {open: open}));
};

export const uploadPostImage = (postsData, data) => dispatch => {
    dispatch(
        loading(SEND_POSTS, {}, 0, true)
    );
    dispatch(
        loading(UPLOAD_POST_PHOTO, {}, 0, true)
    );
    axios.post(`${FORUM_URL}/teacher/image/2`, data, axiosConfig)
        .then(response => {
            const clonedPosts = [...postsData.posts];
            clonedPosts.splice(0, 0, response.data.post);
            const data = {...postsData};
            data.posts = clonedPosts;

            data.nextPage += 1;
            data.lastPage += 1;
            dispatch(
                handleSuccessResponse(GET_POSTS, data, 200)
            );

            dispatch(
                handleSuccessResponse(SEND_POSTS, response.data, response.status)
            );

            dispatch(
                handleSuccessResponse(UPLOAD_POST_PHOTO, response.data, response.status)
            );

            dispatch(togglePostDialog(false));

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: "auto"
                });
            }, 100);
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(SEND_POSTS, error)
                );
                dispatch(
                    handleErrorResponse(UPLOAD_POST_PHOTO, error, true)
                );
            }
        )
};