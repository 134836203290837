import React, {Component} from 'react';
import Paper from "@material-ui/core/Paper";
import logo from "../../images/app_logo.png";
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {currentCountryId, getLocalizedMessage, setSchool, titleCase} from "../../helpers";
import {SCHOOL_LOGO_URL} from "../../constants/request_url";
import CircularProgress from "@material-ui/core/CircularProgress";
import {mainTheme} from "../../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {withLastLocation} from "react-router-last-location";
import {getSchools} from "../../actions/commonActions";

class SelectSchool extends Component {

    constructor(props) {
        super(props);
        this.handleSelectSchool = this.handleSelectSchool.bind(this);
    }


    componentDidMount() {
        document.title = getLocalizedMessage('selectSchool');
        const countryId = currentCountryId();
        if (countryId) {
            this.props.getSchools(countryId);
        }
    }

    handleSelectSchool = school => event => {
        setSchool(school.publicId, school.name, school.imageUri);
        window.location.reload();
    };

    render() {
        const {items} = this.props.data;
        const {loading} = this.props;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="container-fluid-alt d-flex align-items-center justify-content-center bg-white mt-5 mb-5">
                    <Paper className='select-school-container'>
                        <div className="w-100  pl-3 pb-5 pt-3 pr-lg-5 pl-lg-5">
                            <div className="select-school-logo-container">
                                <img src={logo} alt="company logo" className="select-school-logo"/>
                            </div>
                            <h5 className="text-center form-header font-lora mb-3 font-weight-bold mt-2">{getLocalizedMessage('selectSchool')}</h5>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-3'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                items &&
                                <div>
                                    {
                                        items.map((item, index) => (
                                            <div onClick={this.handleSelectSchool(item)}
                                            key={index} className='cursor-pointer hover-bg-gray'>
                                                <div
                                                    className='d-flex justify-content-start align-items-center pt-2'>
                                                    <img
                                                                src={`${SCHOOL_LOGO_URL}/${item.imageUri}`}
                                                                alt="profile photo"
                                                                className="select-school-img mr-3"/>
                                                    <div className='pt-2 pb-2'>
                                                        <div className='font-lato font-weight-bold'>
                                                            {titleCase(item.name)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Divider className='mt-2'/>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </Paper>
                </div>
            </MuiThemeProvider>
        );
    }
}

SelectSchool.propTypes = {
    getSchools: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.schoolsData.data,
    loading: state.schoolsData.loading,
});

export default connect(mapStateToProps, {getSchools})(withLastLocation(SelectSchool));