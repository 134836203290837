import {PROFILE_IMG_URL} from "../constants/request_url";

export const schoolClassLevelName = () => {
    return localStorage.getItem("teacherLevelName")
};

export const schoolTermName = () => {
    return localStorage.getItem("teacherTermName")
};

export const userPublicId = () => {
    return localStorage.getItem("teacherPublicId")
};

export const userImageUri = () => {
    const imgUri = localStorage.getItem("teacherImageUri");

    if (imgUri === 'null' || !imgUri) {
        return null
    }

    return imgUri

};

export const userFullImageUri = () => {
    const imgUri = localStorage.getItem("teacherImageUri");

    if (imgUri === 'null' || !imgUri) {
        return null
    }

    if (imgUri) {
        return `${PROFILE_IMG_URL}/${imgUri}`
    }
};