import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider/Divider';
import {NavLink, withRouter} from 'react-router-dom';
import ReceiptIcon from '@material-ui/icons/Receipt';
import {withStyles} from '@material-ui/core/styles'
import List from '@material-ui/core/List';
import ScheduleIcon from "@material-ui/icons/Schedule";
import ForumIcon from "@material-ui/icons/Forum";
import ViewListIcon from "@material-ui/icons/ViewList";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SubjectIcon from "@material-ui/icons/Subject";
import EventIcon from '@material-ui/icons/Event'
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import {currentSchoolLogo, currentSchoolName, getLocalizedMessage, hasModule} from "../../helpers";
import history from "../../history";
import {
    MODULE_ATTENDANCE,
    MODULE_COMMUNICATION,
    MODULE_EXAM, MODULE_HOMEWORK,
    MODULE_LEAVES, MODULE_LIBRARY,
    MODULE_TIMETABLE
} from "../../constants/constants";

const styles = theme => ({
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    }
});

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub_menu: {
                attendance: false,
                leave: false,
                result: false,
                homework: false
            },
            open: false,
            addResult: false,
            markAttendance: false,
            issueLeave: false,
            addHomework: false
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    handleOpen = name => e => {
        if (!this.state.sub_menu[name]) {
            const {sub_menu} = this.state;
            const keys = Object.keys(sub_menu);

            keys.forEach(key => {
                if (sub_menu[key])
                    this.setState({
                        sub_menu: {
                            [key]: false
                        }
                    })
            });
        }


        this.setState(state => ({
            sub_menu: {
                [name]: !state.sub_menu[name]
            }
        }));

    };

    openDialog = name => e => {
        this.setState({[name]: true});
    };

    handleMenuClick = route => e => {
        e.preventDefault();
        this.props.handleDrawerClose();
        if (history) {
            this.props.history.push(route);
        }
    };

    render() {
        const {modules} = this.props;
        return (
            <div>
                <div className="mr-auto d-flex align-items-center p-2 toolbar-logo-container-sm">
                    <img src={currentSchoolLogo()}
                         alt="logo" className="toolbar-account-img"/>
                    <div className="font-weight-bold text-dark text-capitalize ml-2 font-lor">
                        {currentSchoolName()}
                    </div>
                </div>
                <Divider className='w-100 toolbar-logo-container-sm'/>
                <List
                    component="nav"
                >

                    <NavLink to='/' onClick={this.handleMenuClick('/')} activeClassName='active-nav' exact strict>
                        <ListItem button className='nav-item'>
                            <ListItemIcon>
                                <EventIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary={getLocalizedMessage('events')}/>
                        </ListItem>
                    </NavLink>


                    {
                        hasModule(MODULE_EXAM, modules) &&
                        <NavLink to='/result' onClick={this.handleMenuClick('/result')} activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ListAltIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('subjectResults')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_EXAM, modules) &&
                        <NavLink to='/overall' onClick={this.handleMenuClick('/overall')} activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ListIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('overallResults')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_ATTENDANCE, modules) &&
                        <NavLink to='/attendance' onClick={this.handleMenuClick('/attendance')}
                                 activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ViewListIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('attendance')}/>
                            </ListItem>
                        </NavLink>
                    }


                    {
                        hasModule(MODULE_LEAVES, modules) &&
                        <NavLink to='/leave' onClick={this.handleMenuClick('/leave')} activeClassName='active-nav' exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ReceiptIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('leaves')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_TIMETABLE, modules) &&
                        <NavLink to='/timetable' onClick={this.handleMenuClick('/timetable')}
                                 activeClassName='active-nav'
                                 exact strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ScheduleIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('myTimetable')}/>
                            </ListItem>
                        </NavLink>
                    }

                    <NavLink to='/subjects' onClick={this.handleMenuClick('/subjects')} activeClassName='active-nav'
                             exact strict>
                        <ListItem button className='nav-item'>
                            <ListItemIcon>
                                <SubjectIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary={getLocalizedMessage('mySubjects')}/>
                        </ListItem>
                    </NavLink>


                    {
                        hasModule(MODULE_HOMEWORK, modules) &&
                        <NavLink to='/homework' onClick={this.handleMenuClick('/homework')} activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <Icon className={classNames('fa fa-file-o fa- menu-list-icon')}/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('homework')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_LIBRARY, modules) &&
                        <NavLink to='/library' onClick={this.handleMenuClick('/library')} activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon><i className='fa fa-book menu-list-icon'/></ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('library')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_LIBRARY, modules) &&
                        <NavLink to='/library/history' onClick={this.handleMenuClick('/library/history')}
                                 activeClassName='active-nav' exact strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon><i className='fa fa-bars menu-list-icon'/></ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('libraryHistory')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {/*<NavLink to='/chat' onClick={this.handleMenuClick('/chat')} activeClassName='active-nav' exact
                             strict>
                        <ListItem button className='nav-item'>
                            <ListItemIcon>
                                <ChatIcon className='menu-list-icon'/>
                            </ListItemIcon>
                            <ListItemText primary={getLocalizedMessage('chats')}/>
                        </ListItem>
                    </NavLink>*/}

                    {
                        hasModule(MODULE_COMMUNICATION, modules) &&
                        <NavLink to='/forum' onClick={this.handleMenuClick('/forum')} activeClassName='active-nav' exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <ForumIcon className='menu-list-icon'/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('forum')}/>
                            </ListItem>
                        </NavLink>
                    }

                    {
                        hasModule(MODULE_COMMUNICATION, modules) &&
                        <NavLink to='/notice' onClick={this.handleMenuClick('/notice')} activeClassName='active-nav'
                                 exact
                                 strict>
                            <ListItem button className='nav-item'>
                                <ListItemIcon>
                                    <Icon className={classNames('fa fa-bell-o menu-list-icon')}/>
                                </ListItemIcon>
                                <ListItemText primary={getLocalizedMessage('noticeboard')}/>
                            </ListItem>
                        </NavLink>
                    }

                    <Divider/>
                </List>

            </div>
        )
            ;
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Menu));