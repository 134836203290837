import React, {Component} from 'react';
import {mainTheme} from "../../themes/Themes";
import Paper from "@material-ui/core/Paper";
import Footer from "../footer/Footer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {Divider} from "@material-ui/core";
import UserPlaceHolderImg from "../../images/placeholders/user.jpg";
import UploadProfilePhotoDialog from "../dialogs/UploadProfilePhotoDialog";
import {PROFILE_IMG_URL} from "../../constants/request_url";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {toggleDialog} from "../../actions/commonActions";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as dialogActions from "../../constants/DialogActions";
import {getLocalizedMessage} from "../../helpers";
import {getAccountInfo} from "../../actions/accountActions";
import CircularProgress from "@material-ui/core/es/CircularProgress";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Account extends Component {
    constructor(props) {
        super(props);

        this.uploadDialogRef = React.createRef();

        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('account');
        this.props.setTitle(getLocalizedMessage('account'));
        this.props.getAccountInfo();
    }

    openDialog = name => e => {
        this.setState({
            anchorEl: null
        });

        if (name === dialogActions.UPLOAD_PROFILE_PHOTO) {
            this.uploadDialogRef.clearFields();
        }
        this.props.toggleDialog(name, true);
    };

    closeDialog = name => e => {
        this.props.toggleDialog(name, false);
    };

    render() {
        const {dialogData, data, loading} = this.props;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className='d-flex justify-content-center'>
                    <div className='notice-item mt-3 mt-lg-0'>
                        {
                            loading &&
                            <div className='w-100 d-flex justify-content-center pt-1'>
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            </div>
                        }
                        {
                            !loading && data.name &&
                            <Paper elevation={1} className='p-3'>
                                <div className='profile-img-container'>
                                    <div className="d-flex justify-content-center">
                                        {
                                            data.imageUri ?
                                                <img
                                                    src={`${PROFILE_IMG_URL}/${localStorage.getItem('teacherImageUri')}`}
                                                    alt="profile photo" className="account-img"/>
                                                :
                                                <img
                                                    src={UserPlaceHolderImg}
                                                    alt="profile photo" className="account-img"/>
                                        }</div>
                                    <div className='profile-img-overlay-container'>
                                        <div
                                            className='profile-img-overlay d-flex justify-content-center align-items-center'>
                                            <div className='profile-text'
                                                 onClick={this.openDialog(dialogActions.UPLOAD_PROFILE_PHOTO)}>{getLocalizedMessage('change')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-capitalize text-center mt-2 text-black-50'>
                                    {data.name}
                                </div>
                                {
                                    data.role &&
                                    <div className='text-capitalize text-center mt-2 text-black-50'>
                                        {data.role}
                                    </div>
                                }
                                <Divider className='mt-3'/>
                                <div className='d-flex justify-content-between mt-2'>
                                    <div className=''>{getLocalizedMessage('gender')}:</div>
                                    <div className='text-black-50'>{data.gender}</div>
                                </div>
                                <Divider className='mt-2'/>
                                <div className='d-flex justify-content-between mt-2'>
                                    <div className=''>{getLocalizedMessage('email')}:</div>
                                    <div className='text-black-50'>{data.email}</div>
                                </div>
                                <Divider className='mt-2'/>
                                <div className='d-flex justify-content-between mt-2'>
                                    <div className=''>{getLocalizedMessage('phoneNo')}:</div>
                                    <div className='text-black-50'>{data.phoneNo}</div>
                                </div>

                                {
                                    data.dutyDate &&
                                    <div>
                                        <Divider className='mt-2'/>
                                        <div className='d-flex justify-content-between mt-2'>
                                            <div className=''>{getLocalizedMessage('dateOnDuty')}:</div>
                                            <div className='text-black-50'>{data.dutyDate}</div>
                                        </div>
                                    </div>
                                }
                            </Paper>
                        }
                    </div>
                </div>
                <Footer/>

                <UploadProfilePhotoDialog
                    open={dialogData[dialogActions.UPLOAD_PROFILE_PHOTO] ? dialogData[dialogActions.UPLOAD_PROFILE_PHOTO] : false}
                    closeDialog={this.closeDialog(dialogActions.UPLOAD_PROFILE_PHOTO)}
                    childRef={ref => this.uploadDialogRef = ref} reload={this.props.reload}/>

            </MuiThemeProvider>
        );
    }
}

Account.propTypes = {
    toggleDialog: PropTypes.func.isRequired,
    getAccountInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    dialogData: state.dialogData.data,
    data: state.accountInfoData.data,
    loading: state.accountInfoData.loading,
});

export default connect(mapStateToProps, {
    toggleDialog,
    getAccountInfo
})(withStyles(styles, {withTheme: true})(Account));