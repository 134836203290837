import {combineReducers} from 'redux';
import authReducer from './auth/authReducer';
import validateTokenReducer from './auth/validateTokenReducer';
import blacklistTokenReducer from './auth/blacklistTokenReducer';
import {reducer as formReducer} from 'redux-form';
import getEventsReducer from "./event/getEventsReducer";
import getResults from "./result/getResults";
import showErrorReducer from "./helpers/showErrorReducer";
import getTeachingSubjectsReducer from "./subject/getTeachingSubjectsReducer";
import getStreamsReducer from "./stream/getStreamsReducer";
import getStreamStudentsReducer from "./student/getStreamStudentsReducer";
import addResultsReducer from "./result/addResultsReducer";
import editResultsReducer from "./result/editResultsReducer";
import markAttendanceReducer from "./attendance/markAttendanceReducer";
import getAttendanceReducer from "./attendance/getAttendanceReducer";
import editAttendanceReducer from "./attendance/editAttendanceReducer";
import getEventReducer from "./event/getEventReducer";
import getOverallResultsReducer from "./result/getOverallResultsReducer";
import issueLeaveReducer from "./leave/issueLeaveReducer";
import getIssuedLeavesReducer from "./leave/getIssuedLeavesReducer";
import editIssuedLeavesReducer from "./leave/editIssuedLeavesReducer";
import authErrorReducer from "./auth/authErrorReducer";
import getExamsReducer from "./exam/getExamsReducer";
import getClassLevelsReducer from "./level/getClassLevelsReducer";
import getTermsReducer from "./term/getTermsReducer";
import getHomeworkReducer from "./homework/getHomeworkReducer";
import addHomeworkReducer from "./homework/addHomeworkReducer";
import getHomeworkQuestionsReducer from "./homework/getHomeworkQuestionsReducer";
import getNoticesReducer from "./notice/getNoticesReducer";
import getNoticeReducer from "./notice/getNoticeReducer";
import getAllEventsReducer from "./event/getAllEventsReducer";
import GetTimetableReducer from "./timetable/GetTimetableReducer";
import SendDeviceTokenReducer from "./fcm/SendDeviceTokenReducer";
import GetChatsReducer from "./chat/GetChatsReducer";
import GetStudentsReducer from "./student/GetStudentsReducer";
import SendMessageReducer from "./chat/SendMessageReducer";
import SendReadMessageReducer from "./chat/SendReadMessageReducer";
import SendReceivedMessageReducer from "./chat/SendReceivedMessageReducer";
import GetPostsReducer from "./forum/GetPostsReducer";
import SendPostsReducer from "./forum/SendPostsReducer";
import GetCommentsReducer from "./forum/GetCommentsReducer";
import SendCommentsReducer from "./forum/SendCommentsReducer";
import GetRepliesReducer from "./forum/GetRepliesReducer";
import SendRepliesReducer from "./forum/SendRepliesReducer";
import exportResultsReducer from "./result/exportResultsReducer";
import importResultsReducer from "./result/importResultsReducer";
import downloadMarkSheetReducer from "./result/downloadMarkSheetReducer";
import GetSchoolsReducer from "./common/GetSchoolsReducer";
import GetCountriesReducer from "./common/GetCountriesReducer";
import DialogReducer from "./common/DialogReducer";
import getBooksReducer from "./library/getBooksReducer";
import getBookCategoriesReducer from "./library/getBookCategoriesReducer";
import booksMetadataReducer from "./library/booksMetadataReducer";
import reserveBookReducer from "./library/reserveBookReducer";
import getReservedBooksReducer from "./library/getReservedBooksReducer";
import reserveBookDialogReducer from "./library/reserveBookDialogReducer";
import uploadPhotoReducer from "./account/uploadPhotoReducer";
import AddPostDialogReducer from "./forum/AddPostDialogReducer";
import UploadPostPhotoReducer from "./forum/UploadPostPhotoReducer";
import GetAllCommentsReducer from "./forum/GetAllCommentsReducer";
import GetAllRepliesReducer from "./forum/GetAllRepliesReducer";
import GetAccountInfoReducer from "./account/GetAccountInfoReducer";


export default combineReducers({
    authData: authReducer,
    validateTokenData: validateTokenReducer,
    blacklistTokenData: blacklistTokenReducer,
    form: formReducer,
    getStreamsData: getStreamsReducer,
    getEventsData: getEventsReducer,
    getResultsData: getResults,
    getTeachingSubjectsData: getTeachingSubjectsReducer,
    showErrorData: showErrorReducer,
    getStreamStudentsData: getStreamStudentsReducer,
    addResultsData: addResultsReducer,
    editResultsData: editResultsReducer,
    markAttendanceData: markAttendanceReducer,
    getAttendanceData: getAttendanceReducer,
    editAttendanceData: editAttendanceReducer,
    getSingleEventData: getEventReducer,
    overallResultsData: getOverallResultsReducer,
    issueLeaveData: issueLeaveReducer,
    issuedLeavesData: getIssuedLeavesReducer,
    editIssuedLeavesData: editIssuedLeavesReducer,
    sessionExpData: authErrorReducer,
    examsData: getExamsReducer,
    levelsData: getClassLevelsReducer,
    termsData: getTermsReducer,
    homeworkData: getHomeworkReducer,
    addHomeworkData: addHomeworkReducer,
    homeworkQuestionsData: getHomeworkQuestionsReducer,
    noticesData: getNoticesReducer,
    noticeData: getNoticeReducer,
    allEventsData: getAllEventsReducer,
    timetableData: GetTimetableReducer,
    sendTokenData: SendDeviceTokenReducer,
    chatsData: GetChatsReducer,
    studentsData: GetStudentsReducer,
    sendMessageData: SendMessageReducer,
    sendReadMessageData: SendReadMessageReducer,
    sendReceivedMessageData: SendReceivedMessageReducer,
    postsData: GetPostsReducer,
    sendPostsData: SendPostsReducer,
    commentsData: GetCommentsReducer,
    sendCommentsData: SendCommentsReducer,
    repliesData: GetRepliesReducer,
    sendRepliesData: SendRepliesReducer,
    exportResultsData: exportResultsReducer,
    importResultsData: importResultsReducer,
    downloadMarkSheetData: downloadMarkSheetReducer,
    schoolsData: GetSchoolsReducer,
    countriesData: GetCountriesReducer,
    dialogData: DialogReducer,
    booksData: getBooksReducer,
    bookCategoriesData: getBookCategoriesReducer,
    booksMetadata: booksMetadataReducer,
    reserveBookData: reserveBookReducer,
    reservedBooksData: getReservedBooksReducer,
    reserveBookDialogData: reserveBookDialogReducer,
    uploadPhotoData: uploadPhotoReducer,
    addPostDialogData: AddPostDialogReducer,
    uploadPostPhotoData: UploadPostPhotoReducer,
    allCommentsData: GetAllCommentsReducer,
    allRepliesData: GetAllRepliesReducer,
    accountInfoData: GetAccountInfoReducer
})
