import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import Paper from "@material-ui/core/Paper";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {format, parse} from "date-fns";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getNotice} from "../../actions/noticeActions";
import {getLocalizedMessage} from "../../helpers";
import {withRouter} from "react-router-dom";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Notice extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('noticeboard');
        this.props.setTitle(getLocalizedMessage('noticeboard'));
        const {match} = this.props;
        this.props.getNotice(parseInt(match.params.id))
    }

    render() {
        const {data, loading} = this.props;

        let displayDate = null;

        if (data.date) {
            const displayFormat = "EEE do MMM, yyyy";

            const date = parse(data.date, "yyyy-MM-dd", new Date());
            displayDate = format(date, displayFormat, {awareOfUnicodeTokens: true});
        }

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className='d-flex justify-content-center overflow-x-hidden'>
                    <div className='pt-0 notice-item-2'>
                        {
                            loading &&
                            <div className='w-100 d-flex justify-content-center pt-5'>
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            </div>
                        }
                        {
                            data.title && !loading &&
                            <Paper className='p-3' elevation={1}>
                                <div className='mt-0 text-dark'>
                                    <strong>{data.title}</strong>
                                </div>
                                <article className='mt-1 text-dark' dangerouslySetInnerHTML={{__html: data.description}}/>
                                <div className='text-dark'>
                                    <span className='font-sm'>{displayDate}</span>
                                </div>
                            </Paper>
                        }

                        {
                            (data.error || this.props.status === 404) && !loading &&
                            <Paper elevation={1}>
                                <div className='p-3'>
                                    {getLocalizedMessage('invalidNotice')}
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Notice.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getNotice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.noticeData.data,
    loading: state.noticeData.loading,
    status: state.noticeData.status
});

export default connect(mapStateToProps, {getNotice})(withStyles(styles, {withTheme: true})(withRouter(Notice)));