import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";
import {DOWNLOAD_MARK_SHEET} from "../constants/actionTypes";
import {axiosFileConfig} from "../constants/constants";
import fileDownload from "js-file-download";

export const getStreamStudents = (level, year, streamId) => dispatch => {
    dispatch(
        loading(actionTypes.GET_STREAM_STUDENTS, {}, 0, true)
    );
    axios.get(`${urls.STUDENT_URL}${level}/${year}/${streamId}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_STREAM_STUDENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_STREAM_STUDENTS, error)
                );
            }
        )
};

export const getStudents = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_STUDENTS, {}, 0, true)
    );
    axios.get(`${urls.STUDENT_URL}teacher`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_STUDENTS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_STUDENTS, error)
                );
            }
        )
};

export const downloadMarkSheet = (level, year, stream) => dispatch => {
    dispatch(
        loading(DOWNLOAD_MARK_SHEET, {}, 0, true)
    );
    axios.get(`${urls.STUDENT_URL}sheet/${level}/${year}/${stream}/${new Date().getTime()}`, axiosFileConfig)
        .then(response => {
            fileDownload(response.data, 'mark_sheet.xlsx');
            dispatch(
                handleSuccessResponse(DOWNLOAD_MARK_SHEET, {}, response.status)
            );
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(DOWNLOAD_MARK_SHEET, error)
                );
            }
        )
};