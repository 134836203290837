import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {getLocalizedMessage, titleCase} from "../../helpers";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {
    booksMetadata,
    getBookCategories,
    getBooks,
    getCategoryBooks,
    searchBooks,
    searchBooksByCategory,
} from "../../actions/libraryActions";
import Pagination from "react-js-pagination";
import {PAGE_LIMIT} from "../../constants/constants";
import ReserveBookDialog from "../dialogs/ReserveBookDialog";
import {LIBRARY_DOC_URL} from "../../constants/request_url";
import * as dialogActions from "../../constants/DialogActions";
import {toggleDialog} from "../../actions/commonActions";
import {RESERVE_BOOK_DIALOG} from "../../constants/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 50
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    }
});

class Library extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            reserveDialogOpen: false,
            bookId: 0,
            bookTitle: '',
            categoriesOpen: false,
            categoryName: getLocalizedMessage('allGenres'),
            query: '',
            categoryId: 0,
            myAnchorEl: null
        };
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleReserveClick = this.handleReserveClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('library');
        this.props.setTitle(getLocalizedMessage('library'));
        // this.props.toolbarMetadata(true, true);
        this.props.booksMetadata(0, 0, '');
        this.props.getBooks(0);
        this.props.getBookCategories();
    }

    componentWillUnmount() {
        // this.props.toolbarMetadata(false, false);
    }

    openDialog = e => {
        this.props.toggleDialog(RESERVE_BOOK_DIALOG, true);
    };

    closeDialog = e => {
        this.props.toggleDialog(RESERVE_BOOK_DIALOG, false);
    };

    handlePageChange = page => {
        const {metadata} = this.props;
        if (metadata.categoryId === 0) {
            if (metadata.searchTerm.trim().length > 2) {
                this.props.booksMetadata(0, page - 1, metadata.searchTerm);
                this.props.searchBooks(metadata.searchTerm, page - 1);
            } else {
                this.props.booksMetadata(0, page - 1, '');
                this.props.getBooks(0);
            }
        } else {
            if (metadata.searchTerm.trim().length > 2) {
                this.props.booksMetadata(metadata.categoryId, page - 1, metadata.searchTerm);
                this.props.searchBooksByCategory(metadata.searchTerm, metadata.categoryId, page - 1);
            } else {
                this.props.booksMetadata(metadata.categoryId, page - 1, '');
                this.props.getCategoryBooks(metadata.categoryId, page - 1);
            }
        }
    };

    handleReserveClick = book => e => {
        this.setState({
            bookTitle: book.title,
            bookId: book.id
        });
        this.props.toggleDialog(RESERVE_BOOK_DIALOG, true);
    };

    handleToggle = () => {
        this.setState(state => ({categoriesOpen: !state.categoriesOpen}));
    };

    handleClose = event => {
        if (this.myAnchorEl.contains(event.target)) {
            return;
        }

        this.setState({categoriesOpen: false});
    };

    handleMenuClick = category => event => {
        if (this.myAnchorEl.contains(event.target)) {
            return;
        }

        const {query} = this.state;
        this.setState({categoriesOpen: false, categoryName: category.name, categoryId: category.id});

        if (category.id === 0) {
            if (query.trim().length > 2) {
                this.props.booksMetadata(0, 0, query);
                this.props.searchBooks(query, 0);
            } else {
                this.props.booksMetadata(0, 0, '');
                this.props.getBooks(0);
            }
        } else {
            if (query.trim().length > 2) {
                this.props.booksMetadata(category.id, 0, query);
                this.props.searchBooksByCategory(query, category.id, 0);
            } else {
                this.props.booksMetadata(category.id, 0, '');
                this.props.getCategoryBooks(category.id, 0);
            }
        }
    };

    handleChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    handleSearch = event => {
        const {query, categoryId} = this.state;
        if (query.trim().length > 2) {
            if (categoryId === 0) {
                this.props.booksMetadata(0, 0, query);
                this.props.searchBooks(query, 0);
            } else {
                this.props.booksMetadata(categoryId, 0, query);
                this.props.searchBooksByCategory(query, categoryId, 0);
            }
        }
    };

    onKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        const {message} = this.state;
        if (code === 13) {
            event.preventDefault();
            const {query, categoryId} = this.state;
            if (query.trim().length > 2) {
                if (categoryId === 0) {
                    this.props.booksMetadata(0, 0, query);
                    this.props.searchBooks(query, 0);
                } else {
                    this.props.booksMetadata(categoryId, 0, query);
                    this.props.searchBooksByCategory(query, categoryId, 0);
                }
            }
        }
    };

    render() {
        const {data, loading, classes, metadata, dialogData, query, bookCategoriesData} = this.props;
        const {bookTitle, bookId, categoriesOpen, categoryName} = this.state;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="d-flex justify-content-center mt-2 mt-lg-0">
                    <div className='notice-item-2'>
                        <div className=''>

                            <div className="row m-0 bg-white">
                                <div className="col-lg-7 col-12 pl-0 pr-0 pr-lg-3">
                                    <Paper className={`${classes.root} mb-4`} elevation={1}>
                                        <InputBase className={classes.input}
                                                   placeholder={getLocalizedMessage('searchBooks')}
                                                   value={query}
                                                   name='query'
                                                   onChange={this.handleChange}
                                                   onKeyPress={this.onKeyPress}/>
                                        <IconButton className={classes.iconButton}
                                                    aria-label={getLocalizedMessage('search')}
                                                    onClick={this.handleSearch}>
                                            <SearchIcon/>
                                        </IconButton>
                                    </Paper>
                                </div>

                                <div className="col-lg-5 col-12 pl-0 pr-0">
                                    <Paper className={`${classes.root} mb-4`} elevation={1}>
                                        <Button
                                            buttonRef={node => {
                                                this.myAnchorEl = node;
                                            }}
                                            aria-owns={categoriesOpen ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleToggle}
                                        >
                                            {categoryName}
                                            {
                                                categoriesOpen &&
                                                <KeyboardArrowUpIcon/>
                                            }
                                            {
                                                !categoriesOpen &&
                                                <KeyboardArrowDownIcon/>
                                            }
                                        </Button>
                                    </Paper>
                                </div>
                            </div>

                            <div className='books-container'>
                                {
                                    loading &&
                                    <div className='w-100 d-flex justify-content-center pt-1 mt-2'>
                                        <CircularProgress color='primary' size={50} thickness={5}/>
                                    </div>
                                }
                                {
                                    data.books && data.books.length > 0 && !loading &&
                                    <div>
                                        {
                                            data.books.map((item, index) => {
                                                return (
                                                    <Paper key={index} className='mb-3'>
                                                        <div className="p-3 text-dark">
                                                            <div className='mt-2'>
                                                                <strong>{item.title}</strong>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>{getLocalizedMessage('genre')}</strong>: <span>{titleCase(item.categoryName)}</span>
                                                            </div>
                                                            {
                                                                item.author &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('author')}</strong>: <span>{titleCase(item.author)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.publisher &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('publisher')}</strong>: <span>{titleCase(item.publisher)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.isbn &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('isbn')}</strong>: <span>{titleCase(item.isbn)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.edition &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('edition')}</strong>: <span>{titleCase(item.edition)}</span>
                                                                </div>
                                                            }
                                                            <div className='mt-2'>
                                                                <strong>{getLocalizedMessage('materialType')}</strong>:
                                                                {
                                                                    item.isEbook === true &&
                                                                    <span
                                                                        className='text-info ml-2'>{getLocalizedMessage('eBook')}</span>
                                                                }
                                                                {
                                                                    item.isEbook === false &&
                                                                    <span
                                                                        className='text-primary ml-2'>{getLocalizedMessage('hardCopy')}</span>
                                                                }
                                                            </div>
                                                            {
                                                                item.isEbook === false &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('availability')}</strong>:
                                                                    {
                                                                        item.available === true &&
                                                                        <span
                                                                            className='text-success ml-2'>{getLocalizedMessage('available')}</span>
                                                                    }
                                                                    {
                                                                        item.available === false &&
                                                                        <span
                                                                            className='text-warning ml-2'>{getLocalizedMessage('unAvailable')}</span>
                                                                    }
                                                                </div>
                                                            }
                                                            <div className='mt-2'>
                                                                {item.description}
                                                            </div>
                                                            {
                                                                item.available === true && item.isEbook === false &&
                                                                <div className='mt-2 d-inline-block'>
                                                                    <div className={`text-primary cursor-pointer`}
                                                                         onClick={this.handleReserveClick(item)}>
                                                                        {getLocalizedMessage('reserveBook')}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                item.isEbook === true &&
                                                                <div className='mt-2 d-inline-block'>
                                                                    <a className='text-primary'
                                                                       href={`${LIBRARY_DOC_URL}/${item.documentUri}`}
                                                                       target='_blank'>
                                                                        <i className='fa fa-download'/>
                                                                        <span
                                                                            className='ml-2'>{getLocalizedMessage('download')}</span>
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    data.books && data.books.length === 0 && !loading &&
                                    <Paper>
                                        <div className='p-3'>
                                            {getLocalizedMessage('noBooks')}
                                        </div>
                                    </Paper>
                                }

                                {
                                    data.books && data.books.length > 0 &&
                                    <Pagination
                                        activePage={metadata.offset + 1}
                                        itemsCountPerPage={PAGE_LIMIT}
                                        totalItemsCount={data.total}
                                        pageRangeDisplayed={10}
                                        onChange={this.handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        prevPageText='Prev'
                                        nextPageText='Next'
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </div>

                {
                    bookCategoriesData.categories &&
                    <Popper open={categoriesOpen} anchorEl={this.myAnchorEl} transition disablePortal>
                        {({TransitionProps, placement}) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList>
                                            <MenuItem onClick={this.handleMenuClick({
                                                id: 0,
                                                name: getLocalizedMessage('allGenres')
                                            })}>
                                                {getLocalizedMessage('allGenres')}
                                            </MenuItem>
                                            {
                                                bookCategoriesData.categories.map((item, index) => (
                                                    <MenuItem key={index}
                                                              onClick={this.handleMenuClick(item)}>{titleCase(item.name)}</MenuItem>
                                                ))
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                }

                <ReserveBookDialog
                    open={dialogData[dialogActions.RESERVE_BOOK_DIALOG] ? dialogData[dialogActions.RESERVE_BOOK_DIALOG] : false}
                    handleClose={this.closeDialog}
                    bookId={bookId} bookTitle={bookTitle}/>
            </MuiThemeProvider>
        );
    }
}

Library.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getBooks: PropTypes.func.isRequired,
    getCategoryBooks: PropTypes.func.isRequired,
    booksMetadata: PropTypes.func.isRequired,
    getBookCategories: PropTypes.func.isRequired,
    searchBooks: PropTypes.func.isRequired,
    searchBooksByCategory: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.booksData.data,
    loading: state.booksData.loading,
    metadata: state.booksMetadata.data,
    dialogData: state.dialogData.data,
    bookCategoriesData: state.bookCategoriesData.data,
});

export default connect(mapStateToProps, {
    getBooks,
    getCategoryBooks,
    booksMetadata,
    getBookCategories,
    searchBooks,
    searchBooksByCategory,
    toggleDialog
})(withStyles(styles, {withTheme: true})(Library));