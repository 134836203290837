import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {uploadPhoto} from "../../actions/accountActions";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import CropIcon from '@material-ui/icons/Crop';
import RefreshIcon from '@material-ui/icons/Refresh';
import {getLocalizedMessage, toastError, verifyExtension} from "../../helpers";
import {allowedImageExtensions} from "../../constants/constants";
import Button from "@material-ui/core/Button";
import CheckIcon from '@material-ui/icons/Check';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import MuiDialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions/DialogActions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withMobileDialog} from "@material-ui/core";

const imageStyle = {
    display: 'block',
    width: '150px',
    height: 'auto'
};

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit,
    },
}))(MuiDialogActions);

class UploadParentPhotoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            croppedImage: null,
            croppedImgUrl: null,
            cropComplete: false
        };
        this.inputRef = React.createRef();

        this.handleImageChange = this.handleImageChange.bind(this);
        this.crop = this.crop.bind(this);
        this._crop = this._crop.bind(this);
        this.resetCrop = this.resetCrop.bind(this);
        this.cropperRef = React.createRef();
        this.handleImgSubmit = this.handleImgSubmit.bind(this);
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    clearFields = () => {
        if (this.inputRef.current) {
            this.inputRef.current.value = null;
        }
        this.setState({
            file: '',
            imagePreviewUrl: '',
            croppedImage: null,
            croppedImgUrl: null,
            cropComplete: false
        });
    };

    _crop() {

        this.cropperRef.current.getCroppedCanvas().toBlob(blob => {
            const imgFile = new File([blob], this.state.file.name);
            const url = URL.createObjectURL(imgFile);
            this.setState({
                croppedImage: imgFile,
                croppedImgUrl: url
            })
        }, 'image/jpeg', 1)
    }

    completeCrop = () => {
        this.setState({
            cropComplete: true
        })
    };

    resetCrop = () => {
        this.cropperRef.current.reset();
    };

    crop = () => {
        this.setState({
            cropComplete: false
        })
    };

    handleImageChange(e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        const fileName = file.name;
        if (verifyExtension(fileName, allowedImageExtensions) === false) {
            toastError(getLocalizedMessage('fileNotImage'));
            this.setState({
                file: '',
                imagePreviewUrl: '',
                croppedImgUrl: null
            });
        } else {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    croppedImgUrl: null,
                    cropComplete: false
                });
            };

            reader.readAsDataURL(file)
        }
    }

    handleImgSubmit = (e) => {
        e.preventDefault();
        const {croppedImage} = this.state;
        if (croppedImage !== '') {
            const data = new FormData();
            data.append('image', croppedImage);
            this.props.uploadPhoto(data, this.props.reload);
        } else {
            toastError(getLocalizedMessage('imageEmpty'));
            this.setState({
                file: '',
                imagePreviewUrl: ''
            })
        }
    };

    render() {
        const {loading, open, fullScreen} = this.props;
        const {imagePreviewUrl, croppedImgUrl, cropComplete, croppedImage} = this.state;

        let $croppedImgPreview = null;
        if (croppedImgUrl) {
            $croppedImgPreview = (<img src={croppedImgUrl} style={imageStyle}/>);
        }

        return (
            <Dialog
                open={open}
                onClose={this.props.closeDialog}
                TransitionComponent={Transition}
                fullWidth
                maxWidth='xs'
                fullScreen={fullScreen}
            >
                <DialogTitle onClose={this.props.closeDialog}>
                    {getLocalizedMessage('uploadProfileImage')}
                </DialogTitle>
                <DialogContent>
                    <div className="p-3">

                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="raised-button-file"
                            type="file"
                            onChange={this.handleImageChange}
                            ref={this.inputRef}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span">
                                {getLocalizedMessage('chooseImage')}
                            </Button>
                        </label>
                        {
                            imagePreviewUrl && !cropComplete &&
                            <div>
                                <Cropper
                                    ref={this.cropperRef}
                                    src={imagePreviewUrl}
                                    style={{height: 300, width: 'auto'}}
                                    aspectRatio={1}
                                    guides={false}
                                    crop={this._crop}
                                />
                                <div className='mt-3 d-flex justify-content-between'>
                                    <Button variant="contained"
                                            component="span"
                                            onClick={this.resetCrop}
                                            color='primary'
                                    >
                                        <RefreshIcon/>
                                        <span className="ml-3">{getLocalizedMessage('reset')}</span>
                                    </Button>
                                    <Button variant="contained"
                                            component="span"
                                            onClick={this.completeCrop}
                                            color='primary'
                                    >
                                        <CheckIcon/>
                                        <span className="ml-3">{getLocalizedMessage('done')}</span>
                                    </Button>
                                </div>
                            </div>
                        }

                        {
                            croppedImgUrl && cropComplete &&
                            <div>
                                {$croppedImgPreview}
                                <div className='mt-3'>
                                    <Button variant="contained"
                                            component="span"
                                            onClick={this.crop}
                                            color='primary'
                                    >
                                        <CropIcon/>
                                        <span className="ml-3">{getLocalizedMessage('crop')}</span>
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                    {
                        croppedImage && cropComplete &&
                        <DialogActions>
                            <Button onClick={this.handleImgSubmit} color="primary" disabled={loading}>
                                {
                                    !loading &&
                                    <span>{getLocalizedMessage('uploadImage')}</span>
                                }
                                {
                                    loading &&
                                    <span>
                                            <span className='mr-2'>{getLocalizedMessage('uploading')}</span>
                                        <CircularProgress color='primary' size={30} thickness={5}/>
                                        </span>
                                }
                            </Button>
                        </DialogActions>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

UploadParentPhotoDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    uploadPhoto: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.uploadPhotoData.data,
    status: state.uploadPhotoData.status,
    loading: state.uploadPhotoData.loading,
});

export default connect(mapStateToProps, {
    uploadPhoto
})(withMobileDialog()(withStyles(styles, {withTheme: true})(UploadParentPhotoDialog)));