export const LOGIN_URL = '/teacher/auth/login';
export const RESULTS_URL = '/result/';
export const VALIDATE_TOKEN_URL = '/teacher/auth/validate';
export const REQUEST_BASE_URL = process.env.BASE_URL;
export const FILE_BASE_URL = process.env.FILE_BASE_URL;
export const PROFILE_IMG_URL = `${FILE_BASE_URL}/teacher_images`;
export const PARENT_IMG_URL = `${FILE_BASE_URL}/parent_images`;
export const SCHOOL_LOGO_URL = `${FILE_BASE_URL}/school_logos`;
export const COUNTRY_LOGO_URL = `${FILE_BASE_URL}/country_flags`;
export const HOMEWORK_IMG_URL = `${FILE_BASE_URL}/homework_images`;
export const FORUM_IMG_URL = `${FILE_BASE_URL}/forum`;
export const LOGOUT_URL = '/teacher/auth/logout';
export const DOMAIN_NAME = 'localhost';
export const STUDENT_URL = '/student/';
export const TEACHER_URL = '/teacher/';
export const VALIDATE_TEACHER_ID_NO_URL = '/teacher/validate/id/';
export const EVENT_URL = '/event/';
export const STREAM_URL = '/stream/';
export const EVENT_IMG_URL = `${FILE_BASE_URL}/event_images`;
export const SUBJECT_URL = '/subject/';
export const ATTENDANCE_URL = '/attendance/';
export const LEAVE_URL = '/leave/';
export const LEVEL_URL = '/level/teacher';
export const TERM_URL = '/term/teacher';
export const SCHOOL_URL = '/school/country';
export const COUNTRY_URL = '/country/active';
export const EXAM_URL = '/exam/teacher';
export const HOMEWORK_URL = '/homework/';
export const NOTICE_URL = '/notice/';
export const TIMETABLE_URL = '/timetable/teacher';
export const CHAT_URL = '/chat/teacher';
export const SOCKET_BASE_URL = process.env.SOCKET_BASE_URL;
export const FORUM_URL = '/forum';
export const LIBRARY_URL = '/library/teacher';
export const LIBRARY_DOC_URL = `${FILE_BASE_URL}/library`;
