import React, {Component} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import withStyles from "@material-ui/core/styles/withStyles";
import {Route, Switch} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {mainTheme} from "../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Events from "./pages/Events";
import Event from "./pages/Event";
import Result from "./pages/Results";
import Attendance from "./pages/Attendance";
import Notices from "./pages/Notices";
import Notice from "./pages/Notice";
import Assignments from "./pages/Assignments";
import Forum from "./pages/Forum";
import Subjects from "./pages/Subjects";
import Timetable from "./pages/Timetable";
import PageNotFound from "./errors/PageNotFound";
import OverallResults from "./pages/OverallResults";
import IssuedLeaves from "./pages/IssuedLeaves";
import Chat from "./pages/Chat";
import MyAppBar from "./menu/MyAppBar";
import Library from "./pages/Library";
import ReservedBooks from "./pages/ReservedBooks";
import Account from "./pages/Account";
import {hasModule} from "../helpers";
import {
    MODULE_ATTENDANCE,
    MODULE_COMMUNICATION,
    MODULE_EXAM,
    MODULE_HOMEWORK,
    MODULE_LEAVES, MODULE_LIBRARY, MODULE_TIMETABLE
} from "../constants/constants";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        maxWidth: 'calc(100% - 300px)',
        background: '#FFF',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        }
    }
});

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ''
        };
        this.setTitle = this.setTitle.bind(this);
        this.reload = this.reload.bind(this);
    }

    setTitle = title => {
        this.setState({
            title: title
        })
    };

    reload = () => {
        this.forceUpdate();
        this.setState({title: `${this.state.title} `});
    };

    render() {
        const {classes, modules} = this.props;
        const {title} = this.state;

        return (

            <MuiThemeProvider theme={mainTheme}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <MyAppBar title={title} modules={modules}/>

                    <main className={classes.content}>
                        <div className={classes.toolbar}/>
                        <div className='main-content-container'>
                            <Switch>
                                <Route path="/" render={() => <Events setTitle={this.setTitle}/>} exact strict/>
                                {
                                    hasModule(MODULE_EXAM, modules) &&
                                    <Route path="/result" render={() => <Result setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_ATTENDANCE, modules) &&
                                    <Route path="/attendance" render={() => <Attendance setTitle={this.setTitle}/>}
                                           exact
                                           strict/>
                                }
                                <Route path="/events/:id" render={() => <Event setTitle={this.setTitle}/>} exact strict/>
                                <Route path="/events/search/:query" render={() => <Events setTitle={this.setTitle}/>} exact strict/>
                                <Route path="/subjects" render={() => <Subjects setTitle={this.setTitle}/>} exact
                                       strict/>
                                {
                                    hasModule(MODULE_EXAM, modules) &&
                                    <Route path="/overall" render={() => <OverallResults setTitle={this.setTitle}/>}
                                           exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_LEAVES, modules) &&
                                    <Route path="/leave" render={() => <IssuedLeaves setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_HOMEWORK, modules) &&
                                    <Route path="/homework" render={() => <Assignments setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_COMMUNICATION, modules) &&
                                    <Route path="/notice" render={() => <Notices setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_COMMUNICATION, modules) &&
                                    <Route path="/notice/:id" render={() => <Notice setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_TIMETABLE, modules) &&
                                    <Route path="/timetable" render={() => <Timetable setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_LIBRARY, modules) &&
                                    <Route path="/library" render={() => <Library setTitle={this.setTitle}/>} exact
                                           strict/>
                                }
                                {
                                    hasModule(MODULE_LIBRARY, modules) &&
                                    <Route path="/library/history"
                                           render={() => <ReservedBooks setTitle={this.setTitle}/>} exact strict/>
                                }
                                {/*<Route path="/chat" render={() => <Chat setTitle={this.setTitle}/>} exact strict/>*/}
                                {
                                    hasModule(MODULE_COMMUNICATION, modules) &&
                                    <Route path="/forum" render={() => <Forum setTitle={this.setTitle}/>} exact strict/>
                                }

                                <Route path="/account" render={() => <Account setTitle={this.setTitle} reload={this.reload}/>} exact strict/>
                                <Route path="/*" render={() => <PageNotFound setTitle={this.setTitle}/>} exact
                                       strict/>
                            </Switch>
                        </div>
                    </main>
                </div>
            </MuiThemeProvider>
        );
    }
}

Main.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(withStyles(styles, {withTheme: true})(Main));