import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {createDate, getLocalizedMessage, titleCase} from "../../helpers";
import {format} from "date-fns";
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {getReservedBooks} from "../../actions/libraryActions";

const styles = theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '70%',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    }
});

class ReservedBooks extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderStatus = this.renderStatus.bind(this);
        this.renderDate = this.renderDate.bind(this);
        this.renderDueDate = this.renderDueDate.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('libraryHistory');
        this.props.setTitle(getLocalizedMessage('libraryHistory'));
        this.props.getReservedBooks();
    }

    renderDate = item => {
        if (item.date) {
            const date = createDate(item.date);
            const displayDate = format(date, "YYYY-MM-dd HH:mm", {awareOfUnicodeTokens: true});
            return (
                <span>{displayDate}</span>
            );
        } else {
            return (<span/>)
        }
    };

    renderDueDate = item => {
        if (item.dueDate) {
            const date = createDate(item.dueDate);
            const displayDate = format(date, "YYYY-MM-dd HH:mm", {awareOfUnicodeTokens: true});
            return (
                <span>{displayDate}</span>
            );
        } else {
            return (<span/>)
        }
    };

    renderStatus = item => {
        if (item.status === getLocalizedMessage('inShelf') || item.status === getLocalizedMessage('returned') || item.status === getLocalizedMessage('replaced')) {
            return (<span className='text-success'>{item.status}</span>)
        } else if (item.status === getLocalizedMessage('reserved')) {
            return (<span className='text-warning'>{item.status}</span>)
        } else if (item.status === getLocalizedMessage('issued') || item.status === getLocalizedMessage('lost')) {
            return (<span className='text-danger'>{item.status}</span>)
        }

        return (<span/>)

    };

    render() {
        const {data, loading} = this.props;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="d-flex justify-content-center">
                    <div className='notice-item'>
                        <div className=''>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-1 mt-2'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }

                            <div className='books-container mt-2 mt-lg-0'>

                                {
                                    data.books && data.books.length > 0 && !loading &&
                                    <div>
                                        {
                                            data.books.map((item, index) => {
                                                return (
                                                    <Paper key={index} className='mb-3'>
                                                        <div className="p-3 text-dark">
                                                            <div className='mt-2'>
                                                                <strong>{item.title}</strong>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>{getLocalizedMessage('genre')}</strong>: <span>{titleCase(item.categoryName)}</span>
                                                            </div>
                                                            {
                                                                item.author &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('author')}</strong>: <span>{titleCase(item.author)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.publisher &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('publisher')}</strong>: <span>{titleCase(item.publisher)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.isbn &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('isbn')}</strong>: <span>{titleCase(item.isbn)}</span>
                                                                </div>
                                                            }
                                                            {
                                                                item.edition &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('edition')}</strong>: <span>{titleCase(item.edition)}</span>
                                                                </div>
                                                            }
                                                            <div className='mt-2'>
                                                                <strong>{getLocalizedMessage('status')}</strong>: {this.renderStatus(item)}
                                                            </div>
                                                            <div className='mt-2'>
                                                                <strong>{getLocalizedMessage('date')}</strong>: {this.renderDate(item)}
                                                            </div>
                                                            {
                                                                item.dueDate &&
                                                                <div className='mt-2'>
                                                                    <strong>{getLocalizedMessage('dueDate')}</strong>: {this.renderDueDate(item)}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )
                                            })
                                        }
                                    </div>
                                }

                                {
                                    data.books && data.books.length === 0 && !loading &&
                                    <Paper>
                                        <div className='p-3'>
                                            {getLocalizedMessage('noLibraryHistory')}
                                        </div>
                                    </Paper>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

ReservedBooks.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getReservedBooks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.reservedBooksData.data,
    loading: state.reservedBooksData.loading
});

export default connect(mapStateToProps, {
    getReservedBooks
})(withStyles(styles, {withTheme: true})(ReservedBooks));