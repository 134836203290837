import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import logo from '../../images/app_logo.png';
import userImg from '../../images/placeholders/user.jpg';
import {Divider, Paper, Popover} from "@material-ui/core";
import {withRouter} from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import {connect} from "react-redux";
import {blacklistToken} from "../../actions/authActions";
import {logout} from "../../common";
import {mainTheme} from "../../themes/Themes";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Menu from "./Menu";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {
    currentLanguage,
    currentLanguageName,
    currentSchoolLogo,
    currentSchoolName,
    getLanguages, getLocalizedMessage, profileImageUri, setLanguage,
    titleCase, userFullname
} from "../../helpers";
import {PROFILE_IMG_URL, SCHOOL_LOGO_URL} from "../../constants/request_url";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const drawerWidth = 300;
const appBarHeight = 65;

const styles = theme => ({
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: appBarHeight
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    title: {
        marginLeft: 10,
        color: '#FFF',
        fontWeight: 'bold'
    },
    paper: {
        maxWidth: 400,
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'calc(100% - 20px)',
            marginTop: '25px'
        }
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            marginTop: 65,
        },
        [theme.breakpoints.down('md')]: {
            height: '100vh',
            minHeight: '100vh'
        },
        height: 'calc(100vh - 65px)',
    }
});

class MyAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            anchorEl: null,
            storedAnchorEl: null,
            arrowRef: null,
            popAccountMenu: false,
            myAnchorEl: null,
            languageOpen: false,
            language: currentLanguage(),
            languageName: currentLanguageName()
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.handleDrawerClose = this.handleDrawerClose.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
        this.togglePopover = this.togglePopover.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.goToProfile = this.goToProfile.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleDrawerToggle = () => {
        this.setState(state => ({
            mobileOpen: !state.mobileOpen
        }))
    };

    handleDrawerClose = () => {
        this.setState({
            mobileOpen: false
        })
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handlePopoverOpen = e => {
        this.setState({
            anchorEl: e.currentTarget,
            storedAnchorEl: e.currentTarget
        })
    };

    togglePopover = e => {
        // e.persist();
        this.setState(state => {
            if (state.anchorEl) {
                return ({
                    anchorEl: null
                })
            } else {
                return ({
                    anchorEl: state.storedAnchorEl
                })
            }
        })
    };

    goToProfile = () => {
        this.handlePopoverClose();
        this.props.history.push('/account');
    };

    handleLogout(event) {
        this.props.blacklistToken();
    }

    handleToggle = () => {
        this.setState(state => ({languageOpen: !state.languageOpen}));
    };

    handleClose = event => {
        if (this.myAnchorEl.contains(event.target)) {
            return;
        }

        this.setState({languageOpen: false});
    };

    handleMenuClick = language => event => {
        if (this.myAnchorEl.contains(event.target)) {
            return;
        }

        if (language.code !== currentLanguage()) {
            setLanguage(language.code);
            this.setState({languageOpen: false, languageName: language.name});
            window.location.reload();
        } else {
            this.setState({languageOpen: false});
        }
    };

    handleChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    render() {
        const {classes, theme, container, title, logoutLoading, modules} = this.props;
        const {mobileOpen, anchorEl, languageOpen, languageName} = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        if (this.props.logoutData.error === false) {
            logout();
        }

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div>
                    <AppBar position="fixed" className={classes.appBar} elevation={2}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={this.handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <div className="toolbar-logo-container">
                                <div
                                    className='text-white d-flex align-items-center p-2'
                                >
                                    <img src={currentSchoolLogo()}
                                         alt="logo" className="toolbar-account-img"/>
                                    <Typography variant='body1'
                                                className="text-white text-capitalize ml-2 font-lor">
                                        {currentSchoolName()}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant="body1" noWrap className={classes.title}>{title}</Typography>
                            <div className='ml-auto d-flex'>
                                <Button
                                    buttonRef={node => {
                                        this.myAnchorEl = node;
                                    }}
                                    aria-owns={languageOpen ? 'menu-list-grow' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleToggle}
                                    className='text-white ml-auto'
                                >
                                    {languageName}
                                    {
                                        languageOpen &&
                                        <KeyboardArrowUpIcon/>
                                    }
                                    {
                                        !languageOpen &&
                                        <KeyboardArrowDownIcon/>
                                    }
                                </Button>
                                <div className='d-flex align-items-center toolbar-img-container'
                                     onClick={this.handlePopoverOpen}
                                     aria-describedby={id}>
                                    <img src={profileImageUri() ? profileImageUri() : userImg} alt=""
                                         className='toolbar-img'/>
                                </div>
                            </div>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Paper className='mt-2 account-paper' elevation={0}>
                                    <div className='p-2 d-flex link' onClick={this.goToProfile}>
                                        <Typography variant="body1" noWrap
                                                    className='text-capitalize font-weight-bold ml-2 toolbar-username'>
                                            {userFullname()}
                                        </Typography>
                                    </div>
                                    <Divider/>
                                    <div className='p-2 d-flex link' onClick={this.goToProfile}>
                                        <i className='fa fa-gear nav-icon'/>
                                        <Typography className='ml-2'>{getLocalizedMessage('profile')}</Typography>
                                    </div>
                                    <Divider/>
                                    {
                                        logoutLoading &&
                                        <LinearProgress variant="query"/>
                                    }
                                    <div className='p-2 d-flex link' onClick={this.handleLogout}>
                                        <i className='fa fa-sign-out nav-icon'/>
                                        <Typography className='ml-2'>{getLocalizedMessage('logout')}</Typography>
                                    </div>
                                </Paper>
                            </Popover>

                            <Popper open={languageOpen} anchorEl={this.myAnchorEl} transition disablePortal>
                                {({TransitionProps, placement}) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                <MenuList>
                                                    {
                                                        getLanguages().map((item, index) => (
                                                            <MenuItem key={index}
                                                                      onClick={this.handleMenuClick(item)}>{titleCase(item.name)}</MenuItem>
                                                        ))
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden mdUp implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true
                                }}
                            >
                                {<Menu handleDrawerClose={this.handleDrawerToggle} modules={modules}/>}
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {<Menu handleDrawerClose={this.handleDrawerClose} modules={modules}/>}
                            </Drawer>
                        </Hidden>
                    </nav>
                </div>
            </MuiThemeProvider>
        );
    }
}

MyAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
    title: PropTypes.string.isRequired,
    blacklistToken: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    logoutData: state.blacklistTokenData.data,
    logoutLoading: state.blacklistTokenData.loading
});

export default connect(mapStateToProps, {
    blacklistToken
})(withRouter(withStyles(styles, {withTheme: true})(MyAppBar)));