import {GET_ACCOUNT_INFO, UPLOAD_PROFILE_PHOTO} from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {loading} from "./helperActions";
import {axiosConfig} from "../constants/constants";
import {toggleDialog} from "./commonActions";

export const uploadPhoto = (data, onSuccessAction) => dispatch => {
    dispatch(
        loading(UPLOAD_PROFILE_PHOTO, {}, 0, true)
    );
    axios.put(`${urls.TEACHER_URL}image`, data, axiosConfig)
        .then(response => {
            localStorage.setItem('teacherImageUri', response.data.imageUri);
            dispatch(
                handleSuccessResponse(UPLOAD_PROFILE_PHOTO, response.data, response.status, true)
            );
            dispatch(toggleDialog(UPLOAD_PROFILE_PHOTO, false));
            onSuccessAction();
            dispatch(getAccountInfo());
        })
        .catch(error => {
            dispatch(
                    handleErrorResponse(UPLOAD_PROFILE_PHOTO, error, true)
                );
            }
        )
};

export const getAccountInfo = () => dispatch => {
    dispatch(
        loading(GET_ACCOUNT_INFO, {}, 0, true)
    );
    axios.get(`${urls.TEACHER_URL}info`)
        .then(response => {
            dispatch(
                handleSuccessResponse(GET_ACCOUNT_INFO, response.data, response.status)
            )
        })
        .catch(error => dispatch(
            handleErrorResponse(GET_ACCOUNT_INFO, error)
            )
        )
};
