import * as actionTypes from "../constants/actionTypes";

export const showError = (error, message) => dispatch => {
    if (error) {
        dispatch(
            {
                type: actionTypes.SHOW_ERROR,
                data: {
                    error: true,
                    message: message
                }
            }
        );
        setTimeout(function () {
            dispatch(
                {
                    type: actionTypes.SHOW_ERROR,
                    data: {
                        error: false,
                        message: null
                    }
                }
            );
        }, 4000)
    } else if (error === false) {
        dispatch(
            {
                type: actionTypes.SHOW_ERROR,
                data: {
                    error: false,
                    message: null
                }
            }
        )
    }
};

const loadingData = (actionType, data, status, loading) => {
    return {
        type: actionType,
        data: data,
        status: status,
        loading: loading
    }
};

export const loading = (actionType, data, status, loading) =>  {
    return {
        type: actionType,
        data: data,
        status: status,
        loading: loading
    }
};

export const dialogData = (actionType, data) =>  {
    return {
        type: actionType,
        data: data
    }
};

export const loadingMore = (actionType, data, status, loading, loadingIdNo = null) =>  {
    return {
        type: actionType,
        data: data,
        status: status,
        loading: false,
        loadingMore: loading,
        loadingIdNo: loadingIdNo
    }
};

