import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleErrorResponse, handleSuccessResponse, resetError, resetSuccess} from "../helpers";
import {axiosConfig} from "../constants/constants";
import {loading} from "./helperActions";
import {toggleDialog} from "./commonActions";
import {MARK_ATTENDANCE} from "../constants/DialogActions";

export const markAttendance = (data) => dispatch => {
    dispatch(
        loading(actionTypes.MARK_ATTENDANCE, {}, 0, true)
    );
    axios.post(urls.ATTENDANCE_URL, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.MARK_ATTENDANCE, response.data, response.status, true)
            );
            dispatch(toggleDialog(MARK_ATTENDANCE, false));
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.MARK_ATTENDANCE, error, true)
                );
            }
        )
};

export const editAttendance = (data) => dispatch => {
    dispatch(
        loading(actionTypes.EDIT_ATTENDANCE, {}, 0, true)
    );
    axios.put(urls.ATTENDANCE_URL, data, axiosConfig)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.EDIT_ATTENDANCE, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.EDIT_ATTENDANCE, error, true)
                );
            }
        )
};

export const getAttendance = (level, year, stream, date) => dispatch => {
    dispatch(
        loading(actionTypes.GET_ATTENDANCE, {}, 0, true)
    );
    axios.get(`${urls.ATTENDANCE_URL}${level}/${year}/${stream}/${date}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_ATTENDANCE, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_ATTENDANCE, error)
                );
            }
        )
};