import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import {RenderPasswordField, RenderTextField} from "./inputs";
import Grid from "@material-ui/core/Grid";
import BookIcon from "@material-ui/icons/Book";
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import LockRoundedIcon from '@material-ui/icons/LockOutlined';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MuiThemeProvider} from "@material-ui/core";
import {mainTheme} from "../../themes/Themes";
import {getLocalizedMessage} from "../../helpers";

const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
});


const StyledCircularProgress = withStyles({
    root: {
        color: '#FFF',
        marginLeft: 10
    }
})(CircularProgress);

const StyledFormControlLabel = withStyles({
    root: {},
    label: {
        textTransform: 'capitalize',
        color: "#FFF",
        fontSize: 18,
        fontFamily: "'Open Sans', 'sans-serif'"
    },
})(FormControlLabel);


const validate = values => {
    const errors = {};
    const requiredFields = ['username', 'password'];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = getLocalizedMessage("required")
        }
    });

    return errors
};

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    }

    handleClickShowPassword() {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    render() {
        const { handleSubmit, loading} = this.props;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <form onSubmit={handleSubmit}>
                    <div>
                        <div>
                            <Grid container alignItems="center" className="w-100">
                                <Grid item className="icon-container">
                                    <AccountCircleIcon/>
                                </Grid>
                                <Grid item className="input-container">
                                    <Field name="username"
                                           component={RenderTextField}
                                           label={getLocalizedMessage("email")}
                                           placeholder={getLocalizedMessage("email")}
                                           fullWidth
                                           margin="normal"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <Grid container alignItems="center" className="w-100">
                                <Grid item className="icon-container">
                                    <LockRoundedIcon/>
                                </Grid>
                                <Grid item className="input-container">
                                    <Field name="password"
                                           component={RenderPasswordField}
                                           label={getLocalizedMessage("password")}
                                           placeholder={getLocalizedMessage("password")}
                                           fullWidth
                                           margin="normal"
                                           type='password'
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div>
                            <Button variant="contained" color="primary" fullWidth type="submit"
                                    style={{
                                        marginTop: 35,
                                        marginBottom: 5,
                                        fontSize: 16,
                                    }}
                                    disabled={!!this.props.loading}>
                                {loading ? getLocalizedMessage('pleaseWait') : getLocalizedMessage('continue')}
                                {loading ? <CircularProgress size={30} thickness={5} className='ml-2'/> :
                                    <i className="fa fa-sign-in fa-3x" style={{fontSize: 20, marginLeft: 10}}/>

                                }

                            </Button>
                        </div>

                        <div className="form-group d-flex align-items-center w-100 justify-content-between mt-3">
                            <div className="font-open-sans">
                                <a href="" className="forgot-pwd">{getLocalizedMessage('forgotPassword')}</a>
                            </div>
                        </div>
                    </div>
                </form>
            </MuiThemeProvider>
        );
    }
}

LoginForm.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
};

LoginForm = reduxForm({
    form: 'LoginForm',
    validate
})(LoginForm);

export default withStyles(styles, {withTheme: true})(LoginForm);
