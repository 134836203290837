import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import UserPlaceholder from '../../images/placeholders/user.jpg';
import {FROM_PARENT, FROM_TEACHER} from "../../constants/constants";
import {PARENT_IMG_URL, SOCKET_BASE_URL, PROFILE_IMG_URL, FORUM_IMG_URL} from "../../constants/request_url";
import {
    currentLanguage,
    formatDateTime,
    formatForumPostDate,
    getLocalizedMessage,
    trimString, userFullname,
    utcDate
} from "../../helpers";
import TimeComponent from "../TimeComponent";
import Fab from "@material-ui/core/Fab";
import SendIcon from "@material-ui/icons/Send";
import InputBase from "@material-ui/core/InputBase";
import {connect} from "react-redux";
import {
    addComment,
    addReply,
    getComments,
    getReplies,
    newComment, newPost,
    newReply,
    sendComments,
    sendReplies
} from "../../actions/forumActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDown from "../../images/down_arrow.png";
import ArrowUp from "../../images/up_arrow.png";
import io from "socket.io-client";
import {userImageUri, userPublicId} from "../../helpers/StorageHelper";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    inputRoot: {
        color: 'inherit',
        background: '#FFFFFF',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    inputInput: {
        width: '100%',
        fontSize: 15
    },
    inputRootReply: {
        color: 'inherit',
        background: 'rgba(0, 0, 0, 0.12)',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    fab: {
        marginLeft: 10
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PaperComponent(props) {
    return (
        <Draggable cancel='.input'>
            <Paper {...props} />
        </Draggable>
    );
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
    },
}))(MuiDialogContent);

/*const commentSocket = io(`${SOCKET_BASE_URL}/forum/comment`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'X-TenantID': localStorage.getItem('teacherSchoolId'),
                Language: currentLanguage()
            }
        }
    },
    forceNew: true,
    reconnection: true
});

const replySocket = io(`${SOCKET_BASE_URL}/forum/reply`, {
    path: '',
    transportOptions: {
        polling: {
            extraHeaders: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'X-TenantID': localStorage.getItem('teacherSchoolId'),
                Language: currentLanguage()
            }
        }
    },
    forceNew: true,
    reconnection: true
});*/


class CommentsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: {},
            replyText: '',
            commentId: 0,
            shownReplies: {}
        };

        this.onCommentChange = this.onCommentChange.bind(this);
        this.onCommentKeyPress = this.onCommentKeyPress.bind(this);
        this.onSubmitComment = this.onSubmitComment.bind(this);
        this.onSubmitReply = this.onSubmitReply.bind(this);
        this.replyComment = this.replyComment.bind(this);
        this.onReplyChange = this.onReplyChange.bind(this);
        this.onReplyKeyPress = this.onReplyKeyPress.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.showReplies = this.showReplies.bind(this);
        this.hideReplies = this.hideReplies.bind(this);

        this.scrollViewRef = React.createRef();

        this.commentSocket = io(`${SOCKET_BASE_URL}/forum/comment/teacher`, {
            path: '',
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('teacherToken')}`,
                        'X-TenantID': localStorage.getItem('teacherSchoolId'),
                        Language: currentLanguage()
                    }
                }
            },
            forceNew: true,
            reconnection: true
        });

        this.replySocket = io(`${SOCKET_BASE_URL}/forum/reply/teacher`, {
            path: '',
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('teacherToken')}`,
                        'X-TenantID': localStorage.getItem('teacherSchoolId'),
                        Language: currentLanguage()
                    }
                }
            },
            forceNew: true,
            reconnection: true
        });

    }

    componentDidMount() {
        const {id} = this.props.item;
        this.props.getComments(id, 0, {});

        const thisRef = this;

        this.commentSocket.connect();
        this.commentSocket.on(`post:${id}`, data => {
            if (data.exclude !== userPublicId()) {
                thisRef.props.newComment(thisRef.props.commentsData, data);
                const post = {...thisRef.props.item};
                post.comments += 1;
                this.props.newPost(thisRef.props.postsData, post);
            }
        });

        this.replySocket.connect();
        this.replySocket.on(`post:${id}`, data => {
            if (data.exclude !== userPublicId()) {
                thisRef.props.newReply(thisRef.props.commentsData, data);
            }
        });

    };

    componentWillUnmount() {
        this.commentSocket.disconnect();
        this.replySocket.disconnect();
    }

    handleScroll = e => {
        const {loading, loadingMore, item} = this.props;
        if ((this.scrollViewRef.current.scrollHeight - this.scrollViewRef.current.offsetHeight) <= (this.scrollViewRef.current.scrollTop + 100) && !loading && !loadingMore) {
            const {comments, replies, nextPage, lastPage} = this.props.commentsData;
            if (nextPage <= lastPage) {
                this.props.getComments(item.id, nextPage, this.props.commentsData);
            }
        }
    };

    onCommentKeyPress = postId => event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        if (code === 13) {
            event.preventDefault();
            const {comments} = this.state;
            const mComments = {...comments};
            const text = mComments[postId.toString()];
            if (text && text.trim() !== '') {
                const date = utcDate();

                const data = {
                    id: 0,
                    pendingId: date.getTime(),
                    category: FROM_TEACHER,
                    text: text.trim(),
                    dateCommented: formatDateTime(date),
                    fromIdNo: userPublicId(),
                    fromName: userFullname(),
                    fromImageUri: userImageUri(),
                    postId: postId
                };
                this.props.addComment(this.props.allCommentsData.comments, this.props.commentsData, data);
                const post = {...this.props.item};
                post.comments += 1;
                this.props.newPost(this.props.postsData, post);
                mComments[postId.toString()] = '';
                this.setState({comments: mComments});
            }
        }
    };

    onSubmitComment = postId => event => {
        const {comments} = this.state;
        const mComments = {...comments};
        const text = mComments[postId.toString()];
        if (text && text !== '') {
            const date = utcDate();
            const data = {
                id: 0,
                pendingId: date.getTime(),
                category: FROM_TEACHER,
                text: text.trim(),
                dateCommented: formatDateTime(date),
                fromIdNo: userPublicId(),
                fromName: userFullname(),
                fromImageUri: userImageUri(),
                postId: postId
            };
            this.props.addComment(this.props.allCommentsData.comments, this.props.commentsData, data);
            const post = {...this.props.item};
            post.comments += 1;
            this.props.newPost(this.props.postsData, post);
            mComments[postId.toString()] = '';
            this.setState({comments: mComments});
        }
    };

    onCommentChange = postId => event => {
        const {comments} = this.state;
        const mComments = {...comments};
        mComments[postId.toString()] = event.target.value;
        this.setState({comments: mComments})
    };

    replyComment = commentId => event => {
        this.setState({commentId: commentId, replyText: ''})
    };

    onReplyChange = event => {
        this.setState({replyText: event.target.value});
    };

    onReplyKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        if (code === 13) {
            event.preventDefault();
            const {commentId, replyText} = this.state;
            if (replyText.trim() !== '') {
                const date = utcDate();

                const data = {
                    id: 0,
                    pendingId: date.getTime(),
                    category: FROM_TEACHER,
                    text: replyText.trim(),
                    dateReplied: formatDateTime(date),
                    fromIdNo: userPublicId(),
                    fromName: userFullname(),
                    fromImageUri: userImageUri(),
                    commentId: commentId
                };
                this.props.addReply(this.props.allRepliesData.replies, this.props.commentsData, data);
                const showReplies = {...this.state.shownReplies};
                showReplies[commentId.toString()] = true;
                this.setState({commentId: 0, reply: '', shownReplies: showReplies});
            }
        }
    };

    onSubmitReply = event => {
        const {commentId, replyText} = this.state;
        if (replyText.trim() !== '') {
            const date = utcDate();

            const data = {
                id: 0,
                pendingId: date.getTime(),
                category: FROM_TEACHER,
                text: replyText.trim(),
                dateReplied: formatDateTime(date),
                fromIdNo: userPublicId(),
                fromName: userFullname(),
                fromImageUri: userImageUri(),
                commentId: commentId
            };
            this.props.addReply(this.props.allRepliesData.replies, this.props.commentsData, data);
            const showReplies = {...this.state.shownReplies};
            showReplies[commentId.toString()] = true;
            this.setState({commentId: 0, reply: '', shownReplies: showReplies});
        }
    };

    showReplies = commentId => e => {
        const showReplies = {...this.state.shownReplies};
        showReplies[commentId.toString()] = true;
        this.setState({shownReplies: showReplies});
    };

    hideReplies = commentId => e => {
        const showReplies = {...this.state.shownReplies};
        showReplies[commentId.toString()] = false;
        this.setState({shownReplies: showReplies});
    };

    render() {
        const {open, handleClose, item, classes, loadingMore, loading, commentsData} = this.props;
        const {comments, replies} = commentsData;
        const {shownReplies} = this.state;

        const data = [];

        if (!loading && comments) {
            const myComments = [...comments];
            myComments.forEach(comment => {
                const myReplies = replies.filter(x => x.commentId === comment.id);
                data.push({...comment, replies: myReplies});
            });
        }

        return (
            <Dialog open={open}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    fullWidth
                    fullScreen={true}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
            >
                <DialogTitle onClose={handleClose}>
                    <div className='text-center'>{getLocalizedMessage('comments')}</div>
                </DialogTitle>
                <DialogContent style={{overflow: 'hidden'}}>
                    {
                        item.id &&
                        <div className='forum-container pb-3 h-100' ref={this.scrollViewRef}
                             style={{overflow: "scroll"}} onScroll={this.handleScroll}>
                            <div className='forum-item mt-2'>
                                <div className='d-flex align-items-center'>
                                    {
                                        !item.fromImageUri &&
                                        <img src={UserPlaceholder} alt="" className='forum-profile-img'/>
                                    }
                                    {
                                        item.fromImageUri && item.category === FROM_PARENT &&
                                        <img src={`${PARENT_IMG_URL}/${item.fromImageUri}`} alt=""
                                             className='forum-profile-img'/>
                                    }
                                    {
                                        item.fromImageUri && item.category === FROM_TEACHER &&
                                        <img src={`${PROFILE_IMG_URL}/${item.fromImageUri}`} alt=""
                                             className='forum-profile-img'/>
                                    }
                                    <div className='ml-2'>
                                        <div className='text-capitalize'>
                                            {item.category === FROM_TEACHER && item.fromIdNo === userPublicId() ? getLocalizedMessage('me') : item.fromName}
                                            {
                                                !(item.category === FROM_TEACHER && item.fromIdNo === userPublicId()) &&
                                                <span
                                                    className='text-black-50'>[{item.category === FROM_TEACHER ? getLocalizedMessage('teacher') : item.isAlumni === true ? getLocalizedMessage('alumni') : getLocalizedMessage('parent')}]</span>
                                            }
                                        </div>
                                        <div className='text-black-50'><TimeComponent time={item.datePosted}
                                                                                      formatFunction={formatForumPostDate}/>
                                        </div>
                                    </div>
                                </div>
                                <div>{item.text}</div>
                                {
                                    item.imageUri &&
                                    <img src={`${FORUM_IMG_URL}/${item.imageUri}`} className='forum-img mt-2'/>
                                }
                                {
                                    commentsData.lastPage >= 0 &&
                                    <div
                                        className='text-black-50 mt-2'>{commentsData.lastPage + 1} comment{commentsData.lastPage > 0 ? 's' : ''}</div>
                                }

                                {
                                    item.id !== 0 && !loading &&
                                    <div className='d-flex align-items-end mt-2'>
                                        <InputBase fullWidth placeholder={getLocalizedMessage('writeComment')}
                                                   multiline
                                                   rows={1}
                                                   rowsMax={5}
                                                   classes={{
                                                       root: classes.inputRoot,
                                                       input: classes.inputInput,
                                                   }}
                                                   value={this.state.comments[item.id.toString()] ? this.state.comments[item.id.toString()] : ''}
                                                   onChange={this.onCommentChange(item.id)}
                                                   onKeyPress={this.onCommentKeyPress(item.id)}/>
                                        <Fab color="primary" aria-label="Add" className={`${classes.fab} text-white`}
                                             size='small'
                                             onClick={this.onSubmitComment(item.id)}
                                             disabled={!this.state.comments[item.id.toString()] || this.state.comments[item.id.toString()].trim() === ''}>
                                            <SendIcon/>
                                        </Fab>
                                    </div>
                                }

                                {
                                    data.length > 0 &&
                                    <div className='mt-2'>
                                        {
                                            data.map((comment, commentIndex) => (
                                                <div className="comment-item d-flex justify-content-start"
                                                     key={commentIndex}>
                                                    {
                                                        !comment.fromImageUri &&
                                                        <img src={UserPlaceholder} alt=""
                                                             className='comment-profile-img'/>
                                                    }
                                                    {
                                                        comment.fromImageUri && comment.category === FROM_PARENT &&
                                                        <img src={`${PARENT_IMG_URL}/${comment.fromImageUri}`} alt=""
                                                             className='comment-profile-img'/>
                                                    }
                                                    {
                                                        comment.fromImageUri && comment.category === FROM_TEACHER &&
                                                        <img src={`${PROFILE_IMG_URL}/${comment.fromImageUri}`} alt=""
                                                             className='comment-profile-img'/>
                                                    }
                                                    <div className='pl-2'>
                                                        <div className='text-capitalize'>
                                                            <strong>{comment.category === FROM_TEACHER && comment.fromIdNo === userPublicId() ? getLocalizedMessage('me') : comment.fromName}</strong>
                                                            {
                                                                !(comment.category === FROM_TEACHER && comment.fromIdNo === userPublicId()) &&
                                                                <span
                                                                    className='text-black-50'>[{comment.category === FROM_TEACHER ? getLocalizedMessage('teacher') : comment.isAlumni === true ? getLocalizedMessage('alumni') : getLocalizedMessage('parent')}]</span>
                                                            }
                                                        </div>
                                                        <div>{comment.text}</div>
                                                        <div className='text-black-50'>
                                                            <span><TimeComponent time={comment.dateCommented}
                                                                                 formatFunction={formatForumPostDate}/></span>
                                                            {
                                                                comment.id !== 0 &&
                                                                <span
                                                                    className='ml-3 cursor-pointer'
                                                                    onClick={this.replyComment(comment.id)}>{getLocalizedMessage('reply')}</span>
                                                            }
                                                        </div>

                                                        {
                                                            this.state.commentId === comment.id && comment.id > 0 &&
                                                            <div
                                                                className='d-flex align-items-end mt-2'>
                                                                <InputBase fullWidth
                                                                           placeholder={getLocalizedMessage('writeReply')}
                                                                           multiline
                                                                           rows={1}
                                                                           rowsMax={5}
                                                                           classes={{
                                                                               root: classes.inputRootReply,
                                                                               input: classes.inputInput,
                                                                           }}
                                                                           value={this.state.replyText}
                                                                           onChange={this.onReplyChange}
                                                                           onKeyPress={this.onReplyKeyPress}/>
                                                                <Fab color="primary"
                                                                     aria-label={getLocalizedMessage('send')}
                                                                     className={`${classes.fab} text-white`}
                                                                     size='small'
                                                                     onClick={this.onSubmitReply}
                                                                     disabled={this.state.replyText.trim() === ''}>
                                                                    <SendIcon/>
                                                                </Fab>
                                                            </div>
                                                        }

                                                        {
                                                            comment.replies.length > 0 &&
                                                            <div>
                                                                <div className='d-flex'>
                                                                    {
                                                                        !shownReplies[comment.id.toString()] &&
                                                                        <div
                                                                            onClick={this.showReplies(comment.id)}
                                                                            className='text-color-primary mt-1 cursor-pointer d-flex align-items-center'>
                                                                            <img src={ArrowDown} alt=""
                                                                                 className='reply-arrow-icon mr-1'/>
                                                                            <span>
                                                                                {getLocalizedMessage('view')} {comment.replies.length ? comment.replies.length : ''} {comment.replies.length > 1 ? getLocalizedMessage('replies') : getLocalizedMessage('reply')}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        shownReplies[comment.id.toString()] &&
                                                                        <div
                                                                            onClick={this.hideReplies(comment.id)}
                                                                            className='text-color-primary mt-1 cursor-pointer d-flex align-items-center'>
                                                                            <img src={ArrowUp} alt=""
                                                                                 className='reply-arrow-icon mr-1'/>
                                                                            <span>
                                                                                {getLocalizedMessage('hide')} {comment.replies.length} {comment.replies.length > 1 ? getLocalizedMessage('replies') : getLocalizedMessage('reply')}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                {
                                                                    shownReplies[comment.id.toString()] &&
                                                                    <div>
                                                                        {
                                                                            comment.replies.map((reply, replyIndex) => (
                                                                                <div className='pl-0 pt-1 pb-1 '
                                                                                     key={replyIndex}>
                                                                                    <div className="d-flex"
                                                                                         key={replyIndex}>
                                                                                        {
                                                                                            !reply.fromImageUri &&
                                                                                            <img src={UserPlaceholder}
                                                                                                 alt=""
                                                                                                 className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_PARENT &&
                                                                                            <img
                                                                                                src={`${PARENT_IMG_URL}/${reply.fromImageUri}`}
                                                                                                alt=""
                                                                                                className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_TEACHER &&
                                                                                            <img
                                                                                                src={`${PROFILE_IMG_URL}/${reply.fromImageUri}`}
                                                                                                alt=""
                                                                                                className='reply-profile-img'/>
                                                                                        }
                                                                                        <div className='ml-2'>
                                                                                            <div
                                                                                                className='text-capitalize'>
                                                                                                {/*<strong>{trimString(reply.fromName, 20)}</strong>*/}
                                                                                                <strong>{reply.category === FROM_TEACHER && reply.fromIdNo === userPublicId() ? "Me" : reply.fromName}</strong>
                                                                                            </div>
                                                                                            <div>{reply.text}</div>
                                                                                            <div
                                                                                                className='text-black-50 last-seen-text'>
                                                                                    <span><TimeComponent
                                                                                        time={reply.dateReplied}
                                                                                        formatFunction={formatForumPostDate}/></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                }

                                {
                                    (loadingMore || loading) &&
                                    <div className='w-100 d-flex justify-content-center pt-3'>
                                        <CircularProgress color='primary' size={40} thickness={4}/>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

CommentsDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    getComments: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    loadingMore: state.commentsData.loadingMore,
    commentsData: state.commentsData.data,
    loading: state.commentsData.loading,
    sendingComments: state.sendCommentsData.loading,
    sendingReplies: state.sendRepliesData.loading,
    allCommentsData: state.allCommentsData.data,
    allRepliesData: state.allRepliesData.data,
    postsData: state.postsData.data
});

export default connect(
    mapStateToProps,
    {
        getComments,
        newComment,
        newReply,
        sendComments,
        sendReplies,
        addComment,
        addReply,
        newPost
    })
(withStyles(styles, {withTheme: true})(CommentsDialog));