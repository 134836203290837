import {dialogData, loading} from "./helperActions";
import * as actionTypes from "../constants/actionTypes";
import axios from "../axios/axios";
import * as urls from "../constants/request_url";
import {handleErrorResponse, handleSuccessResponse} from "../helpers";
import {axiosConfig, PAGE_LIMIT} from "../constants/constants";
import {toggleDialog} from "./commonActions";
import {RESERVE_BOOK_DIALOG} from "../constants/DialogActions";

export const getBooks = offset => dispatch => {
    dispatch(
        loading(actionTypes.GET_BOOKS, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/${offset}/${PAGE_LIMIT}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_BOOKS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_BOOKS, error)
                );
            }
        )
};

export const getBookCategories = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_BOOK_CATEGORIES, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/category`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_BOOK_CATEGORIES, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_BOOK_CATEGORIES, error)
                );
            }
        )
};

export const getCategoryBooks = (categoryId, offset) => dispatch => {
    dispatch(
        loading(actionTypes.GET_BOOKS, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/${categoryId}/${offset}/${PAGE_LIMIT}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_BOOKS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_BOOKS, error)
                );
            }
        )
};

export const booksMetadata = (categoryId, offset, searchTerm) => dispatch => {
    dispatch(
        loading(actionTypes.BOOKS_METADATA,
            {
                categoryId: categoryId,
                offset: offset,
                searchTerm: searchTerm
            }
            , 0, false)
    );
};

export const searchBooks = (query, offset) => dispatch => {
    dispatch(
        loading(actionTypes.GET_BOOKS, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/search/${query}/${offset}/${PAGE_LIMIT}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_BOOKS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_BOOKS, error)
                );
            }
        )
};

export const searchBooksByCategory = (query, categoryId, offset) => dispatch => {
    dispatch(
        loading(actionTypes.GET_BOOKS, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/search/${query}/${categoryId}/${offset}/${PAGE_LIMIT}`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_BOOKS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_BOOKS, error)
                );
            }
        )
};

export const reserveBook = bookId => dispatch => {
    dispatch(
        loading(actionTypes.RESERVE_BOOK, {}, 0, true)
    );
    axios.post(`${urls.LIBRARY_URL}/reserve/${bookId}`, {}, axiosConfig)
        .then(response => {
            dispatch(toggleDialog(RESERVE_BOOK_DIALOG, false));
            dispatch(
                handleSuccessResponse(actionTypes.RESERVE_BOOK, response.data, response.status, true)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.RESERVE_BOOK, error, true)
                );
            }
        )
};

export const toggleReserveDialog = open => dispatch => {
    dispatch(toggleDialog(RESERVE_BOOK_DIALOG, open));
};

export const getReservedBooks = () => dispatch => {
    dispatch(
        loading(actionTypes.GET_RESERVED_BOOKS, {}, 0, true)
    );
    axios.get(`${urls.LIBRARY_URL}/reserved`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.GET_RESERVED_BOOKS, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleErrorResponse(actionTypes.GET_RESERVED_BOOKS, error)
                );
            }
        )
};