import axios from 'axios';
import {REQUEST_BASE_URL} from "../constants/request_url";
import {currentLanguage} from "../helpers";

const instance  = axios.create({
    baseURL: REQUEST_BASE_URL
});

instance.defaults.headers.common['Authorization'] = `bearer  ${localStorage.getItem('teacherToken')}`;
instance.defaults.headers.common['X-TenantID'] = localStorage.getItem('teacherSchoolId');
instance.defaults.headers.common['Language'] = currentLanguage();

export default instance;
