import React, {Component} from 'react';
import {editIssuedLeaves, getIssuedLeaves} from "../../actions/leaveActions";
import connect from "react-redux/es/connect/connect";
import withStyles from "@material-ui/core/es/styles/withStyles";
import * as PropTypes from "prop-types";
import {mainTheme} from "../../themes/Themes";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/es/Button";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Paper from "@material-ui/core/es/Paper/Paper";
import {createDate, getLocalizedMessage, titleCase, toastError} from "../../helpers";
import {format} from "date-fns";
import {DateTimePicker} from "material-ui-pickers";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from "@material-ui/core/es/CircularProgress";
import {toggleDialog} from "../../actions/commonActions";
import * as dialogActions from "../../constants/DialogActions";
import IssueLeaveDialog from "../dialogs/IssueLeaveDialog";
import Preloader2 from "../progress_bars/Preloader2";
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({});

class IssuedLeaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editable: false,
            contentsChanged: false,
            data: []
        };

        this.addDialogRef = React.createRef();

        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('leaves');
        this.props.setTitle(getLocalizedMessage('leaves'));
        this.props.getIssuedLeaves();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {data} = this.state;
        const submitData = [];
        let itemEmpty = false;
        data.forEach(item => {
            if (item.edited === true) {

                if (item.title === '' || item.description === '' || item.dueDate === '' || item.status === '') {
                    itemEmpty = true;
                } else {
                    if (item.status === 1) {
                        submitData.push({
                            title: item.title,
                            description: item.description,
                            dueDate: item.dueDate.toUTCString(),
                            id: item.id,
                            status: item.status
                        })
                    } else {
                        submitData.push({
                            title: item.title,
                            description: item.description,
                            dueDate: item.dueDate.toUTCString(),
                            id: item.id,
                            status: item.status,
                            resumeDate: item.resumeDate.toUTCString()
                        })
                    }
                }
            }
        });

        if (itemEmpty === true) {
            toastError(getLocalizedMessage('fillFields'));
        } else if (itemEmpty === false && submitData.length > 0) {
            this.props.editIssuedLeaves(JSON.stringify(submitData));
        }
    };

    handleModeChange = event => {
        const {issuedLeaves} = this.props.data;
        if (issuedLeaves.length > 0) {
            this.setState({
                    editable: event.target.value
                },
                () => {
                    if (this.state.editable) {
                        const data = [];
                        issuedLeaves.forEach(item => {
                            let resumeDate = null;
                            if (item.resumeDate) {
                                resumeDate = createDate(item.resumeDate)
                            }
                            data.push({
                                title: item.title,
                                description: item.description,
                                studentName: item.studentName,
                                admNo: item.admNo,
                                dateIssued: createDate(item.dateIssued),
                                dueDate: createDate(item.dueDate),
                                id: item.id,
                                status: item.statusId,
                                resumeDate: resumeDate
                            })
                        });
                        this.setState({
                            data: data,
                            contentsChanged: false
                        })
                    } else {
                        this.props.getIssuedLeaves();
                    }
                })
        }


    };

    openDialog = name => e => {
        this.setState({
            anchorEl: null
        });

        this.props.toggleDialog(name, true);
    };

    closeDialog = name => e => {
        this.props.toggleDialog(name, false);
    };

    render() {
        const {data, dialogData, classes, loading, editLoading} = this.props;
        const {editable} = this.state;
        const {issuedLeaves} = data;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="d-flex justify-content-center">
                    <div className='notice-item-2'>
                        <div className="d-flex justify-content-between">
                            <FormControl className={classes.formControl}>
                                <InputLabel shrink htmlFor="mode">
                                    {getLocalizedMessage('mode')}
                                </InputLabel>
                                <Select
                                    value={editable}
                                    input={<Input name="mode"/>}
                                    displayEmpty
                                    name="editable"
                                    className={classes.selectEmpty}
                                    onChange={this.handleModeChange}
                                >
                                    <MenuItem value={false}>{getLocalizedMessage('view')}</MenuItem>
                                    <MenuItem value={true}>{getLocalizedMessage('editable')}</MenuItem>
                                </Select>
                            </FormControl>

                            <Hidden mdDown implementation="css">
                                <div className='d-flex justify-content-end'>
                                    <Button onClick={this.openDialog(dialogActions.ISSUE_LEAVE)} variant='contained'
                                            color='primary'>
                                        {getLocalizedMessage('issueLeave')}
                                    </Button>
                                    {
                                        this.state.contentsChanged && editable &&
                                        <Button variant="outlined"
                                                color="primary"
                                                size="small"
                                                type="submit"
                                                onClick={this.handleSubmit}
                                                className='ml-3'>
                                            {getLocalizedMessage('submitChanges')}
                                        </Button>
                                    }
                                </div>
                            </Hidden>

                        </div>

                        <Hidden mdUp implementation="css">
                            <div className='d-flex mt-3'>
                                <Button onClick={this.openDialog(dialogActions.ISSUE_LEAVE)} variant='contained'
                                        color='primary'>
                                    {getLocalizedMessage('issueLeave')}
                                </Button>

                                {
                                    this.state.contentsChanged && editable &&
                                    <Button variant="outlined"
                                            color="primary"
                                            size="small"
                                            type="submit"
                                            onClick={this.handleSubmit}
                                            className='ml-3'>
                                        {getLocalizedMessage('submitChanges')}
                                    </Button>
                                }
                            </div>
                        </Hidden>
                        <div className='mt-3'>
                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-1'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }
                            {
                                !editable && issuedLeaves && issuedLeaves.length > 0 && !loading &&
                                <div>
                                    {
                                        issuedLeaves.map((item, index) => {
                                            const dateIssued = createDate(item.dateIssued);
                                            const dueDate = createDate(item.dueDate);
                                            let resumeDate = null;
                                            let displayResumeDate = null;
                                            if (item.resumeDate) {
                                                resumeDate = createDate(item.resumeDate);
                                                displayResumeDate = format(resumeDate, "EEE do MMM, yyyy h:mm bb",
                                                    {awareOfUnicodeTokens: true});
                                            }
                                            const displayDateIssued = format(dateIssued, "EEE do MMM, yyyy h:mm bb",
                                                {awareOfUnicodeTokens: true});
                                            const displayDueDate = format(dueDate, "EEE do MMM, yyyy h:mm bb",
                                                {awareOfUnicodeTokens: true});

                                            return (
                                                <Paper key={index} className='mb-3 p-3'>
                                                    <div className='mt-0'>
                                                        <strong>{getLocalizedMessage('reason')}</strong>: <span>{item.title}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('dateIssued')}</strong>: <span>{displayDateIssued}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('dueDate')}:</strong>
                                                        <span>{displayDueDate}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('studentName')}</strong>:
                                                        <span> {titleCase(item.studentName)}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('admNo')}</strong>: <span>{item.admNo}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('status')}</strong>: <span>{titleCase(item.status)}</span>
                                                    </div>
                                                    {
                                                        resumeDate &&
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('resumeDate')}</strong>:
                                                            <span> {displayResumeDate}</span>
                                                        </div>
                                                    }
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('detailedReason')}</strong>:
                                                        <span> {item.description}</span>
                                                    </div>
                                                </Paper>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {
                                editable && this.state.data.length > 0 &&
                                <div>
                                    {
                                        issuedLeaves.map((item, index) => {
                                            const dateIssued = createDate(item.dateIssued);
                                            const displayDateIssued = format(dateIssued, "EEE do MMM, yyyy h:mm bb",
                                                {awareOfUnicodeTokens: true});

                                            return (
                                                <Paper key={index} className='mb-3 p-3'>
                                                    <div className='mt-2'>
                                                        <strong>Reason</strong>:
                                                        <Input value={this.state.data[index]["title"]}
                                                               onChange={event => {
                                                                   const data = [...this.state.data];
                                                                   data[index]["title"] = event.target.value;
                                                                   data[index]["edited"] = true;

                                                                   this.setState({
                                                                       data,
                                                                       contentsChanged: true
                                                                   })
                                                               }}
                                                               className='ml-2'/>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>Date Issued</strong>: <span>{displayDateIssued}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>Due Date:</strong>
                                                        <span>
                                                        <DateTimePicker
                                                            value={this.state.data[index]['dueDate']}
                                                            onChange={date => {
                                                                let data = [...this.state.data];
                                                                data[index]['dueDate'] = date;
                                                                data[index]["edited"] = true;

                                                                this.setState({
                                                                    data,
                                                                    contentsChanged: true
                                                                })
                                                            }}
                                                            leftArrowIcon={<ChevronLeftIcon/>}
                                                            rightArrowIcon={<ChevronRightIcon/>}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className="ml-2"
                                                        />
                                                    </span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('studentName')}</strong>:
                                                        <span> {titleCase(item.studentName)}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('admNo')}</strong>: <span>{item.admNo}</span>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('status')}</strong>:
                                                        <span>
                                                            <FormControl className={classes.formControl}>
                                                                <Select
                                                                    value={this.state.data[index]["status"]}
                                                                    input={<Input name="status"/>}
                                                                    displayEmpty
                                                                    name="status"
                                                                    className={classes.selectEmpty}
                                                                    onChange={e => {
                                                                        let data = [...this.state.data];
                                                                        data[index]['status'] = e.target.value;
                                                                        data[index]["edited"] = true;
                                                                        this.setState({
                                                                            data,
                                                                            contentsChanged: true
                                                                        })
                                                                    }}
                                                                >
                                                                    <MenuItem
                                                                        value={1}>{getLocalizedMessage('onLeave')}</MenuItem>
                                                                    <MenuItem
                                                                        value={2}>{getLocalizedMessage('resumed')}</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </span>
                                                    </div>
                                                    {
                                                        this.state.data[index]['status'] === 2 &&
                                                        <div className='mt-2'>
                                                            <strong>{getLocalizedMessage('resumeDate')}</strong>
                                                            <span>
                                                        <DateTimePicker
                                                            value={this.state.data[index]['resumeDate']}
                                                            onChange={date => {
                                                                let data = [...this.state.data];
                                                                data[index]['resumeDate'] = date;
                                                                data[index]["edited"] = true;

                                                                this.setState({
                                                                    data,
                                                                    contentsChanged: true
                                                                })
                                                            }}
                                                            leftArrowIcon={<ChevronLeftIcon/>}
                                                            rightArrowIcon={<ChevronRightIcon/>}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            className="ml-2"
                                                        />
                                                    </span>
                                                        </div>
                                                    }
                                                    <div className='mt-2'>
                                                        <strong>{getLocalizedMessage('detailedReason')}</strong>:
                                                        <span>
                                                        <Input value={this.state.data[index]["description"]}
                                                               onChange={event => {
                                                                   const data = [...this.state.data];
                                                                   data[index]["description"] = event.target.value;
                                                                   data[index]["edited"] = true;

                                                                   this.setState({
                                                                       data,
                                                                       contentsChanged: true
                                                                   })
                                                               }}
                                                               className='ml-2'/>
                                                        </span>
                                                    </div>
                                                </Paper>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>

                        {
                            issuedLeaves && issuedLeaves.length === 0 && !loading &&
                            <Paper>
                                <div className='p-3'>
                                    {getLocalizedMessage('noLeaves')}
                                </div>
                            </Paper>
                        }
                    </div>
                </div>

                {
                    editLoading &&
                    <Preloader2/>
                }

                <IssueLeaveDialog
                    open={dialogData[dialogActions.ISSUE_LEAVE] ? dialogData[dialogActions.ISSUE_LEAVE] : false}
                    handleClose={this.closeDialog(dialogActions.ISSUE_LEAVE)} eload={!editable}/>
            </MuiThemeProvider>
        );
    }
}

IssuedLeaves.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getIssuedLeaves: PropTypes.func.isRequired,
    editIssuedLeaves: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.issuedLeavesData.data,
    status: state.issuedLeavesData.status,
    loading: state.issuedLeavesData.loading,
    editData: state.editIssuedLeavesData.data,
    editLoading: state.editIssuedLeavesData.loading,
    dialogData: state.dialogData.data
});

export default connect(mapStateToProps, {
    getIssuedLeaves,
    editIssuedLeaves,
    toggleDialog
})(withStyles(styles, {withTheme: true})(IssuedLeaves));