import React, {Component} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {mainTheme} from "../../themes/Themes";
import withStyles from "@material-ui/core/styles/withStyles";

const style = theme => ({});

class Preloader extends Component {
    render() {
        return (
            <MuiThemeProvider theme={mainTheme}>
                    <div className="pre-loader">
                        <div className="book">
                            <div className="inner">
                                <div className="left"/>
                                <div className="middle"/>
                                <div className="right"/>
                            </div>
                            <ul>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                                <li/>
                            </ul>
                        </div>
                    </div>
            </MuiThemeProvider>
        );
    }
}

export default withStyles(style, {withTheme: true})(Preloader);