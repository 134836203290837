import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getToken, validateToken} from "../../actions/authActions";
import {withLastLocation} from 'react-router-last-location';
import {showError} from "../../actions/helperActions";
import loginImg from '../../images/login_bg.png';

import appLogo from '../../images/app_logo.png';
// import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import LoginForm from "../forms/LoginForm";
import {currentCountryId, currentSchoolId, getLocalizedMessage} from "../../helpers";
import SelectCountry from "./SelectCountry";
import SelectSchool from "./SelectSchool";
import {MuiThemeProvider} from "@material-ui/core";

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
    fontSize: 50
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            showPassword: false,
            knecCode: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.submit = this.submit.bind(this);
        this.reload = this.reload.bind(this);
    }

    /*static getDerivedStateFromProps(props, state) {
        //
    }*/

    componentDidMount() {
        document.title = getLocalizedMessage('login');
    }

    componentDidUpdate() {
        const {data} = this.props;
        if (this.props.validateData.error === false) {
            if (this.props.lastLocation != null) {
                window.location.replace(this.props.lastLocation.pathname)
            } else {
                window.location.replace(this.props.lastLocation.pathname);
            }
        }

        if (this.props.status >= 200 && this.props.status < 300) {
            /*const expires = new Date();
            const days = 28 * 4;
            expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));*/
            const details = data.details;

            localStorage.setItem('teacherToken', data.token);
            localStorage.setItem('teacherSchoolLogo', data.schoolLogo);
            localStorage.setItem('teacherSchoolId', data.schoolId);
            localStorage.setItem('teacherLevelName', data.levelName);
            localStorage.setItem('teacherTermName', data.termName);
            localStorage.setItem('teacherSchoolName', data.schoolName);
            localStorage.setItem('teacherFName', details.fName);
            localStorage.setItem('teacherLName', details.lName);
            localStorage.setItem('teacherSurname', details.surname);
            localStorage.setItem('teacherImageUri', details.imageUri);
            localStorage.setItem('teacherName', details.name);
            localStorage.setItem('teacherPublicId', details.publicId);

            if (this.props.lastLocation != null) {
                if (this.props.lastLocation.pathname !== '/login')
                    window.location.replace(this.props.lastLocation.pathname);
                else
                    window.location.replace('/');
            } else {
                window.location.replace('/');
            }
        }
    }

    handleClickShowPassword() {
        this.setState(state => ({showPassword: !state.showPassword}));
    };

    submit = values => {
        const credentials = {
            username: values.username,
            password: values.password,
        };
        this.props.getToken(credentials);
    };

    handleChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    reload = () => {
        this.forceUpdate();
    };

    render() {
        const {loading} = this.props;

        return (
            <div>
                {
                    !currentCountryId() ? <SelectCountry reload={this.reload}/> : (!currentSchoolId() ? <SelectSchool reload={this.reload}/> : <div/>)
                }

                {
                    currentSchoolId() && currentCountryId() &&
                    <div className="row m-0">
                        <div className="col-lg-8 m-0 p-0 d-none d-lg-block">
                            <img src={loginImg} className='login-img'/>
                        </div>

                        <div className="col-lg-4 d-flex justify-content-center">
                            <div className="login-container">
                                <div className="login-logo-container">
                                    <img src={appLogo} alt="school logo" className="login-logo"/>
                                </div>

                                <h3 className="text-center form-header font-oswald">{getLocalizedMessage('logIn')}</h3>
                                <LoginForm onSubmit={this.submit}
                                           loading={loading}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

Login.proptypes = {
    getToken: PropTypes.func.isRequired,
    lastLocation: PropTypes.object.isRequired,
    validateToken: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    // result: state.authData.data
    data: state.authData.data,
    status: state.authData.status,
    loading: state.authData.loading,
    validateData: state.validateTokenData.data,
    errorData: state.showErrorData.data
});

export default connect(mapStateToProps, {getToken, validateToken, showError})(withLastLocation(Login));
