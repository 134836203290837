import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Cropper from "react-cropper";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckIcon from "@material-ui/icons/Check";
import CropIcon from "@material-ui/icons/Crop";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {getLocalizedMessage} from "../../helpers";

const styles = theme => ({});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PaperComponent(props) {
    return (
        <Draggable cancel='.input'>
            <Paper {...props} />
        </Draggable>
    );
}

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const {children, classes, onClose} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label={getLocalizedMessage('close')} className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: 0,
    },
}))(MuiDialogContent);

const imageStyle = {
    display: 'block',
    width: '300px',
    height: 'auto'
};


class AddPostDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            open, handleClose, post, handleChange, handleSubmit, imagePreviewUrl, croppedImgUrl,
            cropComplete, croppedImage, loading
        } = this.props;

        let $croppedImgPreview = null;
        if (croppedImgUrl) {
            $croppedImgPreview = (<img src={croppedImgUrl} style={imageStyle}/>);
        }

        return (
            <Dialog open={open}
                    TransitionComponent={Transition}
                    maxWidth="sm"
                    fullWidth
                    PaperComponent={PaperComponent}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullScreen
            >
                <DialogTitle onClose={handleClose}>
                    <div className='text-center'>{getLocalizedMessage('postOnForum')}</div>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-center">
                        <div className='notice-item'>
                            <div className=' mt-4 ml-3 mr-3'>
                            <textarea
                                value={post}
                                onChange={handleChange}
                                className='form-control input'
                                rows={5}
                                placeholder={getLocalizedMessage('writePost')}
                                maxLength={5000}/>
                            </div>

                            <div className=' mt-4 ml-3 mr-3'>
                                <div className='pb-3'>{getLocalizedMessage('attachImage')}</div>

                                <input
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={this.props.handleImageChange}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="contained" component="span">
                                        {getLocalizedMessage('chooseImage')}
                                    </Button>
                                </label>
                                {
                                    imagePreviewUrl && !cropComplete &&
                                    <div>
                                        <Cropper
                                            ref={this.props.cropperRef}
                                            src={imagePreviewUrl}
                                            style={{height: 200, width: 'auto'}}
                                            aspectRatio={1280 / 720}
                                            guides={false}
                                            crop={this.props._crop}
                                        />
                                        <div className='mt-3 d-flex justify-content-between'>
                                            <Button variant="contained"
                                                    component="span"
                                                    onClick={this.props.resetCrop}
                                                    color='primary'
                                            >
                                                <RefreshIcon/>
                                                <span className="ml-3">{getLocalizedMessage('reset')}</span>
                                            </Button>
                                            <Button variant="contained"
                                                    component="span"
                                                    onClick={this.props.completeCrop}
                                                    color='primary'
                                            >
                                                <CheckIcon/>
                                                <span className="ml-3">{getLocalizedMessage('done')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                }

                                {
                                    croppedImgUrl && cropComplete &&
                                    <div>
                                        {$croppedImgPreview}
                                        <div className='mt-3'>
                                            <Button variant="contained"
                                                    component="span"
                                                    onClick={this.props.crop}
                                                    color='primary'
                                            >
                                                <CropIcon/>
                                                <span className="ml-3">{getLocalizedMessage('crop')}</span>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='p-3 d-flex justify-content-end'>
                                <Button color="primary" type="submit" variant='contained'
                                        disabled={post.trim() === '' || (croppedImage && !cropComplete) || loading}
                                        onClick={handleSubmit}>
                                    {loading ? getLocalizedMessage('submitting') : getLocalizedMessage('post')}
                                    {
                                        loading &&
                                        <CircularProgress size={30} thickness={5}/>
                                    }
                                </Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

AddPostDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    post: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleImageChange: PropTypes.func.isRequired,
    crop: PropTypes.func.isRequired,
    _crop: PropTypes.func.isRequired,
    resetCrop: PropTypes.func.isRequired,
    completeCrop: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    data: state.uploadPostPhotoData.data,
    loading: state.uploadPostPhotoData.loading
});

export default connect(
    mapStateToProps,
    {})
(withStyles(styles, {withTheme: true})(AddPostDialog));