export const LOCALIZED_MESSAGES = {
    en: {
        subjectResults: "Subject Results",
        overallResults: "Overall Results",
        addResults: "Add Results",
        viewResults: "View Results",
        attendance: "Attendance",
        markAttendance: "Mark Attendance",
        viewAttendance: "View Attendance",
        leaves: "Leaves",
        issueLeave: "Issue Leave",
        viewIssuedLeaves: "View Issued Leaves",
        myTimetable: "My Timetable",
        mySubjects: "My Subjects",
        events: "Events",
        chats: "Chats",
        forum: "Forum",
        noticeboard: "Noticeboard",
        homework: "Homework",
        addHomework: "Add Homework",
        viewHomework: "View Homework",
        english: "English",
        french: "French",
        sessionExpired: "Your session has expired. Please login again.",
        ok: "OK",
        login: "Login",
        logIn: "LOG IN",
        forgotPassword: "Forgot password?",
        password: "Password",
        pleaseWait: "Please wait",
        continue: "Continue",
        profile: "Profile",
        logout: "Logout",
        email: "Email",
        selectSchool: "Select School",
        selectCountry: "Select Country",
        latest: 'Latest',
        readMore: 'Read More...',
        noEvents: 'No Events',
        searchEvents: 'Search Events',
        search: 'Search',
        event: 'Event',
        invalidEvent: 'Invalid event.',
        subjectsNotAdded: 'Subjects has not been added',
        shortName: 'Short Name',
        subjectName: 'Subject Name',
        timetableNotCreated: 'Timetable has not been created',
        notNotices: 'No Notice between these dates',
        from: 'From',
        to: 'To',
        invalidNotice: 'Invalid Notice',
        noHomework: 'You\'ve not added any Homework between these dates',
        submissionDate: 'Submission Date',
        dueDate: 'Due Date',
        date: 'Date',
        stream: 'Stream',
        subject: 'Subject',
        instructions: 'Instructions',
        close: 'Close',
        submit: 'Submit',
        chooseStream: 'Choose stream',
        noLeaves: 'You have not issued any leave',
        mode: 'Mode',
        view: 'View',
        editable: 'Editable',
        submitChanges: 'Submit changes',
        reason: 'Reason',
        dateIssued: 'Date Issued',
        studentName: 'Student Name',
        admNo: 'Admission Number',
        status: 'Status',
        resumeDate: 'Resume Date',
        detailedReason: 'Detailed Reason',
        onLeave: 'On Leave',
        resumed: 'Resumed',
        required: 'Required',
        admNoMustBeNo: 'Admission Number must be a number',
        year: 'Year',
        name: 'Name',
        attendanceNotMarked: 'Attendance has not been marked.',
        noStudentsInStream: 'There is no students in that stream',
        present: 'Present',
        moreActions: 'More Actions',
        score: 'Score',
        resultsNotAdded: 'Results have not been added.',
        importResults: 'Import Results',
        exportResults: 'Export Results',
        exam: 'Exam',
        scoreMustBeBetween: 'Score must be between 0 to',
        importResultsExcelFile: 'Import Results Excel File',
        chooseFile: 'Choose File',
        processing: 'Processing',
        fileNotExcel: 'The file must be an excel file',
        pleaseChooseFile: 'Please choose file',
        total: 'Total',
        admNo2: 'Adm No',
        points: 'Points',
        grade: 'Grade',
        sPos: 'S.POS',
        oPos: 'O.POS',
        studentResult: 'Student Result',
        totalPoints: 'Total Points',
        meanGrade: 'Mean Grade',
        streamPosition: 'Stream Position',
        overallPosition: 'Overall Position',
        libraryHistory: 'Library History',
        library: 'Library',
        reserveBook: 'Reserve Book',
        bookTitle: 'Book Title',
        reserveBookMessage: 'You can reserve book only for 24 hours.',
        reserve: 'Reserve',
        cancel: 'Cancel',
        genre: 'Genre',
        author: 'Cancel',
        publisher: 'Publisher',
        isbn: 'ISBN',
        edition: 'Edition',
        materialType: 'Material Type',
        eBook: 'E-Book',
        hardCopy: 'Hard Copy',
        available: 'Available',
        unAvailable: 'Unavailable',
        availability: 'Availability',
        download: 'Download',
        noBooks: 'No books in the library',
        inShelf: 'In Shelf',
        returned: 'Returned',
        reserved: 'Reserved',
        replaced: 'Replaced',
        lost: 'Lost',
        issued: 'Issued',
        noLibraryHistory: 'You have no library history',
        searchBooks: 'Search Books by title, ISBN, author',
        allGenres: 'All Genres',
        comments: 'Comments',
        reply: 'Reply',
        teacher: 'Teacher',
        parent: 'Parent',
        alumni: 'Alumni',
        writeComment: 'Write a comment...',
        writeReply: 'Write a reply...',
        send: 'Send',
        replies: 'Replies',
        hide: 'Hide',
        fileNotImage: 'The file must be an image',
        addNewForumPost: 'Add New Forum Post',
        beFirstToPost: 'Be the first to post in the forum',
        me: 'Me',
        comment: 'Comment',
        postOnForum: 'Post on Forum',
        writePost: 'Write your post...',
        attachImage: 'Attach an image',
        chooseImage: 'Choose image',
        reset: 'Reset',
        done: 'Done',
        crop: 'Crop',
        submitting: 'Submitting',
        post: 'Post',
        imageEmpty: 'Please choose an image',
        uploadProfileImage: 'Upload Profile Image',
        uploadImage: 'Upload Image',
        uploading: 'Uploading',
        account: 'Account',
        change: 'Change',
        gender: 'Gender',
        phoneNo: 'Phone Number',
        dateOnDuty: 'Date On Duty',
    },
    fr: {
        subjectResults: "Sujet Résultats",
        overallResults: "Résultats globaux",
        addResults: "Ajouter des résultats",
        viewResults: "Voir les résultats",
        attendance: "Présence",
        markAttendance: "Marquer la présence",
        viewAttendance: "Afficher la participation",
        leaves: "Congés",
        issueLeave: "Émettre un congé",
        viewIssuedLeave: "Voir les congés émis",
        myTimetable: "Mon emploi du temps",
        mySubjects: "Mes Sujets",
        events: "Événements",
        chats: "Discussions",
        forum: "Forum",
        noticeboard: "Tableau d'affichage",
        homework: "Devoirs",
        addHomework: "Ajouter des devoirs",
        viewHomework: "Voir les devoirs",
        english: "Anglais",
        french: "Français"
    }
};