import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getTeachingSubjects} from "../../actions/subjectActions";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {getLocalizedMessage} from "../../helpers";

const styles = theme => ({});

class Subjects extends Component {
    componentDidMount() {
        document.title = getLocalizedMessage('mySubjects');
        this.props.setTitle(getLocalizedMessage('mySubjects'));
        this.props.getTeachingSubjects();
    }

    render() {
        const {data, loading} = this.props;
        const {subjects} = data;
        return (
            <MuiThemeProvider theme={mainTheme}>
                <div>
                    {
                        loading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }
                    <div className='pt-3 pt-lg-0'>
                        {
                            subjects && subjects.length > 0 && !loading &&
                           <Paper elevation={1}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>{getLocalizedMessage('shortName')}</TableCell>
                                            <TableCell>{getLocalizedMessage('subjectName')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            subjects.map((subject, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{subject.shortName.toUpperCase()}</TableCell>
                                                    <TableCell>{subject.name}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        }

                        {
                            subjects && subjects.length === 0 &&
                            <Paper>
                                <div className='p-3'>
                                    {getLocalizedMessage('subjectsNotAdded')}
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Subjects.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getTeachingSubjects: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    data: state.getTeachingSubjectsData.data,
    loading: state.getTeachingSubjectsData.loading,
    status: state.getTeachingSubjectsData.status,
});

export default connect(mapStateToProps, {getTeachingSubjects})(withStyles(styles, {withTheme: true})(Subjects));