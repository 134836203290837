import * as actionTypes from '../constants/actionTypes';
import axios from '../axios/axios';
import * as urls from '../constants/request_url';
import {handleAuthErrorResponse, handleSuccessResponse, resetError} from "../helpers";
import {axiosConfig} from "../constants/constants";
import {loading} from "./helperActions";
import {sendDeviceToken} from "./fcmActions";
import io from "socket.io-client";
import {SOCKET_BASE_URL} from "../constants/request_url";


export const getToken = (userData) => dispatch => {
    dispatch(
        loading(actionTypes.GET_TOKEN, {}, 0, true)
    );

    // const credentials = JSON.stringify(userData);
    const credentials = btoa(userData.username + ':' + userData.password);
    axios.defaults.headers.common['Authorization'] = `Basic  ${credentials}`;

    axios.post(`${urls.LOGIN_URL}`, null, axiosConfig)
        .then(response => {
                dispatch(
                    handleSuccessResponse(actionTypes.GET_TOKEN, response.data, response.status)
                );
            }
        )
        .catch(error => {
                dispatch(
                    handleAuthErrorResponse(actionTypes.GET_TOKEN, error, true)
                );
                setTimeout(function () {
                    dispatch(
                        resetError(error, actionTypes.GET_TOKEN)
                    )
                }, 4000);
            }
        )
};

export const validateToken = () => dispatch => {
    dispatch(
        loading(actionTypes.VALIDATE_TOKEN, {}, 0, true)
    );
    axios.post(urls.VALIDATE_TOKEN_URL)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.VALIDATE_TOKEN, response.data, response.status)
            );
            if (localStorage.getItem("deviceId")) {
                const data = JSON.stringify({device_id: localStorage.getItem("deviceId")});
                dispatch(sendDeviceToken(data));
            }
        })
        .catch(error => {
            dispatch(
                    handleAuthErrorResponse(actionTypes.VALIDATE_TOKEN, error)
                );
            }
        )
};

export const blacklistToken = () => dispatch => {
    dispatch(
        loading(actionTypes.BLACKLIST_TOKEN, {}, 0, true)
    );
    axios.post(`${urls.LOGOUT_URL}/2`)
        .then(response => {
            dispatch(
                handleSuccessResponse(actionTypes.BLACKLIST_TOKEN, response.data, response.status)
            );
        })
        .catch(error => {
                dispatch(
                    handleAuthErrorResponse(actionTypes.BLACKLIST_TOKEN, error)
                );
                setTimeout(function () {
                    dispatch(
                        resetError(error, actionTypes.BLACKLIST_TOKEN)
                    )
                }, 4000);
            }
        )
};
