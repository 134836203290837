import {RESERVE_BOOK_DIALOG} from "../../constants/actionTypes";

const initialState = {
    data: {},
    loading: false
};

export default (state = initialState, action) => {
    const newState = {...state};

    switch (action.type) {
        case RESERVE_BOOK_DIALOG:
            newState.data = action.data;
            return newState;
        default:
            return state;
    }
}
