import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getAllEvents, getEvents, searchEvents} from "../../actions/eventActions";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {format} from "date-fns";
import {createDate, getLocalizedMessage, trimString} from "../../helpers";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {EVENT_IMG_URL} from "../../constants/request_url";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import InputBase from "@material-ui/core/es/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from "@material-ui/core/CircularProgress";
import {withRouter} from "react-router";
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    fab: {
        margin: theme.spacing.unit,
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Events extends Component {
    constructor(props) {
        super(props);
        const query = this.props.match.params.query;
        this.state = {
            query: query ? query : ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('events');
        this.props.setTitle(getLocalizedMessage('events'));

        const query = this.props.match.params.query;
        if (query) {
            this.props.searchEvents(query);
        } else {
            this.props.getAllEvents();
        }
        this.props.getEvents(0, 5);
    }

    handleChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            }
        );

    };

    handleSearch = event => {
        const {query} = this.state;
        if (query.trim().length > 2) {
            this.props.searchEvents(query);
        }
    };

    onKeyPress = event => {
        const code = (event.keyCode ? event.keyCode : event.which);
        const {message} = this.state;
        if (code === 13) {
            event.preventDefault();
            const {query} = this.state;
            if (query.trim().length > 2) {
                this.props.searchEvents(query);
            }
        }
    };

    render() {
        const {classes, data, loading, latestData, latestLoading} = this.props;
        const {query} = this.state;
        const {events} = data;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className="">
                    <div className="row m-0">
                        <div className="col-12 order-0 order-md-1 col-md-4 mb-0 mb-lg-4 mt-3 mt-lg-0">
                            <Paper className={`${classes.root} mb-4`} elevation={1}>
                                <InputBase className={classes.input} placeholder={getLocalizedMessage('searchEvents')}
                                           value={query}
                                           name='query'
                                           onChange={this.handleChange}
                                           onKeyPress={this.onKeyPress}/>
                                <IconButton className={classes.iconButton} aria-label={getLocalizedMessage('search')}
                                            onClick={this.handleSearch}>
                                    <SearchIcon/>
                                </IconButton>
                            </Paper>
                            <Hidden smDown implementation="css">
                                <div>
                                    <h5 className="text-info">{getLocalizedMessage('latest')}</h5>

                                    {
                                        latestLoading &&
                                        <div className='w-100 d-flex justify-content-center pt-3'>
                                            <CircularProgress color='primary' size={50} thickness={5}/>
                                        </div>
                                    }
                                    <Paper className='w-100'>
                                        {
                                            latestData.events &&
                                            <div>
                                                {
                                                    latestData.events.map((event, index) => (
                                                        <div key={index} className='recent-event-container'>
                                                            <Link to={`/events/${event.id}`}>
                                                                <div className="p-3 d-flex">
                                                                    <img src={`${EVENT_IMG_URL}/${event.imgUri}`}
                                                                         alt="events"
                                                                         className="recent-event-img"/>
                                                                    <div className="ml-3">
                                                                        <div>
                                                                            <strong className='text-dark'>
                                                                                {trimString(event.title, 20)}
                                                                            </strong>
                                                                        </div>
                                                                        <div className="text-dark">
                                                                            {trimString(event.description, 25)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                            <Divider/>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </Paper>
                                </div>
                            </Hidden>
                        </div>
                        <div className="col-12 order-1 order-md-0 col-md-8">
                            {
                                events && events.length === 0 &&
                                <Paper>
                                    <div className='p-3'>
                                        {getLocalizedMessage('noEvents')}
                                    </div>
                                </Paper>
                            }

                            {
                                loading &&
                                <div className='w-100 d-flex justify-content-center pt-5'>
                                    <CircularProgress color='primary' size={50} thickness={5}/>
                                </div>
                            }

                            {
                                events &&
                                <div>
                                    {
                                        this.props.data.events.map((event, index) => {
                                            const date = createDate(event.date);
                                            const displayDate = format(date, "EEE do MMM, yyyy",
                                                {awareOfUnicodeTokens: true});
                                            const displayTime = format(date, "h:mm bb",
                                                {awareOfUnicodeTokens: true});

                                            return (
                                                <div className="w-100 mb-4" key={index}>
                                                    <Paper className={`${classes.paper}`}>
                                                        <div className="row">
                                                            <img src={`${EVENT_IMG_URL}/${event.imgUri}`}
                                                                 alt={event.title}
                                                                 className="event-img col-12 col-lg-4"/>
                                                            <div className="col-12 col-lg-8">
                                                                <div className="d-flex">
                                                                    <div className="p-2 event-date">
                                                                        {displayDate}
                                                                    </div>
                                                                    <div className="p-2 event-date">
                                                                        {displayTime}
                                                                    </div>
                                                                </div>
                                                                <div className="text-dark pl-2">
                                                                    <strong>{trimString(event.title, 30)}</strong>
                                                                </div>
                                                                <div className='text-dark pl-2'>
                                                                    {trimString(event.description, 40)}
                                                                </div>
                                                                <div
                                                                    className='d-flex justify-content-end pr-4 mb-2 mt-2'>
                                                                    <Link to={`/events/${event.id}`}>
                                                                        <Button
                                                                            className='ml-auto'
                                                                            size='small'
                                                                            variant='contained'
                                                                        >
                                                                            {getLocalizedMessage('readMore')}
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Paper>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Events.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getEvents: PropTypes.func.isRequired,
    getAllEvents: PropTypes.func.isRequired,
    searchEvents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.allEventsData.data,
    status: state.allEventsData.status,
    loading: state.allEventsData.loading,
    latestData: state.getEventsData.data,
    latestLoading: state.getEventsData.loading
});

export default connect(mapStateToProps, {
    getEvents,
    getAllEvents,
    searchEvents
})(withRouter(withStyles(styles, {withTheme: true})(Events)));