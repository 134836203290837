import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {mainTheme} from "../../themes/Themes";
import MenuDrawer from "../menu/MenuDrawer";
import Paper from "@material-ui/core/Paper";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {format, isAfter, isBefore, parse, startOfWeek} from "date-fns";
import {getLocalizedMessage, trimString} from "../../helpers";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {DatePicker} from "material-ui-pickers";
import {getNotices} from "../../actions/noticeActions";
import {Link} from "react-router-dom";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

class Notices extends Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const start = startOfWeek(today, {weekStartsOn: 1});
        this.state = {
            end: new Date(),
            start: start
        };

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.shouldDisableDateStart = this.shouldDisableDateStart.bind(this);
        this.shouldDisableDateEnd = this.shouldDisableDateEnd.bind(this);
    }

    componentDidMount() {
        document.title = getLocalizedMessage('noticeboard');
        this.props.setTitle(getLocalizedMessage('noticeboard'));
        const {start, end} = this.state;
        if (start && end) {
            const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
            this.props.getNotices(from, to);
        }
    }

    handleStartDateChange = date => {
        this.setState({start: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getNotices(from, to);
                }
            });
    };

    handleEndDateChange = date => {
        this.setState({end: date},
            () => {
                const {start, end} = this.state;
                if (start && end) {
                    const from = format(start, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    const to = format(end, "yyyy-MM-dd", {awareOfUnicodeTokens: true});
                    this.props.getNotices(from, to);
                }
            });
    };

    shouldDisableDateStart = date => {
        const {end} = this.state;
        return isAfter(date, end);
    };

    shouldDisableDateEnd = date => {
        const {start} = this.state;
        return isBefore(date, start);
    };

    render() {
        const {data, loading} = this.props;
        const {start, end} = this.state;

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className='d-flex justify-content-center'>
                    <div className='notice-item'>
                        <div className='d-flex justify-content-start'>
                            <DatePicker
                                value={start}
                                onChange={this.handleStartDateChange}
                                leftArrowIcon={<ChevronLeftIcon/>}
                                rightArrowIcon={<ChevronRightIcon/>}
                                label={getLocalizedMessage('from')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="mb-4"
                                format="dd MMM yyyy"
                                openToYearSelection={false}
                                shouldDisableDate={this.shouldDisableDateStart}
                            />
                            <div className='ml-3'>
                                <DatePicker
                                    value={end}
                                    onChange={this.handleEndDateChange}
                                    leftArrowIcon={<ChevronLeftIcon/>}
                                    rightArrowIcon={<ChevronRightIcon/>}
                                    label={getLocalizedMessage('to')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className="mb-4"
                                    format="dd MMM yyyy"
                                    openToYearSelection={false}
                                    shouldDisableDate={this.shouldDisableDateEnd}
                                    disableFuture
                                />
                            </div>
                        </div>
                        {
                            loading &&
                            <div className='w-100 d-flex justify-content-center pt-5'>
                                <CircularProgress color='primary' size={50} thickness={5}/>
                            </div>
                        }
                        {
                            data.data && data.data.length > 0 &&
                            <div>
                                {
                                    data.data.map((item, index) => {
                                        const displayFormat = "EEE do MMM, yyyy";

                                        const date = parse(item.date, "yyyy-MM-dd", new Date());
                                        const displayDate = format(date, displayFormat, {awareOfUnicodeTokens: true});

                                        return (
                                            <Link to={`/notice/${item.id}`} key={index}>
                                                <Paper className='mb-4 p-3' elevation={1}>
                                                    <div className='mt-0 text-dark'>
                                                        <strong>{item.title}</strong>
                                                    </div>
                                                    {/*<div className='mt-1 text-dark'>
                                                        {trimString(item.description)}
                                                    </div>*/}
                                                    <div className='text-dark'>
                                                        <span className='font-sm'>{displayDate}</span>
                                                    </div>
                                                </Paper>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        }

                        {
                            data.data && data.data.length === 0 && !loading &&
                            <Paper elevation={1}>
                                <div className='p-3'>
                                    {getLocalizedMessage('notNotices')}
                                </div>
                            </Paper>
                        }
                    </div>
                </div>
            </MuiThemeProvider>
        );
    }
}

Notices.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getNotices: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.noticesData.data,
    loading: state.noticesData.loading,
    status: state.noticesData.status
});

export default connect(mapStateToProps, {getNotices})(withStyles(styles, {withTheme: true})(Notices));