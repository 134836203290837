import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {mainTheme} from "../../themes/Themes";
import Footer from "../footer/Footer";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {withStyles} from "@material-ui/core";
import UserPlaceholder from '../../images/placeholders/user.jpg';
import SpeechBubble from '../../images/speech_bubble.png';
import PlusIcon from '@material-ui/icons/Add';
import {
    addComment,
    addPost,
    addReply,
    getPosts,
    newComment,
    newPost,
    newReply,
    sendComments,
    sendPosts,
    sendReplies,
    togglePostDialog,
    uploadPostImage
} from "../../actions/forumActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {allowedImageExtensions, FROM_PARENT, FROM_TEACHER} from "../../constants/constants";
import {FORUM_IMG_URL, PARENT_IMG_URL, PROFILE_IMG_URL, SOCKET_BASE_URL} from "../../constants/request_url";
import {
    currentLanguage,
    formatDateTime,
    formatForumPostDate,
    getLocalizedMessage,
    toastError, userFullname,
    utcDate,
    verifyExtension
} from "../../helpers";
import AddPostDialog from "../dialogs/AddPostDialog";
import io from "socket.io-client";
import TimeComponent from "../TimeComponent";
import CommentsDialog from "../dialogs/CommentsDialog";
import {userImageUri, userPublicId} from "../../helpers/StorageHelper";

const styles = theme => ({
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: 0,
        marginBottom: 30
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    inputRoot: {
        color: 'inherit',
        background: '#FFFFFF',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    inputInput: {
        width: '100%',
        fontSize: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        }
    },
    inputRootReply: {
        color: 'inherit',
        background: 'rgba(0, 0, 0, 0.12)',
        height: 'auto',
        width: 'calc(100% - 56px)',
        borderRadius: 5,
        padding: 10
    },
    fab: {
        marginLeft: 10
    },
    whiteDivider: {
        background: 'rgba(0, 0, 0, 0.1)',
    },
    container: {
        [theme.breakpoints.up('md')]: {
            marginTop: 20
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 30
        }
    }
});

class Forum extends Component {
    constructor(props) {
        super(props);
        this.state = {
            post: '',
            addPostDialogOpen: false,
            comments: {},
            replyText: '',
            commentId: 0,
            scrollTop: 0,
            showAddPost: true,
            commentsDialogOpen: false,
            activePost: {},
            file: '',
            imagePreviewUrl: '',
            croppedImage: null,
            croppedImgUrl: null,
            cropComplete: false
        };

        this.closeDialog = this.closeDialog.bind(this);
        this.handlePostChange = this.handlePostChange.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
        this.getUnsentPosts = this.getUnsentPosts.bind(this);
        this.getUnsentComments = this.getUnsentComments.bind(this);
        this.openCommentDialog = this.openCommentDialog.bind(this);
        this.closeCommentDialog = this.closeCommentDialog.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.crop = this.crop.bind(this);
        this._crop = this._crop.bind(this);
        this.resetCrop = this.resetCrop.bind(this);
        this.completeCrop = this.completeCrop.bind(this);
        this.openPostDialog = this.openPostDialog.bind(this);
        this.closePostDialog = this.closePostDialog.bind(this);
        this.cropperRef = React.createRef();

        this.scrollViewRef = React.createRef();
        this.postsSocket = io(`${SOCKET_BASE_URL}/forum/teacher`, {
            path: '',
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('teacherToken')}`,
                        'X-TenantID': localStorage.getItem('teacherSchoolId'),
                        Language: currentLanguage()
                    }
                }
            },
            forceNew: true,
            reconnection: true
        });
    }


    componentDidMount() {
        document.title = getLocalizedMessage('forum');
        this.props.setTitle(getLocalizedMessage('forum'));
        this.props.getPosts(0, {posts: []});
        this.getUnsentPosts();
        this.getUnsentComments();
        this.getUnsentReplies();

        this.postsSocket.connect();
        const thisRef = this;

        this.postsSocket.on(`school:`, data => {
            if (data.exclude !== userPublicId()) {
                thisRef.props.newPost(thisRef.props.postsData, data);
            }
        });

        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
        this.postsSocket.disconnect()
    }

    handleScroll = () => {
        // console.log("sH", document.documentElement.scrollTop);
        /*if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            console.log("scrolledToBottom")
        }*/

        /*if ((window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight) {
            console.log("scrolledToBottom")
        }*/

        const {loading, loadingMore} = this.props;
        if ((window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 100) && !loading && !loadingMore) {
            const {posts, nextPage, lastPage} = this.props.postsData;
            if (nextPage <= lastPage) {
                this.props.getPosts(nextPage, this.props.postsData);
            }
        }

        const {scrollTop} = this.state;
        if (window.scrollY > scrollTop) {
            this.setState({scrollTop: window.scrollY, showAddPost: false});
        } else {
            this.setState({scrollTop: window.scrollY, showAddPost: true});
        }
    };

    closeDialog = name => e => {
        this.setState({
            [name]: false
        });
    };

    openDialog = name => event => {
        this.setState({
            [name]: true,
            post: ''
        });
    };

    closeCommentDialog = e => {
        this.setState({
            commentsDialogOpen: false,
            activePost: {}
        });
    };

    openCommentDialog = item => event => {
        this.setState({
            activePost: item,
            commentsDialogOpen: true
        });
    };

    handlePostChange = event => {
        this.setState({
            post: event.target.value
        });
    };

    handleSubmit = event => {
        const {post, croppedImgUrl, croppedImage} = this.state;
        if (post.trim().length > 0) {
            if (croppedImgUrl) {
                const {post} = this.state;
                const date = utcDate();

                const data = {
                    id: 0,
                    pendingId: date.getTime(),
                    text: post.trim(),
                    date: formatDateTime(date),
                    fromWeb: true
                };

                const formData = new FormData();
                formData.append('image', croppedImage);
                formData.append('data', JSON.stringify(data));
                this.props.uploadPostImage(this.props.postsData, formData);
            } else {
                this.submit();
                this.closePostDialog();
            }
        }
    };

    submit = () => {
        const {post} = this.state;
        const date = utcDate();

        const data = {
            id: 0,
            pendingId: date.getTime(),
            category: FROM_TEACHER,
            text: post.trim(),
            datePosted: formatDateTime(date),
            fromIdNo: userPublicId(),
            fromName: userFullname(),
            fromImageUri: userImageUri(),
            isAlumni: false
        };
        this.props.addPost(this.props.postsData, data);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "auto"
            });
        }, 100);
    };

    getUnsentPosts = () => {
        setInterval(() => {
            const {sendingPosts} = this.props;
            const {posts} = this.props.postsData;
            const filtered = posts.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingPosts) {
                const submitData = JSON.stringify({
                    posts: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.datePosted
                    })),
                    fromWeb: true
                });
                this.props.sendPosts(this.props.postsData, submitData);
            }
        }, 1000);
    };

    getUnsentComments = () => {
        setInterval(() => {
            const {sendingComments, currentCommentsData} = this.props;
            const {comments} = this.props.commentsData;
            const filtered = comments.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingComments) {
                const submitData = JSON.stringify({
                    comments: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.dateCommented,
                        postId: item.postId
                    })),
                    fromWeb: true
                });
                this.props.sendComments(comments, currentCommentsData, submitData);
            }
        }, 1000);
    };

    getUnsentReplies = () => {
        setInterval(() => {
            const {sendingReplies, currentCommentsData} = this.props;
            const {replies} = this.props.repliesData;
            const filtered = replies.filter(item => item.category === FROM_TEACHER && item.id === 0);
            if (filtered.length > 0 && !sendingReplies) {
                const submitData = JSON.stringify({
                    replies: filtered.map(item => ({
                        pendingId: item.pendingId,
                        text: item.text,
                        date: item.dateReplied,
                        commentId: item.commentId
                    })),
                    fromWeb: true
                });
                this.props.sendReplies(replies, currentCommentsData, submitData);
            }
        }, 1000);
    };

    _crop() {
        this.cropperRef.current.getCroppedCanvas().toBlob(blob => {
            const imgFile = new File([blob], this.state.file.name);
            const url = URL.createObjectURL(imgFile);
            this.setState({
                croppedImage: imgFile,
                croppedImgUrl: url
            })
        }, 'image/jpeg', 1)
    }

    completeCrop = () => {
        this.setState({
            cropComplete: true
        })
    };

    resetCrop = () => {
        this.cropperRef.current.reset();
    };

    crop = () => {
        this.setState({
            cropComplete: false
        })
    };

    handleImageChange(e) {
        const reader = new FileReader();
        const file = e.target.files[0];
        const fileName = file.name;
        if (verifyExtension(fileName, allowedImageExtensions) === false) {
            toastError(true, getLocalizedMessage('fileNotImage'));
            this.setState({
                file: '',
                imagePreviewUrl: '',
                croppedImgUrl: null
            });
        } else {
            reader.onloadend = () => {
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    croppedImgUrl: null,
                    cropComplete: false
                });
            };

            reader.readAsDataURL(file)
        }
    }

    closePostDialog = e => {
        this.setState({
            file: '',
            imagePreviewUrl: '',
            croppedImage: null,
            croppedImgUrl: null,
            cropComplete: false
        });

        this.props.togglePostDialog(false);
    };

    openPostDialog = event => {
        this.setState({
            post: ''
        });
        this.props.togglePostDialog(true);
    };

    render() {
        const {
            postsData, postsLoading,
            classes, loadingMore, addPostDialogData
        } = this.props;
        const {
            addPostDialogOpen, post, showAddPost, commentsDialogOpen, activePost, imagePreviewUrl, croppedImgUrl,
            cropComplete, croppedImage
        } = this.state;
        const {posts} = postsData;

        const data = [];

        /*if (!postsLoading) {
            posts.forEach(item => {
                const postComments = comments.filter(x => x.postId === item.id).map(ele => {
                    return ({...ele, replies: replies.filter(x => x.commentId === ele.id)})
                });
                data.push({...item, comments: postComments});
            });
        }*/

        return (
            <MuiThemeProvider theme={mainTheme}>
                <div className='forum-main-container' ref={this.scrollViewRef}>
                    {
                        postsLoading &&
                        <div className='w-100 d-flex justify-content-center pt-5'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }

                    {/*{
                            leaves && leaves.length === 0 &&
                            <Paper>
                                <div className='p-3'>
                                    No leave has been issued
                                </div>
                            </Paper>
                        }*/}
                    {
                        !postsLoading && showAddPost &&
                        <div className='btn-new-post'>
                            <div className='text-white' onClick={this.openPostDialog}>
                                <PlusIcon className='menu-list-icon'/>
                                <span>{getLocalizedMessage('addNewForumPost')}</span>
                            </div>
                        </div>
                    }
                    {
                        !postsLoading &&
                        <div className={`forum-container ${showAddPost ? classes.container : ''}`}>
                            {/*{
                                data.map((item, index) => (
                                    <div className='forum-item mt-4' key={index}>
                                        <div className='d-flex align-items-center'>
                                            {
                                                !item.fromImageUri &&
                                                <img src={UserPlaceholder} alt="" className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_PARENT &&
                                                <img src={`${PARENT_IMG_URL}/${item.fromImageUri}`} alt="" className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_TEACHER &&
                                                <img src={`${PROFILE_IMG_URL}/${item.fromImageUri}`} alt="" className='forum-profile-img'/>
                                            }
                                            <div className='ml-2'>
                                                <div className='text-capitalize'>{item.fromName} <span className='text-black-50'>[{item.category === FROM_TEACHER ? 'Teacher' : 'Parent'}]</span></div>
                                                <div className='text-black-50'><TimeComponent time={item.datePosted} formatFunction={formatForumPostDate}/></div>
                                            </div>
                                        </div>
                                        <div>{item.text}</div>
                                        {
                                            item.comments.length > 0 &&
                                            <div className='text-black-50 mt-1'>{item.comments.length} comment{item.comments.length > 0 ? 's' : ''}</div>
                                        }

                                        {
                                            item.comments.length > 0 &&
                                            <div className='mt-1'>
                                                {
                                                    item.comments.map((comment, commentIndex) => (
                                                        <div className="comment-item d-flex justify-content-start" key={commentIndex}>
                                                            {
                                                                !comment.fromImageUri &&
                                                                <img src={UserPlaceholder} alt="" className='comment-profile-img'/>
                                                            }
                                                            {
                                                                comment.fromImageUri && comment.category === FROM_PARENT &&
                                                                <img src={`${PARENT_IMG_URL}/${comment.fromImageUri}`} alt="" className='comment-profile-img'/>
                                                            }
                                                            {
                                                                comment.fromImageUri && comment.category === FROM_TEACHER &&
                                                                <img src={`${PROFILE_IMG_URL}/${comment.fromImageUri}`} alt="" className='comment-profile-img'/>
                                                            }
                                                            <div className='pl-2'>
                                                                <div className='text-capitalize'><strong>{comment.fromName}</strong> <span
                                                                    className='text-black-50'>[{comment.category === FROM_TEACHER ? 'Teacher' : 'Parent'}]</span></div>
                                                                <div>{comment.text}</div>
                                                                <div className='text-black-50'>
                                                                    <span><TimeComponent time={comment.dateCommented} formatFunction={formatForumPostDate}/></span>
                                                                    {
                                                                        comment.id !== 0 &&
                                                                        <span
                                                                            className='ml-3 cursor-pointer' onClick={this.replyComment(comment.id)}>Reply</span>
                                                                    }
                                                                </div>

                                                                {
                                                                    this.state.commentId === comment.id && comment.id > 0 &&
                                                                    <div
                                                                        className='d-flex align-items-end mt-2'>
                                                                        <InputBase fullWidth
                                                                                   placeholder='Write a reply...'
                                                                                   multiline
                                                                                   rows={1}
                                                                                   rowsMax={5}
                                                                                   classes={{
                                                                                       root: classes.inputRootReply,
                                                                                       input: classes.inputInput,
                                                                                   }}
                                                                                   value={this.state.replyText}
                                                                                   onChange={this.onReplyChange}
                                                                                   onKeyPress={this.onReplyKeyPress}/>
                                                                        <Fab color="primary"
                                                                             aria-label="Add"
                                                                             className={`${classes.fab} text-white`}
                                                                             size='small'
                                                                             onClick={this.onSubmitReply}
                                                                             disabled={this.state.replyText.trim() === ''}>
                                                                            <SendIcon/>
                                                                        </Fab>
                                                                    </div>
                                                                }

                                                                {
                                                                    comment.replies.length > 0 &&
                                                                    <div>
                                                                        {
                                                                            comment.replies.map((reply, replyIndex) => (
                                                                                <div className='pl-3 pt-1 pb-1 ' key={replyIndex}>
                                                                                    <div className="d-flex" key={replyIndex}>
                                                                                        {
                                                                                            !reply.fromImageUri &&
                                                                                            <img src={UserPlaceholder} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_PARENT &&
                                                                                            <img src={`${PARENT_IMG_URL}/${reply.fromImageUri}`} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        {
                                                                                            reply.fromImageUri && reply.category === FROM_TEACHER &&
                                                                                            <img src={`${PROFILE_IMG_URL}/${reply.fromImageUri}`} alt="" className='reply-profile-img'/>
                                                                                        }
                                                                                        <div className='ml-2'>
                                                                                            <div className='text-capitalize'><strong>{trimString(reply.fromName, 20)}</strong></div>
                                                                                            <div className='text-black-50'>{trimString(reply.text, 20)}</div>
                                                                                            <div className='text-black-50 last-seen-text'>
                                                                                                <span><TimeComponent time={reply.dateReplied} formatFunction={formatForumPostDate}/></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }

                                        {
                                            item.id !== 0 &&
                                            <div className='d-flex align-items-end mt-2'>
                                                <InputBase fullWidth placeholder='Write a comment...'
                                                           multiline
                                                           rows={1}
                                                           rowsMax={5}
                                                           classes={{
                                                               root: classes.inputRoot,
                                                               input: classes.inputInput,
                                                           }}
                                                           value={this.state.comments[item.id.toString()] ? this.state.comments[item.id.toString()]: ''}
                                                           onChange={this.onCommentChange(item.id)}
                                                           onKeyPress={this.onCommentKeyPress(item.id)}/>
                                                <Fab color="primary" aria-label="Add" className={`${classes.fab} text-white`} size='small'
                                                     onClick={this.onSubmitComment(item.id)}
                                                     disabled={!this.state.comments[item.id.toString()] || this.state.comments[item.id.toString()].trim() === ''}>
                                                    <SendIcon/>
                                                </Fab>
                                            </div>
                                        }
                                    </div>
                                ))
                            }*/}

                            {
                                posts && posts.length === 0 &&
                                <div className='mt-5'>{getLocalizedMessage('beFirstToPost')}</div>
                            }

                            {
                                posts.map((item, index) => (
                                    <div className='forum-item mt-4' key={index}>
                                        <div className='d-flex align-items-center'>
                                            {
                                                !item.fromImageUri &&
                                                <img src={UserPlaceholder} alt="" className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_PARENT &&
                                                <img src={`${PARENT_IMG_URL}/${item.fromImageUri}`} alt=""
                                                     className='forum-profile-img'/>
                                            }
                                            {
                                                item.fromImageUri && item.category === FROM_TEACHER &&
                                                <img src={`${PROFILE_IMG_URL}/${item.fromImageUri}`} alt=""
                                                     className='forum-profile-img'/>
                                            }

                                            <div className='ml-2'>
                                                <div className='text-capitalize'>
                                                    {item.category === FROM_TEACHER && item.fromIdNo === userPublicId() ? getLocalizedMessage('me') : item.fromName}
                                                    {
                                                        !(item.category === FROM_TEACHER && item.fromIdNo === userPublicId()) &&
                                                        <span
                                                            className='text-black-50'>[{item.category === FROM_TEACHER ? getLocalizedMessage('teacher') : item.isAlumni === true ? getLocalizedMessage('alumni') : getLocalizedMessage('parent')}]</span>
                                                    }
                                                </div>
                                                <div className='text-black-50'><TimeComponent time={item.datePosted}
                                                                                              formatFunction={formatForumPostDate}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div>{item.text}</div>
                                        {
                                            item.imageUri &&
                                            <img src={`${FORUM_IMG_URL}/${item.imageUri}`} className='forum-img mt-2'/>
                                        }

                                        {/*<Divider className='mt-2' classes={{root: classes.whiteDivider}}/>*/}

                                        <div className='d-flex'>
                                            {
                                                item.comments > 0 &&
                                                <div
                                                    onClick={this.openCommentDialog(item)}
                                                    className='text-black-50 mt-2 cursor-pointer mr-4'>{item.comments} {item.comments > 1 ? getLocalizedMessage('comments').toLowerCase() : getLocalizedMessage('comment').toLowerCase()}</div>
                                            }
                                            {
                                                item.id !== 0 &&
                                                <div
                                                    onClick={this.openCommentDialog(item)}
                                                    className='text-black-50 mt-2 cursor-pointer d-flex align-items-center'>
                                                    <img src={SpeechBubble} alt="" className='comment-icon mr-1'/>
                                                    <span>{getLocalizedMessage('comment')}</span>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }

                    {
                        loadingMore &&
                        <div className='w-100 d-flex justify-content-center pt-3'>
                            <CircularProgress color='primary' size={50} thickness={5}/>
                        </div>
                    }

                    <Footer/>
                </div>

                <AddPostDialog
                    open={!!(addPostDialogData && addPostDialogData.open)}
                    post={post}
                    handleChange={this.handlePostChange}
                    handleClose={this.closePostDialog}
                    handleSubmit={this.handleSubmit}
                    cropperRef={this.cropperRef}
                    crop={this.crop}
                    _crop={this._crop}
                    completeCrop={this.completeCrop}
                    handleImageChange={this.handleImageChange}
                    resetCrop={this.resetCrop}
                    imagePreviewUrl={imagePreviewUrl}
                    croppedImgUrl={croppedImgUrl}
                    cropComplete={cropComplete}
                    croppedImage={croppedImage}/>

                {
                    commentsDialogOpen &&
                    <CommentsDialog
                        open={commentsDialogOpen}
                        item={activePost}
                        handleClose={this.closeCommentDialog}/>
                }
            </MuiThemeProvider>
        );
    }
}

Forum.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    getPosts: PropTypes.func.isRequired,
    newPost: PropTypes.func.isRequired,
    newComment: PropTypes.func.isRequired,
    newReply: PropTypes.func.isRequired,
    addPost: PropTypes.func.isRequired,
    sendPosts: PropTypes.func.isRequired,
    sendComments: PropTypes.func.isRequired,
    sendReplies: PropTypes.func.isRequired,
    addComment: PropTypes.func.isRequired,
    addReply: PropTypes.func.isRequired,
    uploadPostImage: PropTypes.func.isRequired,
    togglePostDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    postsData: state.postsData.data,
    postsLoading: state.postsData.loading,
    loadingMore: state.postsData.loadingMore,
    commentsData: state.allCommentsData.data,
    currentCommentsData: state.commentsData.data,
    commentsLoading: state.allCommentsData.loading,
    repliesData: state.allRepliesData.data,
    repliesLoading: state.allRepliesData.loading,
    sendingPosts: state.sendPostsData.loading,
    sendingComments: state.sendCommentsData.loading,
    sendingReplies: state.sendRepliesData.loading,
    addPostDialogData: state.addPostDialogData.data,
});

export default connect(
    mapStateToProps,
    {
        getPosts,
        newPost,
        newComment,
        newReply,
        addPost,
        sendPosts,
        sendComments,
        sendReplies,
        addComment,
        addReply,
        uploadPostImage,
        togglePostDialog
    })
(withStyles(styles, {withTheme: true})(Forum));