import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide/Slide";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import {getStreams} from "../../actions/streamActions";
import {getStreamStudents} from "../../actions/studentActions";
import {getLocalizedMessage, titleCase, toastError} from "../../helpers";
import {showError} from "../../actions/helperActions";
import Paper from "@material-ui/core/Paper";
import {DatePicker} from "material-ui-pickers";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {isWeekend} from 'date-fns';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {markAttendance} from "../../actions/attendanceActions";
import {WhiteCircularProgress} from "../progress_bars/ProgressBars";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getClassLevels} from "../../actions/classLevelActions";
import {load} from "../../reducers/loadDataReducer";
import {schoolClassLevelName} from "../../helpers/StorageHelper";
import {clearStore} from "../../actions/commonActions";
import {GET_STREAM_STUDENTS} from "../../constants/actionTypes";

const styles = theme => ({
    appBar: {
        position: 'fixed',
        height: 70
    },
    flex: {
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    resize: {
        fontSize: 23,
        paddingTop: 20
    },
    resizeDate: {
        fontSize: 23,
        paddingTop: 20,
    },
    label: {
        fontSize: 30,
    },
    dateLabel: {
        fontSize: 30,
        padding: 0
    },
    resizeSM: {
        fontSize: 15,
        paddingTop: 15
    },
    labelSM: {
        fontSize: 18,
    },
    formControl: {
        margin: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const StyledFormControlLabel = withStyles({
    root: {
        height: 27
    },
    label: {
        textTransform: 'capitalize',
        color: "#FFF",
        fontSize: 15,
        fontFamily: "'Open Sans', 'sans-serif'",
    },
})(FormControlLabel);

class MarkAttendanceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classLevel: '',
            year: '',
            stream: '',
            data: [],
            date: new Date()
        }
        ;
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.shouldDisableDate = this.shouldDisableDate.bind(this);
    }

    componentDidMount() {
        const {childRef} = this.props;
        childRef(this);
    }

    componentWillUnmount() {
        const {childRef} = this.props;
        childRef(undefined);
    }

    clearFields = () => {
        this.setState({
            classLevel: '',
            year: '',
            stream: '',
            data: [],
            date: new Date()
        });
        this.props.clearStore(GET_STREAM_STUDENTS);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {classLevel, year, stream} = this.state;
        if (classLevel !== '' && year !== '' && stream !== '') {
            const {data, date} = this.state;
            const _data = [];
            data.forEach(item => {
                _data.push({
                    present: item.present,
                    admNo: item.admNo,
                    date: date.toUTCString()
                })
            });
            if (data.length > 0) {

                const submitData = JSON.stringify({
                    data: _data
                });
                this.props.markAttendance(submitData);
            }
        } else {
            toastError(getLocalizedMessage('fillFields'));
        }
    };

    handleSelectChange = event => {
        this.setState(
            {
                [event.target.name]: event.target.value
            },
            () => {
                if (event.target.name === 'classLevel' || event.target.name === 'year' || event.target.name === 'stream') {
                    const {classLevel, year, stream} = this.state;
                    if (classLevel !== '' && year !== '' && stream !== '') {
                        this.setState({data: []});
                        this.props.getStreamStudents(classLevel, year, stream);
                    }
                }
            }
        );

    };

    handleDateChange = date => {
        this.setState({date});
    };

    shouldDisableDate = date => {
        return isWeekend(date);
    };

    render() {
        const {
            classes, streamsData, studentsData, data, loading, studentsLoading,
            streamsLoading, levelsData
        } = this.props;
        const {classLevel} = this.state;
        const currentYear = new Date().getFullYear();

        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.handleClose}
                TransitionComponent={Transition}
            >
                <div>
                    <div className="container mb-3">
                        <div className="row clearfix">
                            <div className="col-md-12 column">
                                <form onSubmit={this.handleSubmit}>
                                    <AppBar className={classes.appBar}>
                                        <Toolbar>
                                            <IconButton color="inherit" onClick={this.props.handleClose}
                                                        aria-label={getLocalizedMessage('close')}>
                                                <CloseIcon/>
                                            </IconButton>
                                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                                {getLocalizedMessage('markAttendance')}
                                            </Typography>
                                            <Button color="inherit" type="submit" disabled={loading || this.state.data.length < 1}>
                                                {
                                                    !loading &&
                                                    <span>
                                                            {getLocalizedMessage('submit')}
                                                        </span>
                                                }
                                                {
                                                    loading &&
                                                    <WhiteCircularProgress size={30} thickness={5}/>
                                                }
                                            </Button>
                                        </Toolbar>
                                    </AppBar>
                                    <div className="container"
                                         style={{marginTop: 90}}>
                                        <div className="row">
                                            <div className="col-md-4 mb-sm-3">
                                                {
                                                    !levelsData.levels &&
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(schoolClassLevelName())}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    levelsData.levels &&
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="level-label-placeholder">
                                                            {titleCase(schoolClassLevelName())}
                                                        </InputLabel>
                                                        <Select
                                                            value={classLevel}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="classLevel"
                                                                          id="level-label-placeholder"/>}
                                                            displayEmpty
                                                            name="classLevel"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem
                                                                value=''>{titleCase(schoolClassLevelName())}...</MenuItem>
                                                            {
                                                                levelsData.levels.map((item, index) => (
                                                                    <MenuItem value={item.id} key={index}>
                                                                        {titleCase(item.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-4 mb-sm-3 mt-3 mt-lg-0">
                                                <FormControl fullWidth>
                                                    <InputLabel shrink htmlFor="term-label-placeholder">
                                                        {getLocalizedMessage('year')}
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.year}
                                                        onChange={this.handleSelectChange}
                                                        input={<Input name="year" id="term-label-placeholder"/>}
                                                        displayEmpty
                                                        name="year"
                                                        className={classes.selectEmpty}
                                                    >
                                                        <MenuItem value=''>{getLocalizedMessage('year')}...</MenuItem>
                                                        <MenuItem value={currentYear}>{currentYear}</MenuItem>
                                                        <MenuItem
                                                            value={currentYear - 1}>{currentYear - 1}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>

                                            <div className="col-md-4 mb-sm-3 mt-3 mt-lg-0">
                                                {
                                                    !streamsData.streams &&
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                                            {getLocalizedMessage('stream')}
                                                        </InputLabel>
                                                        <Select
                                                            value=''
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="term-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>
                                                                <CircularProgress color='primary' size={30}
                                                                                  thickness={5}/>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                                {
                                                    streamsData.streams && !streamsLoading &&
                                                    <FormControl fullWidth>
                                                        <InputLabel shrink htmlFor="term-label-placeholder">
                                                            {getLocalizedMessage('stream')}
                                                        </InputLabel>
                                                        <Select
                                                            value={this.state.stream}
                                                            onChange={this.handleSelectChange}
                                                            input={<Input name="stream"
                                                                          id="term-label-placeholder"/>}
                                                            displayEmpty
                                                            name="stream"
                                                            className={classes.selectEmpty}
                                                        >
                                                            <MenuItem value=''>{getLocalizedMessage('stream')}...</MenuItem>
                                                            {
                                                                streamsData.streams.map((stream, index) => (
                                                                    <MenuItem value={stream.value} key={index}>
                                                                        {titleCase(stream.name)}
                                                                    </MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <div className="col-md-4 mb-sm-3 mt-3 mt-lg-0">
                                                <DatePicker
                                                    value={this.state.date}
                                                    onChange={this.handleDateChange}
                                                    leftArrowIcon={<ChevronLeftIcon/>}
                                                    rightArrowIcon={<ChevronRightIcon/>}
                                                    label="Date"
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className="mb-4"
                                                    format="dd MMM yyyy"
                                                    disableFuture
                                                    openToYearSelection
                                                    shouldDisableDate={this.shouldDisableDate}
                                                />
                                            </div>
                                            <div className="w-100">
                                                {
                                                    studentsLoading &&
                                                    <div className='w-100 d-flex justify-content-center pt-5'>
                                                        <CircularProgress color='primary' size={50} thickness={5}/>
                                                    </div>
                                                }
                                                {
                                                    studentsData.students &&
                                                    <div>
                                                        {
                                                            studentsData.students.length === 0 &&
                                                            <Paper>
                                                                <div className='p-3'>
                                                                    {getLocalizedMessage('noStudentsInStream')}
                                                                </div>
                                                            </Paper>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    studentsData.students && !streamsLoading &&
                                                    <div>
                                                        {
                                                            studentsData.students.length > 0 &&
                                                            <table className="table table-bordered table-hover table-responsive-md">
                                                                <thead>
                                                                <tr>
                                                                    <th/>
                                                                    <th>{getLocalizedMessage('admNo')}</th>
                                                                    <th>{getLocalizedMessage('name')}</th>
                                                                    <th>{getLocalizedMessage('present')}</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {
                                                                    studentsData.students.map((student, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{student.admNo}</td>
                                                                            <td>
                                                                                {titleCase(`${student.fName}
                                                                         ${student.lName} ${student.surname}`)}
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    this.state.data.length > 0 &&
                                                                                    <StyledFormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                color="primary"
                                                                                                checked={this.state.data[index].present}
                                                                                                onChange={event => {
                                                                                                    const data = [...this.state.data];
                                                                                                    data[index].present = event.target.checked;

                                                                                                    this.setState({
                                                                                                        data,
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                }

                                                                                {
                                                                                    this.state.data.length === 0 &&
                                                                                    <StyledFormControlLabel
                                                                                        control={
                                                                                            <Checkbox
                                                                                                color="primary"
                                                                                                onChange={event => {
                                                                                                    const data = [];
                                                                                                    studentsData.students.forEach(function (item) {
                                                                                                        data.push({
                                                                                                            admNo: item.admNo,
                                                                                                            present: false
                                                                                                        })
                                                                                                    });
                                                                                                    data[index].present = true;
                                                                                                    this.setState({
                                                                                                        data: data
                                                                                                    });
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                    />
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        )
            ;
    }
}

MarkAttendanceDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    markAttendance: PropTypes.func.isRequired,
    getStreamStudents: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    data: state.markAttendanceData.data,
    streamsData: state.getStreamsData.data,
    streamsLoading: state.getStreamsData.loading,
    studentsData: state.getStreamStudentsData.data,
    studentsLoading: state.getStreamStudentsData.loading,
    status: state.markAttendanceData.status,
    loading: state.markAttendanceData.loading,
    levelsData: state.levelsData.data
});

export default connect(mapStateToProps, {
    markAttendance,
    getStreamStudents,
    clearStore
})(withStyles(styles)(MarkAttendanceDialog));