import {UPLOAD_POST_PHOTO} from "../../constants/actionTypes";

const initialState = {
    data: {
        posts: []
    },
    loading: false
};

export default (state = initialState, action) => {
    const newState = {...state};

    if (action.type === UPLOAD_POST_PHOTO) {
        newState.data = action.data;
        newState.status = action.status;
        newState.loading = action.loading;
        return newState;
    } else {
        return state;
    }
}
